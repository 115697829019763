/*
// .block-vehicle-make
*/
@import '../variables';
@import '../mixins/breakpoints';
@import "../adapt";


@mixin local-layout($columns) {
    .block-vehicle-make__item {
        width: calc((100% - #{$columns * 1px}) / #{$columns});
    }
    .block-vehicle-make__divider {
        width: 1px;
    }
    .block-vehicle-make__divider:nth-child(#{$columns * 2}n) {
        height: 1px;
        width: 100%;

        &:last-child {
            display: none;
        }
    }
}


$local-transition-duration: .15s;

.block-vehicle-make {
    display: block;
    padding: 1.5rem 0;
    background: #fff;
}


.block-vehicle-make__body {
    background-color: map_get($theme-scheme, block-bg);
    padding-top: 64px;
    padding-bottom: 64px;
}
.block-vehicle-make__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    //border: 1px solid $block-vehicle-make-divider;
}
.block-vehicle-make__item {
    text-align: center;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.block-vehicle-make__item-link {
    position: relative;
    display: block;
    padding: adapt-size-px(8);
    width: 100%;
    border: 1px solid $block-vehicle-make-divider;
    background-color: #fff;

    //&:before {
    //    position: absolute;
    //    content: '';
    //    width: 100%;
    //    height: 100%;
    //    left: 0;
    //    top: 0;
    //    //background: $block-vehicle-make-item-hover-overlay-color;
    //    opacity: 0;
    //    transition: opacity $local-transition-duration;
    //}

    img {
        max-width: 100%;
        height: adapt-size-px(86);
        object-fit: contain;
        filter: grayscale(1);
        //opacity: .7;
        transition:
            filter $local-transition-duration,
            opacity $local-transition-duration;
    }
}
.block-vehicle-make__item-name {
    display: block;
    font-size: $default-font-size;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: $block-vehicle-make-item-normal-font-color;
    margin-top: adapt-size-px(20);
    margin-bottom: adapt-size-px(18);
    transition: color $local-transition-duration;
}
.block-vehicle-make__item-link:hover {
    border: none;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;

    &:before {
        opacity: 1;
    }
    img {
        filter: grayscale(0);
        opacity: 1;
    }
    .block-vehicle-make__item-name {
        color: $block-vehicle-make-item-hover-font-color;
    }
}
.block-vehicle-make__divider {
    background: $block-vehicle-make-divider;
}


.block-vehicle-make--layout--columns-8-full {
    @include media-breakpoint-up(xxl) {
        @include local-layout(8);
    }
    @include media-breakpoint-only(xl) {
        @include local-layout(7);
    }
    @include media-breakpoint-only(lg) {
        @include local-layout(6);
    }
}

// for all layouts
@include media-breakpoint-only(md) {
    @include local-layout(3);
}
@include media-breakpoint-only(sm) {
    @include local-layout(3);
}
@media (min-width: 420px) and (max-width: 575px) {
    @include local-layout(2);
}
@media (max-width: 419px) {
    @include local-layout(2);
}
