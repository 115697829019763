@font-face {
    font-family: 'Montserrat';
    src: url('/assets/font/montserrat/regular.woff2') format('woff2');
    src: url('/assets/font/montserrat/regular.woff') format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/font/montserrat/medium.woff2') format('woff2');
    src: url('/assets/font/montserrat/medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/font/montserrat/semiBold.woff2') format('woff2');
    src: url('/assets/font/montserrat/semiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/font/montserrat/bold.woff2') format('woff2');
    src: url('/assets/font/montserrat/bold.woff') format('woff');
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: 'Montserrat';
    src: url('/assets/font/montserrat/extraBold.woff2') format('woff2');
    src: url('/assets/font/montserrat/extraBold.woff') format('woff');
    font-weight: 800;
    font-style: normal;
}
