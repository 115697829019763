@import "../variables";
@import "../mixins/product-card";
@import "../mixins/breakpoints";

.product-bom-item {
    &__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid map-get($theme-scheme, border);
        transition: background-color 0.4s ease-in-out;

        span {
            font-size: $adaptive-18-font-size;
            font-weight: $font-weight-bold;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: color 0.4s ease-in-out;
        }

        svg {
            width: 21px;
            height: 12px;
            transition: all .4s ease-in-out;
            fill: map-get($theme-scheme, border);
        }

        &.open {
            $mainColor: map-get($theme-scheme, main);
            $fontColor: map-get($theme-scheme, contrast-text);

            background-color: $mainColor;
            border-color: $mainColor;

            span {
                color: $fontColor;
            }
            svg {
                fill: $fontColor;
                transform: rotate(180deg);
            }
        }
    }

    &__content {
        display: flex;
        gap: 1rem;
        align-items: stretch;
        margin-top: 0.8rem;
        margin-bottom: 1.2rem;
    }

    &__groups {
        flex: 1;
    }

    &__groups--wrapper {
        display: flex;
        gap: 10px;
        flex-wrap: wrap;
        align-items: flex-start;
        padding: 1rem;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.1);
    }

    &__products {
        flex: 3;
        display: flex;
        flex-direction: column;
    }

    &__chip {
        display: flex;
        justify-content: center;
        align-items: center;
        padding: 4px 6px;
        font-weight: $font-weight-medium;
        border: 1px solid map-get($theme-scheme, main);
        border-radius: 20px;
        width: min-content;
        white-space: nowrap;
        transition: all 0.3s ease-in-out;

        &.active {
            background-color: map-get($theme-scheme, main);
            color: map-get($theme-scheme, contrast-text);
        }
    }

    &__product {
        .product-card {
            @include product-card-layout-list;
        }
    }
}

@include media-breakpoint-down(md) {
    .product-bom-item {
        &__content {
            flex-direction: column;
            align-items: unset;
        }

        &__groups {
            flex: 0;
        }

        &__products {
            flex: 0;
        }
    }
}
