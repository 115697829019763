/*
// .view-options
*/
@import "../variables";
@import "../mixins/direction";
@import "../mixins/breakpoints";

.view-options {
    font-size: 0.9375rem;
    overflow: hidden;
}
.view-options__body {
    display: flex;
    gap: 1rem;
    align-items: center;
    position: relative;

    //& + & {
    //    margin-top: 0.5rem;
    //    border-top: 1px solid map_get($card-scheme, divider);
    //}

    &.is-sticky {
        $contrast-text: map-get($theme-scheme, contrast-text);

        z-index: 999;
        background-color: map-get($theme-scheme, fill);
        color: $contrast-text;

        select {
            background-color: map-get($theme-scheme, fill);
            color: $contrast-text;
            border: none;
        }

        .view-options__filters-button {
            border-top: none;
            border-left: none;
            border-bottom: none;

            &:hover {
                background-color: inherit;
            }
        }

        svg {
            fill: $contrast-text;
        }
    }
}

.view-options__general {
    display: flex;
    align-items: center;
    flex: 1;
    border: 1px solid map-get($theme-scheme, 'border');
}

.view-options__body--filters {
    display: block;
    padding: 0.875rem 1rem;
    margin-top: 0.5rem;
}
.view-options__label {
    position: absolute;
    background: map_get($card-scheme, main);
    padding: 0 8px;
    top: -5px;
    font-size: 10px;
    text-transform: uppercase;
    line-height: 1;
    color: map_get($card-scheme, muted);

    @include direction {
        #{$inset-inline-start}: 50%;
        transform: translateX(#{-50% * $transform-direction});
    }
}
.view-options__filters-button {
    min-height: 40px;
    border: 1px solid map_get($theme-scheme, border);
    background: none;
    transition: all 0.15s ease-in-out;
}

.view-options__layout {}

.view-options__legend {
    margin-left: 0.5rem;
    white-space: nowrap;
    color: map-get($theme-scheme, 'secondary-text');
}
.view-options__spring {
    flex-grow: 1;
}
.view-options__select {
    display: flex;
    align-items: center;

    & > label {
        margin-bottom: 0;
        white-space: nowrap;

        @include direction {
            #{$margin-inline-end}: 10px;
        }
    }

    select {
        height: 40px;
    }
}

.view-options--offcanvas--mobile {
    @include media-breakpoint-up(lg) {
        .view-options__filters-button {
            display: none;
        }
    }
}

@include media-breakpoint-down(md) {
    .view-options__filters-button {
        width: 50%;
    }
    .view-options__select {
        flex: 1;
    }
    .view-options__general {
        border: none;
    }
}
@include media-breakpoint-down(xs) {
    .view-options__body {
        & + & {
            margin-top: 2px;
        }
    }
    .view-options__legend {
        width: 100%;
        padding-top: 2px;
    }
    .view-options__select {
        flex-direction: column;
        align-items: flex-start;

        @include direction {
            #{$margin-inline-start}: 0;
        }

        & > label {
            margin: 0 0 2px;
        }
    }
}
