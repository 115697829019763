/*
// .product-tabs
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/breakpoints';
@import '../mixins/container';

//only for sticky position
.product-tabs__panel {
    &.is-sticky {
        z-index: 4;
        background-color: map_get($theme-scheme, fill);

        .container {
            @include container;
        }

        .product-tabs__wrapper {
            display: flex;
            justify-content: space-between;
            align-items: center;

            .product-tabs__list {
                background-color: map_get($theme-scheme, fill);
                margin: 0;
            }

            .product-tabs__item {
                a {
                    &:hover h2 {
                        color: map_get($theme-scheme, main);
                    }

                    &:hover, &:focus {
                        background-color: unset;
                        box-shadow: none;
                    }
                }

                &:not(.product-tabs__item--active) {
                    a {
                        border-color: rgba(map-get($theme-scheme, border-contrast), 0.3);
                    }

                    h2 {
                        color: map-get($theme-scheme, contrast-text);
                        transition: color 0.3s ease;
                    }
                }
            }
        }
    }
}

//position static
.product-tabs__list {
    display: flex;
    padding: 0;
    margin: 0 0 -1px;
    list-style: none;
    overflow-x: auto;
    gap: 1.2rem;
}

.product-tabs__content {
    border-top: 1px solid map_get($card-scheme, divider);
}

.product-tabs__item {
    position: relative;

    a {
        position: relative;
        display: flex;
        justify-content: center;
        padding: 13px 25px;
        white-space: nowrap;
        text-transform: uppercase;
        border-bottom: 1px solid map_get($theme-scheme, border);
        transition: background .12s,
        box-shadow .12s;

        &:hover {
            background: $product-tabs-hover-bg-color;
            //box-shadow: 0 -1px $product-tabs-hover-border-color inset;
        }

        &:active {
            background: $product-tabs-active-bg-color;
            //box-shadow: 0 -1px $product-tabs-active-border-color inset;
        }

        @include media-breakpoint-down(sm) {
            padding: 8px 10px 13px;
        }
    }

    &:last-child {
        @include direction {
            #{$margin-inline-end}: auto;
        }
    }

    h2 {
        font-size: 14px;
        font-weight: $font-weight-semi-bold;
        color: map-get($theme-scheme, secondary-text);
        margin: 0;

        @include media-breakpoint-down(sm) {
            font-size: 11px;
        }
    }
}

.product-tabs__item-counter {
    color: $product-tabs-counter-color;
    font-size: 14px;
    top: -4px;
    position: relative;

    @include direction {
        #{$margin-inline-start}: 3px;
        #{$margin-inline-end}: -3px;
    }
}

.product-tabs__item--active {
    a {
        border-bottom: 1px solid $product-tabs-current-border-color;

        &,
        &:hover,
        &:active {
            background: transparent;
            //box-shadow: 0 -1px $product-tabs-current-border-color inset;
        }

        h2 {
            color: $product-tabs-current-border-color;
        }
    }
}

.product-tabs__pane {
    display: block;
    overflow: hidden;
    height: 0;
    opacity: 0;
    transition: opacity .5s;
}

.product-tabs__pane--active {
    padding: 42px 48px 48px;
    overflow: visible;
    height: auto;
    opacity: 1;
    max-width: calc(100% - .001px); // this is due to safari bag
}


.product-tabs--layout--full {
    .product-tabs__pane--active {
        padding: 48px;
    }

    @include media-breakpoint-down(md) {
        .product-tabs__pane--active {
            padding: 40px 44px 44px;
        }
    }
    @include media-breakpoint-down(sm) {
        .product-tabs__pane--active {
            padding: 24px 28px 28px;
        }
    }
    @media (max-width: 419px) {
        .product-tabs__pane--active {
            padding: 20px 24px 24px;
        }
    }
}


.product-tabs--layout--sidebar {
    @include media-breakpoint-only(xxl) {
        .product-tabs__pane--active {
            padding: 36px;
        }
    }
    @include media-breakpoint-only(xl) {
        .product-tabs__pane--active {
            padding: 48px;
        }
    }
    @include media-breakpoint-down(lg) {
        .product-tabs__pane--active {
            padding: 36px;
        }
    }
    @include media-breakpoint-down(sm) {
        .product-tabs__pane--active {
            padding: 28px;
        }
    }
    @media (max-width: 419px) {
        .product-tabs__pane--active {
            padding: 24px;
        }
    }
}

.scroll-offset {
    scroll-margin-top: 140px;

    @include media-breakpoint-up(sm) {
        scroll-margin-top: 160px;
    }

    @include media-breakpoint-up(lg) {
        scroll-margin-top: 200px;
    }
}

@include media-breakpoint-down(md) {
    .product-tabs__wrapper {
        .product-short {
            display: none;
        }
    }
}
