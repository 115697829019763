@import "../variables";

.correct-old-value {
  text-decoration: line-through;
  color: #ff3333;
  transform: scale(1.5);
  font-weight: $font-weight-bold;
  margin-right: 10px;
}

.correct-correct-value {
  transform: scale(1.2);
  font-weight: $font-weight-semi-bold;
}
