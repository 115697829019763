/*
// .garage-vehicle-selector
*/
@import '../variables';
@import '../mixins/breakpoints';

$local-open-transition-duration: .2s;

@mixin current-vehicle-compact {
    position: inherit;
    pointer-events: auto;
    visibility: visible;
    opacity: 1;
    display: none;

    &.show {
        display: block;
    }

    .garage-vehicle-selector__wrapper {
        width: 100%;
    }
}

.garage-vehicle-selector_pop-up {
    pointer-events: none;
    position: absolute;
    z-index: 4;
    top: 100%;
    right: 0;
    visibility: hidden;
    transform-origin: top;
    transform: rotateX(60deg);
    opacity: 0;
    transition:
        transform $local-open-transition-duration,
        opacity $local-open-transition-duration,
        visibility 0s $local-open-transition-duration;

    &.show {
        pointer-events: auto;
        transition-delay: 0s, 0s, 0s;
        opacity: 1;
        visibility: visible;
        transform: rotateX(0deg);
    }

    @container current-vehicle (width < 900px) {
        @include current-vehicle-compact;
    }

    @include media-breakpoint-down(md) {
        @include current-vehicle-compact;
    }
}


.garage-vehicle-selector__wrapper {
    display: flex;
    width: 300px;
    background-color: map_get($menu-scheme, main);
    box-shadow: map_get($menu-scheme, shadow);
    border-radius: 1.5px;
    color: map_get($menu-scheme, opposite);
    padding: 1.5rem 1rem;
    flex-direction: column;
    gap: 1rem;

    .default-pargo-button-inverse {
        width: 100%;

        svg {
            height: auto;
        }
    }

    .garage-vehicle-selector__item {
        display: flex;
        gap: 1rem;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid map-get($theme-scheme, border);

        .car-content {
            display: flex;
            align-items: center;

            .leading {
                width: 17px;
                height: 17px;
                border: 1px solid map-get($theme-scheme, border-contrast);
                border-radius: 100%;
                display: flex;
                align-items: center;
                justify-content: center;
                flex-shrink: 0;

                &.active {
                    background-color: #4caf50;
                    border-color: #4caf50;
                }
            }

            .title {
                padding: 10px;
                font-weight: 600;
                line-height: 1.2;
            }
        }
    }
}
