/*
// .container
*/
@import '../variables';
@import '../mixins/container';


.container {
    @include container;
}

.container .container {
    padding: 0;
}

.container {
    .page-container {
        max-width: 1410px;
        margin: 0 auto;
    }
}
