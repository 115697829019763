.btn-scroll-to-top {
  position: fixed;
  right: 24px;
  bottom: 10px;
  border-radius: 24px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: opacity 0.15s ease-in-out, transform 0.3s ease-in-out;
  opacity: 1;
  transform: translateY(0);
  z-index: 1;

  &.btn-scroll-to-top--hiding {
    opacity: 0;
    transform: translateY(100px);
  }

  &.btn-scroll-to-top--hide {
    display: none;
  }
}
