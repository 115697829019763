@import "../variables";

.shared-article-parent {
    overflow: hidden;
}

.shared-article {
    display: flex;
    gap: 8px;
    justify-content: flex-end;
    align-items: center;
    //width: min-content;

    .shared-article__text {
        font-family: inherit;
        font-size: $default-font-size;
        font-weight: 400;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }
}
