@import "../variables";

.cart-items {
  display: block;
  // margin-top: 30px;
  margin-bottom: 30px;

  .cart-table {
    padding: 20px 50px 10px 50px;
  }
  .total {
    display: flex;
    justify-content: flex-start;
    gap: 10px;
    span {
      font-weight: $font-weight-bold;
    }
  }
  .cart-table__foot-total {
    padding: 10px;
      text-align: right;
  }
}
