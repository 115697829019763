/*
// .products-view
*/
@import '../variables';
@import '../mixins/card';
@import '../mixins/breakpoints';


.products-view {}

.products-view__body {
    position: relative;
}

.products-view__loader {
    position: absolute;
    left: -10px;
    top: -10px;
    width: calc(100% + 20px);
    height: calc(100% + 20px);
    z-index: 4;
    background: rgba(map_get($body-scheme, main), .7);
    opacity: 0;
    pointer-events: none;
    transition: opacity .3s ease-in-out;
}

.products-view--loading {
    .products-view__loader {
        opacity: 1;
        pointer-events: auto;
    }
}

.products-view__options {
    @include card;

    margin-bottom: 20px;
}

.products-view__pagination {
    //@include card;

    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px;
    padding: .75rem 1rem;
}

.products-view__pagination-legend {
    font-size: .9375rem;
    padding: 0 .375rem;
    color: map-get($theme-scheme, secondary-text);
}

.products-view__empty {
    @include card;

    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4rem 2.5rem;
    text-align: center;
}

.products-view__empty-title {
    font-size: 22px;
    font-weight: $font-weight-medium;
}

.products-view__empty-subtitle {
    margin-top: 4px;
}

.products-view__empty-actions {
    margin-top: 1.25rem;
}

@include media-breakpoint-down(lg) {
    .products-view__pagination {
        padding-top: 1rem;
        flex-direction: column;
    }
    .products-view__pagination-legend {
        padding-top: 1rem;
        text-transform: uppercase;
    }
}

@include media-breakpoint-down(sm) {
    .products-view__pagination {
        padding: 1rem 0 0 0;
    }
}
