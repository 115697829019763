@import "../variables";

.order-product-images {
    display: flex;
    flex-direction: row-reverse;
    gap: 5px;
    @include media-breakpoint-down(xs) {
        gap: 1px;
    }
}

.order-product-images__item {
    width: 60px;
    height: 60px;
    border: 1px solid map-get($theme-scheme, border);
    display: flex;
    align-items: center;
    justify-content: center;
    @include media-breakpoint-down(xs) {
        width: 40px;
        height: 40px;
    }
    img {
        object-fit: contain;
        overflow: auto;
    }
    span {
        color: map-get($theme-scheme, secondary-text);
        font-weight: $font-weight-bold;
    }
}
