@import "../variables";
@import "../mixins/breakpoints";

.page-content {

    li {
        margin-bottom: 1rem;
    }
    li::marker {
        color: map-get($theme-scheme, main);
    }
    a {
        color: map-get($theme-scheme, main);
    }
    h1, h2, h3, h4, h5, h6 {
        font-weight: 700;
    }
}

.page-content__step {
    display: flex;
    align-items: center;
    border-bottom: 1px solid map-get($theme-scheme, main);
    margin-bottom: 3px;

    &:nth-child(even) {
        background: aliceblue;
    }
}

.page-content__step-number {
    font-size: 24px;
    font-weight: $font-weight-bold;
    background: map-get($theme-scheme, main);
    color: map-get($theme-scheme, contrast-text);
    align-self: stretch;
    padding: 20px;
    min-width: 80px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.page-content__step-content {
    color: map-get($theme-scheme, fill);
    padding: 20px;
}

.page-content__modal {
    h4 {
        margin: unset;
        color: map-get($theme-scheme, fill);
        font-weight: 600;
        font-size: 1.2rem;
    }
}

.page--centered-block {
    display: flex;
    justify-content: center;
    margin: 1rem 0;
    padding: 1.2rem;
    border: 1px solid map-get($theme-scheme, main);
    color: map-get($theme-scheme, fill);
    font-weight: 500;
    p {
        margin-bottom: 0;
    }
}

.page--bordered-block {
    padding: 1.2rem;
    margin: 1rem 0;
    border: 1px solid map-get($theme-scheme, border);
}

.page--contrast-block {
    margin: 1rem -3rem;
    padding: 1rem 3rem;
    background-color: map-get($theme-scheme, fill);
    color: #BDBDBD;

    h2, h3, h4, h5, h6 {
        color: map-get($theme-scheme, contrast-text);
        margin-bottom: 1.2rem;
    }

    .page--centered-block {
        color: #BDBDBD;
    }
}

@include media-breakpoint-down(xs) {
    .page--contrast-block {
        margin: 1rem -1.4rem;
    }
    .page-content__step-number {
        min-width: 40px;
        padding: 10px;
        font-size: 20px;
    }
}

.page--contrast-span {
    color: map-get($theme-scheme, main);
}
