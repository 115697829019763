@import "../variables";
@import '../mixins/breakpoints';

.product-replace {
    $main: map-get($theme-scheme, main);
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    font-size: $adaptive-13-font-size;
    font-weight: $font-weight-medium;
    cursor: help;

    &.inside-box {
        cursor: default;
    }

    svg {
        cursor: help;
    }

    .product-replace-title span {
        color: $main;
        text-transform: uppercase;
    }

    em {
        color: #ff3333;
        font-style: normal;
        font-weight: $font-weight-bold;
    }

    .product-replace-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;
        position: absolute;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        top: 100%;
        border: 1px solid $main;
        padding: 10px;
        color: inherit;
        background-color: #f5f5f5;
        transform: translateY(-10px);
        transition: opacity .2s,
        transform .2s,
        visibility 0s .2s;
    }

    .inside-replace {
        position: absolute;
        background-color: #858181;
        border-radius: 4px;
        color: white;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
        visibility: hidden;
        opacity: 0;
        padding: 15px 10px;
        white-space: wrap;
        transition: opacity 0.3s;
        width: 240px;
    }

    .available-replace-content {
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;
        position: absolute;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        right: 0;
        bottom: 120%;
        width: 100%;
        border: 1px solid $main;
        padding: 15px 10px;
        color: inherit;
        background-color: #f5f5f5;
        transform: translateY(-10px);
        transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
    }

    & .visible {
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        z-index: 99;
        transition-delay: 0s;
    }

    &:hover .product-replace-content {
        transition-delay: 0s;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
        z-index: 99;
    }

    .product-replace-content-header {
        font-weight: $font-weight-bold;
    }

    @include media-breakpoint-down(sm) {
        .product-replace-title {
            svg {
                width: 7px;
                height: 9px;
            }
        }
    }
}
