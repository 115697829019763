@import "../variables";

$border: map-get($theme-scheme, border);
$main: map-get($theme-scheme, main);
$contrast: map-get($theme-scheme, contrast-text);
$fill: map-get($theme-scheme, fill);

.wait-list-button {
    border: 1px solid $border;
    padding: 16px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    transition: all ease-in-out .2s;

    span {
        font-weight: $font-weight-semi-bold;
        font-size: $adaptive-13-font-size;
        transition: all ease-in-out .2s;
    }
    svg {
        fill: $fill;
        transition: all ease-in-out .2s;
    }

    &:hover {
        background-color: $fill;
        border-color: $fill;

        span {
            color: $contrast;
        }
        svg {
            fill: $contrast;
        }
    }
}

.wait-list-button-add-cart {
    border-color: $main;
    background-color: $main;

    span {
        color: $contrast;
    }
    svg {
        fill: $contrast;
    }
}

.wait-list-button-clear-all {}


