/*
// .product-gallery
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/scheme';
@import '../mixins/breakpoints';


.product-gallery {}

.product-gallery__wrapper {
    display: flex;
    justify-content: space-between;
    max-height: 600px;

    .product-gallery__thumbnails {
        width: 68px;
        position: relative;
        //display: flex;
        //flex-direction: column;
        //justify-content: space-between;
        //align-items: center;
        //gap: 10px;
        .slick-slider {
            margin: 34px 0;
        }
    }
    .product-gallery__featured {
        height: auto;
        width: calc(100% - 78px);
        margin-left: 10px;
    }
    .product-gallery__thumbnails--nav {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);

        &.up {
            top: 0;
            svg {
                transform: rotate(-90deg);
            }
        }
        &.down {
            bottom: 0;
            svg {
                transform: rotate(90deg);
            }
        }

        svg {
            fill: rgba(map-get($theme-scheme, border-contrast), 0.3);
        }
    }

    @include media-breakpoint-up(xxl) {
        .product-gallery__thumbnails {
            width: 88px;
        }

        .product-gallery__featured {
            width: calc(100% - 98px);
        }
    }

    @include media-breakpoint-down(md) {
        .product-gallery__thumbnails {
            display: none;
        }
        .product-gallery__featured {
            width: 100%;
            margin-left: 0;
        }
    }
}

.product-gallery__featured {
    position: relative;
    border: 1px solid map-get($theme-scheme, border);

    .owl-dots {
        position: absolute;
        bottom: 26px;
        width: 100%;
        display: none;
        justify-content: center;
    }

    .owl-dot.active span {
        background: map-get($theme-scheme, main);
        transform: scale(1.3);
    }

    .owl-dot span {
        width: 8px;
        height: 8px;
        margin: 5px;
        background: map-get($theme-scheme, fill);
        display: block;
        -webkit-backface-visibility: visible;
        transition: 0.3s ease;
        border-radius: 50%;
    }

    .owl-carousel {
        a {
            display: block;
        }
    }

    .image {
        max-width: 420px;
        margin: 0 auto;
    }
}
.product-gallery__zoom {
    $local-padding: 9px;
    $local-icon-size: 24px;

    display: var(--product-gallery-zoom-display, block);
    top: 0;
    position: absolute;
    padding: $local-padding;
    margin: 0;
    border: none;
    border-radius: ($local-padding + $local-icon-size * .5);
    fill: currentColor;
    z-index: 2;
    transition:
        background .15s,
        color .15s;

    @include define-button-scheme($btn-muted-light-scheme);
    @include direction {
        #{$inset-inline-end}: -($local-padding + $local-icon-size * .5);
    }

    &:focus {
        outline: none;
    }

    svg {
        display: block;
    }
}

.product-gallery__thumbnails-item {
    position: relative;
    display: block;
    overflow: hidden;
    border-radius: 1.5px;
    padding: 2px;
    cursor: pointer;

    &:before {
        position: absolute;
        display: block;
        content: '';
        top: 0;
        width: 100%;
        height: 100%;
        box-shadow: 0 0 0 2px $product-gallery-thumbnail-normal-border-color inset;
        transition: box-shadow .12s;

        @include direction {
            #{$inset-inline-start}: 0;
        }
    }
    &:hover:before {
        box-shadow: 0 0 0 2px $product-gallery-thumbnail-hover-border-color inset;
    }
}
.product-gallery__thumbnails-item--active {
    cursor: default;

    &:before,
    &:hover:before {
        box-shadow: 0 0 0 2px $product-gallery-thumbnail-current-border-color inset;
    }
}


.product-gallery--layout--quickview {
    .product-gallery__featured .image {
        width: 320px;
        max-width: 100%;
    }
    .product-gallery__zoom {
        display: none;
    }
}


.product-gallery--layout--product-sidebar {
    .product-gallery__featured .image {
        max-width: var(--product-gallery-image-max-width, 100%);
    }
}


.product-gallery--layout--product-full {
    .product-gallery__featured .image {
        max-width: var(--product-gallery-image-max-width, 100%);
    }
    .product-gallery__zoom {
        top: 0;

        @include direction {
            #{$inset-inline-end}: 0;
        }
    }
}

@include media-breakpoint-down(md) {
    .product-gallery__featured {
        .owl-dots {
            display: flex;
        }
    }
    .product-gallery__thumbnails {
        display: none;
    }
}
