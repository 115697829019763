@import "../variables";

$returnDefaultColor: #d0d0d0;
$returnHoverColor: map-get($theme-scheme, main);

.order-product {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content: space-between;
    align-items: center;
    width: 100%;
    margin: 8px 0;
    gap: 4px;
    & > a {
        grid-column: span 4;
        @include media-breakpoint-down(sm) {
            grid-column: span 2;
        }
    }
    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(2, 1fr);
    }
}

.order-product__title {
    display: flex;
    gap: 10px;
    align-items: center;
    flex-direction: column;
    justify-content: flex-start;

    img {
        height: 74px;
        width: 74px;
        object-fit: contain;
        border: 1px solid map-get($theme-scheme, border);
    }

    span {
        max-width: 300px;
        font-weight: $font-weight-bold;
        font-size: $adaptive-13-font-size;
        @include media-breakpoint-up(xs) {
            text-align: center;
            font-size: $default-font-size;
        }
    }
}

.order-product__property {
    display: flex;
    flex-direction: column;
    align-items: center;
    @include media-breakpoint-down(sm) {
        padding-top: 10px;
    }
}

.order-product__property-name {
    font-size: $adaptive-13-font-size;
    color: map-get($theme-scheme, secondary-text);
}

.order-product__property-value {
    font-weight: $font-weight-bold;
    font-size: $default-font-size;
}

.order-product__return {
    cursor: pointer;
    display: flex;
    gap: 6px;
    align-items: center;
    span {
        color: $returnDefaultColor;
        font-weight: $font-weight-semi-bold;
        transition: color ease-in-out 0.2ms;
    }
    svg {
        fill: $returnDefaultColor;
        transition: fill ease-in-out 0.2ms;
    }

    &:hover {
        span {
            color: $returnHoverColor;
        }
        svg {
            fill: $returnHoverColor;
        }
    }
}
