/*
// .block-header
*/
@import "../variables";
@import "../mixins/breakpoints";

$local-title-padding-y: 36px;
$local-breadcrumb-height: 21px;
$local-breadcrumb-padding-top: 16px;
$local-breadcrumb-padding-bottom: 40px;
$local-breadcrumb-padding-y: $local-breadcrumb-padding-top +
    $local-breadcrumb-padding-bottom;

.block-header {
}
.block-header__body {
    display: flex;
    flex-direction: column;
}
.block-header__breadcrumb {
    padding-top: $local-breadcrumb-padding-top;
    padding-bottom: $local-breadcrumb-padding-bottom;
}
.block-header__title {
    padding-top: $local-title-padding-y;
    padding-bottom: $local-title-padding-y - 16px;
    h1 {
        font-size: 2.25rem;
        font-weight: $font-weight-bold;
        margin: 0;
        word-wrap: break-word;
    }
    @include media-breakpoint-down(md) {
        h1 {
            font-size: 1.75rem;
        }
    }
}

@include media-breakpoint-up(xl) {
    //.block-header__title {
    //    margin: 0 auto;
    //}
    .block-header__breadcrumb + .block-header__title {
        margin-top: -($local-breadcrumb-height + $local-breadcrumb-padding-y);
    }
}
@include media-breakpoint-down(lg) {
    .block-header__title {
        padding-bottom: 6px;
        font-size: 2rem;
        padding-top: 32px;
    }
    .block-header__breadcrumb + .block-header__title {
        margin-top: -$local-breadcrumb-padding-bottom - 4px;
    }
}
