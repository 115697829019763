/*
// .about
*/
@import '../variables';
@import '../mixins/card';
@import '../mixins/direction';
@import '../mixins/breakpoints';
@import '../mixins/decor';


.about {
    .block-advantages__item {
        max-width: 28%;

        @include media-breakpoint-down(md) {
            max-width: unset;
        }
    }
}

.about__header{
    position: relative;

    &:before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;
        z-index: 1;
        background: linear-gradient(90.00deg, rgb(255, 255, 255),rgba(255, 255, 255, 0.67) 52.242%,rgba(255, 255, 255, 0) 100%);
    }
}

.about__header-content {
    position: relative;
    z-index: 2;
}

.about__body-1 {
    background-color: white;
}

.about-history--item {
    font-size: 1.5rem;
    font-weight: $font-weight-bold;
}
