@import '../bootstrap-variables';

@mixin container {
    max-width: 1920px;
    @each $breakpoint, $x-padding in $x-paddings {
        @media (min-width: #{map_get($grid-breakpoints, $breakpoint)}) {
            padding: 0 $x-padding;
        }
    }
}
