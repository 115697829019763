/*
// .logo
*/
@import '../variables';
@import '../functions';
@import '../mixins/scheme';
@import '../mixins/header';


@include desktop-header-variant-selector {
    .logo {}
    .logo__slogan {
        padding-top: 11px;
        font-size: 11px;
        line-height: 1;
        text-transform: uppercase;
    }
    .logo__image {
        display: flex;
        align-items: center;
        height: 100%;
    }
    .logo__slogan {
        display: none;
    }
    .logo__part-primary {
        fill: map_get($logo-scheme, primary);
    }
    .logo__part-secondary {
        fill: map_get($logo-scheme, secondary);
    }

    .ny-logo {
        position: relative;

        .ny-logo__img {
            height: 56px;
            width: 165px;
        }

        .ny-logo__hat {
            position: absolute;
            top: -6px;
            left: -9px;
            width: 34px;
            height: auto;
            transform-origin: bottom right; /* Позиция для поворота */
            animation: rotate-hat 3s ease-in-out infinite alternate;
        }

        @keyframes rotate-hat {
            0% {
                transform: rotate(0deg); /* Исходное положение */
            }
            50% {
                transform: rotate(-12deg); /* Поворот влево */
            }
            100% {
                transform: rotate(0deg); /* Поворот вправо */
            }
        }

        .ny-logo__bell-left {
            position: absolute;
            bottom: -9px;
            left: -16px;
            width: 10px;
            height: auto;
            transform-origin: center top; /* Точка вращения: верхняя центральная часть */
            animation: bell-left-ding 3s infinite ease-in-out;
        }

        .ny-logo__bell-right {
            position: absolute;
            bottom: -9px;
            right: -8px;
            width: 10px;
            height: auto;
            transform: scaleX(-1);
            transform-origin: center top; /* Точка вращения: верхняя центральная часть */
            animation: bell-right-ding 3s infinite ease-in-out;
        }

        /* Анимация левого колокольчика */
        @keyframes bell-left-ding {
            0%, 90% {
                transform: rotate(0deg);
            }
            91% {
                transform: rotate(-20deg); /* Вздрагивание влево */
            }
            93% {
                transform: rotate(20deg); /* Вздрагивание вправо */
            }
            97% {
                transform: rotate(-10deg); /* Легкий откат */
            }
            100% {
                transform: rotate(0deg); /* Возврат в исходное положение */
            }
        }

        /* Анимация правого колокольчика */
        @keyframes bell-right-ding {
            0%, 90% {
                transform: scaleX(-1) rotate(0deg);
            }
            91% {
                transform: scaleX(-1) rotate(20deg); /* Вздрагивание вправо */
            }
            93% {
                transform: scaleX(-1) rotate(-20deg); /* Вздрагивание влево */
            }
            97% {
                transform: scaleX(-1) rotate(10deg); /* Легкий откат */
            }
            100% {
                transform: scaleX(-1) rotate(0deg); /* Возврат в исходное положение */
            }
        }
    }

    .mobile-logo {
        .ny-logo__img {
            height: 34px;
            width: 103px;
        }
        .ny-logo__hat {
            width: 26px;
        }
        .ny-logo__bell-left, .ny-logo__bell-right {
            display: none;
        }

        svg {
            display: block;
            height: 32px;
            width: 103px;
        }
    }
}
