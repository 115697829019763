@import "../variables";
@import "../mixins/breakpoints";

.parent-category__wrapper {
    .image-link {
        height: 260px;
        display: flex;
        box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.25);

        img {
            width: 60%;
            margin: auto;
        }
    }
}
