@import '../variables';
@import '../mixins/breakpoints';

.products-empty {

    &__wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        gap: 1.5rem;
        margin: 1rem;
        text-align: center;

        .h5 {
            color: map-get($theme-scheme, secondary-text);
            font-weight: $font-weight-bold;
        }
    }

    &__image {
        width: 300px;
        height: 300px;
        border-radius: 100%;
        background-color: map-get($theme-scheme, fill);
        padding: 70px;
        animation: bounce 2s infinite;
        transform-origin: bottom center;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

        @keyframes bounce {
            0% {
                transform: scaleY(1);
            }
            10% {
                transform: scaleY(0.9); /* Сжатие перед прыжком */
            }
            30% {
                transform: translateY(-50px) scaleY(1.1); /* Растяжение в высшей точке */
            }
            50% {
                transform: translateY(0) scaleY(1);
            }
            60% {
                transform: scaleY(0.95); /* Небольшое сжатие после приземления */
            }
            70% {
                transform: scaleY(1);
            }
            100% {
                transform: scaleY(1);
            }
        }
    }

    &__actions {
        display: flex;
        justify-content: center;
        align-items: stretch;
        gap: 1rem;

        .btn {
            flex: 1;
        }
    }

    @include media-breakpoint-down(md) {
        margin: 1rem;

        &__image {
            width: 200px;
            height: 200px;
            padding: 40px;
        }

        &__actions {
            flex-direction: column;
        }
    }
}
