@import '../mixins/breakpoints';

.payment-details {
    .payment-table {
        width: 100%;
        border-collapse: collapse;
        background-color: #fff;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
        overflow: hidden;
        word-break: break-all;

        th, td {
            padding: 12px 16px;
            text-align: left;
            border-bottom: 1px solid #eee;
        }

        th {
            width: 200px;
            background-color: #f8f9fa;
            font-weight: 500;
            color: #495057;
        }

        td {
            color: #212529;
        }

        tr:hover {
            background-color: #f8f9fa;

            th {
                background-color: #f1f3f5;
            }
        }

        .purpose-description-row {
            word-break: break-word;

            td {
                padding: 16px;
                background-color: #f8f9fa;
                border-bottom: none;
                font-style: italic;
                color: #666;
            }
        }
        .value-cell {
            display: flex;
            align-items: center;
            gap: 8px;

            app-clipboard-button {
                margin-left: auto; // Кнопка будет справа
            }
        }
    }

    @include media-breakpoint-down(sm) {
        .payment-table {
            font-size: 10px;

            th, td {
                padding: 8px;
                width: unset;
            }
        }
    }
}
