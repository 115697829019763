::-webkit-scrollbar {
  width: 6px;
}

::-webkit-scrollbar-track {
  background-color: #e0e0e0;
  border-radius: 4px;
}

::-webkit-scrollbar-thumb {
  background-color: #F28B00;
  border-radius: 4px;
}

html, html * {
  scrollbar-color: #F28B00 #e0e0e0;
  scrollbar-width: thin;
}
