@import '../variables';
@import '../mixins/breakpoints';

.modal-wrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-wrapper__content {
    position: relative;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
}

.modal-wrapper__close-button {
    position: absolute;
    right: -30px;
    top: -30px;
    background: none;
    border: none;
    cursor: pointer;
    font-size: 20px;
    color: #fff;

    //@include media-breakpoint-down(xs) {
    //    right: 10px;
    //    top: 10px;
    //    color: map-get($theme-scheme, fill);
    //}
}

.modal-login__header {
    background-color: map-get($theme-scheme, fill);

    .modal-login__header-icon {
        svg {
            width: 100px;
        }
    }
}
