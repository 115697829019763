@import "../variables";
@import '../mixins/breakpoints';

.current-vehicle {
    position: relative;
    container: current-vehicle / inline-size;
}

@mixin compact-current-vehicle-wrapper {
    .current-vehicle__subtitle {
        display: none;
    }
}

.current-vehicle-wrapper {
    display: flex;
    gap: 1rem;
    background: map-get($theme-scheme, main);
    color: map-get($theme-scheme, contrast-text);
    position: relative;
    container: current-vehicle-wrapper / inline-size;

    @container current-vehicle (width < 900px) {
        @include compact-current-vehicle-wrapper;
    }

    @include media-breakpoint-down(md) {
        @include compact-current-vehicle-wrapper;
    }
}

.current-vehicle__title {
    .current-vehicle__title--icon {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-shrink: 0;
        width: 21px;
        height: 21px;
        border-radius: 100%;
        background-color: #4caf50;
        margin: 0 1rem;

        svg {
            width: 12px;
            height: auto;
        }
    }
}

.current-vehicle__subtitle {
    display: flex;
    align-items: center;
    gap: 1rem;

    .title {
        margin-right: 0.3rem;
    }
}

.current-vehicle__button {
    background-color: transparent;
    border: 0;
    padding: 0.8rem 1rem;
    outline: none;
    transition: background-color 0.2s ease-in-out;

    &:hover {
        background-color: rgba(map-get($theme-scheme, fill), 0.2);
    }
}
