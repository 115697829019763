@import "../mixins/breakpoints";
@import "../adapt";

.category-with-banner {
    display: flex;
    flex-direction: column;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: auto;
    gap: 14px;
    max-height: 600px;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        align-items: stretch;
    }

    .list-categories {
        width: 100%;
        flex: 0;
        @include media-breakpoint-up(lg) {
            gap: adapt-size-px(30);
            flex: 0 0 22%;
            min-width: 280px;
        }
    }
    .block-slideshow {
        width: 100%;
        max-width: 100%;
        flex: 0;
        @include media-breakpoint-up(lg) {
            flex: 1;
            overflow: hidden;
            //max-width: calc(100% - 25% - $gap);
        }
    }
}

//app-root {
//    @include media-breakpoint-down(md) {
//        app-block-products-carousel, app-block-car-picker {
//            display: none !important;
//        }
//    }
//}

