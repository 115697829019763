/*
// .products-list
*/
@import '../variables';
@import '../mixins/card';
@import '../mixins/product-card';
@import '../mixins/direction';
@import '../mixins/breakpoints';


@mixin local-layout($columns, $gutter) {
    .products-list__content {
        margin: -($gutter * .5);
    }
    .products-list__item {
        margin: $gutter * .5;
        width: calc((100% - #{$columns * $gutter}) / #{$columns});
    }
}
$local-columns: 4;
$local-gutter: 0px;


.products-list {}
.products-list__content {
    display: flex;
    flex-wrap: wrap;
}
.products-list__content + .products-list__content {
    margin-top: $local-gutter * .5;
}
.products-list__item {
    flex-shrink: 0;
    display: flex;

    .product-card {
        width: 100%;
    }
}


.products-list[data-layout="grid"] {
    .product-card {
        @include product-card-grid-base;
    }
}

.products-list--grid--3[data-layout="grid"] {
    @include local-layout(3, 0px);

    .products-list__head {
        display: none;
    }

    @include media-breakpoint-only(lg) {
        @include local-layout(3, 0px);
    }
    @media (min-width: 420px) and (max-width: 767px) {
        @include local-layout(2, 0px);
    }
    @media (max-width: 419px) {
        @include local-layout(1, 0px);
    }
}

.products-list--grid--4[data-layout="grid"] {
    @include local-layout(4, 0px);

    .products-list__head {
        display: none;
    }

    @include media-breakpoint-between(sm, xl) {
        @include local-layout(3, 0px);
    }
    @media (min-width: 400px) and (max-width: 575px) {
        @include local-layout(2, 0px);
    }
    @media (max-width: 399px) {
        @include local-layout(1, 0px);
    }
}

.products-list--grid--5[data-layout="grid"] {
    @include local-layout(5, 0px);

    .products-list__head {
        display: none;
    }

    @include media-breakpoint-only(lg) {
        @include local-layout(4, 0px);
    }
    @include media-breakpoint-only(md) {
        @include local-layout(3, 0px);
    }
    @media (min-width: 420px) and (max-width: 767px) {
        @include local-layout(2, 0px);
    }
    @media (max-width: 419px) {
        @include local-layout(1, 0px);
    }
}

.products-list--grid--6[data-layout="grid"] {
    @include local-layout(6, 0px);

    .products-list__head {
        display: none;
    }

    @include media-breakpoint-only(xl) {
        @include local-layout(5, 0px);
    }
    @include media-breakpoint-only(lg) {
        @include local-layout(4, 0px);
    }
    @include media-breakpoint-only(md) {
        @include local-layout(3, 0px);
    }
    @media (min-width: 420px) and (max-width: 767px) {
        @include local-layout(2, 0px);
    }
    @media (max-width: 419px) {
        @include local-layout(1, 0px);
    }
}

.products-list[data-with-features="true"] {
    .product-card {
        .product-card__features {
            display: block;
        }
    }
}

.products-list[data-layout="list"] {
    @include local-layout(1, 0px);

    .products-list__head {
        display: none;
    }

    .product-card {
        @include product-card-layout-list;
    }
}
