@import "../variables";

.ngx-dropdown-button {
    border: 1px solid rgb(232, 232, 232) !important;
    border-radius: 0 !important;
    min-height: 40px !important;

    .display-text {
        color: #242424;
    }

    .nsdicon-angle-down:before {
        border-style: solid;
        border-width: 2px 2px 0 0 !important;
        border-color: #646464;
        top: 3px !important;
        transform: scale(0.8, 0.7) rotate(135deg) !important;
        transition: transform .3s;
    }

    .nsdicon-angle-down:after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 50%;
        right: 24px;
        transform: translateY(-50%);
        height: 39px;
        border-left: 1px solid #e2e2e2;
    }

    .nsdicon-angle-down {
        right: 5px !important;
        &.up {
            transform: unset !important;
            transition: unset !important;

            &:before {
                transform: scale(0.8, 0.7) rotate(315deg) !important;
                transition: transform .3s;
            }
        }
    }
}

.ngx-dropdown-container {
    input {
        border-color: map-get($theme-scheme, main) !important;
    }
    .available-item {
        font-weight: $font-weight-medium !important;
        line-height: 1.6 !important;
    }
}
