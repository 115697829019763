@import '../variables';
@import '../mixins/direction';

.product-short {
    display: flex;
    background-color: #000;
    align-items: center;
    padding-left: 1rem;
    margin-left: auto;
    min-width: 380px;
    max-width: 480px;

    .buy-button {
        width: min-content;
    }
    .buy-button__btn {
        width: 100%;
        padding-left: 20px;
        padding-right: 20px;
    }
}

.product-short__info {
    display: flex;
    flex-direction: column;
    overflow: hidden;

    .product-short__name {
        font-size: 0.75rem;
        color: map-get($theme-scheme, contrast-text);
        font-weight: $font-weight-bold;
        overflow: hidden;
        text-overflow: ellipsis;
        a {
            color: map-get($theme-scheme, contrast-text);
        }
    }

    .product-short__price {
        font-size: 0.75rem;
        font-weight: $font-weight-bold;
        //color: map-get($theme-scheme, main);
        color: #dc1f1f;
        white-space: nowrap;
    }

    .product-short__price--old {
        font-size: inherit;
        font-weight: $font-weight-normal;
        text-decoration: line-through;
        color: $price-old-color;
    }
}
