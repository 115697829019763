@import '../variables';
@import '../mixins/card';
@import '../mixins/breakpoints';
@import '../mixins/direction';

.site-layout--row {
    max-width: 1320px;
    margin-left: auto;
    margin-right: auto;
}

$secondary-text-color: map-get($theme-scheme, secondary-text);

.site-layout-nav {
    //@include card;
    background-color: rgba(map-get($theme-scheme, border), 0.3);
}
.site-layout-nav__title {
    padding: 1.375rem 1.5rem;
    margin-bottom: 0;
    background-color: map-get($theme-scheme, fill);

    .site-layout-nav__title-name {
        font-weight: $font-weight-bold;
        color: map-get($theme-scheme, contrast-text);
    }
    .site-layout-nav__title-email {
        font-size: $adaptive-13-font-size;
        color: $secondary-text-color;
    }
}
.site-layout-nav__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
}
.site-layout-nav__item a {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: $secondary-text-color;
    padding: 14px 1.5rem;
    font-weight: $font-weight-semi-bold;
    svg {
        fill: $secondary-text-color;
    }
    transition: all 0.2s ease-in-out;
}
.site-layout-nav__item:hover a {
    background: map_get($card-scheme, hover);
}
.site-layout-nav__item--active a {
    color: inherit;
    svg {
        fill: map-get($theme-scheme, main);
    }
    background-color: rgba(map-get($theme-scheme, main), 0.07);
}
.site-layout-nav__divider {
    height: 1px;
    background: map_get($card-scheme, divider);
    margin: 10px 0;
}


@include media-breakpoint-up(lg) {
    .site-layout-nav__list {
        padding-bottom: 1.375rem;
    }
    .site-layout-nav__item--active a {
        @include direction {
            box-shadow: -#{3px * $transform-direction} 0 $account-nav-border-color inset;
        }
    }
}
@include media-breakpoint-down(md) {
    .site-layout-nav {
        overflow-x: auto;
        max-width: 100%;
    }
    .site-layout-nav__list {
        display: flex;
        white-space: nowrap;
        padding: 0 8px;
    }
    .site-layout-nav__title {
        display: none;
    }
    .site-layout-nav__item a {
        padding: 14px 20px;
    }
    .site-layout-nav__item--active a {
        box-shadow: 0 -3px $account-nav-border-color inset;
    }
}
