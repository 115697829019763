/*
// .widget-products
*/
@import '../variables';
@import '../mixins/direction';
@import "../mixins/loader";


.widget-products {
    > .btn {
        text-align: end;
        color: map-get($theme-scheme, main);
        box-shadow: unset;
    }
}
.widget-products__list {
    margin-bottom: $widget-padding;
    margin-right: 2px;
    max-height: 38vh;
    overflow-y: auto;
}
.widget-products__list--open {
    max-height: unset;
    overflow: visible;
}
.widget-products__loader {
    display: flex;
    justify-content: center;
    @include loader-overlay(#ebebeb, 0px, 0px, 20px, 2px, #000);
    position: relative;
    width: 100%;
    height: 50px;
    z-index: 10;
    pointer-events: auto;
    transition: opacity .2s ease-in-out;
}
.widget-products__item {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 0.5rem;
    border: 1px solid map-get($theme-scheme, border);
    background-color: #F7F9FE;
    transition: border-color 0.5s ease-in-out;

    mat-icon {
        transition: all 0.8s ease-in-out;
    }

    & + & {
        margin-top: 12px;
    }

    &:hover {
        border-color: map-get($theme-scheme, main);
        .widget-products__button {
            max-height: 100px;
            opacity: 1;
        }
        mat-icon {
            transform: scale(1.5) rotate(360deg);
        }
    }

    .product-replace {
        justify-content: flex-end;
    }
}
.widget-products__head {
    display: flex;
    align-items: center;
}
.widget-products__image {
    position: relative;
    border-radius: 0;
    overflow: hidden;
    flex-shrink: 0;
    width: 65px;
    height: 65px;

    @include direction {
        #{$margin-inline-end}: 12px;
    }

    &:before {
        display: block;
        position: absolute;
        content: '';
        width: 100%;
        height: 100%;
        border: 1px solid $image-border-color;
        border-radius: inherit;
        pointer-events: none;
        left: 0;
        top: 0;
    }

    img {
        max-width: 100%;
        height: 100%;
        object-fit: contain;
    }
}
.widget-products__info {
    padding-top: 2px;
}
.widget-products__name {
    $local-line-height: 17px;

    font-size: 14px;
    font-weight: $font-weight-medium;
    line-height: $local-line-height;
    height: $local-line-height * 2;
    overflow: hidden;

    a {
        color: inherit;
        transition: color .12s;
    }
    a:hover {
        color: $link-color;
    }
}
.widget-products__prices {
    display: flex;
    padding-top: 2px;
}
.widget-products__price {
    font-size: 1rem;
    font-weight: $font-weight-semi-bold;
    color: $price-color;

    & + & {
        @include direction {
            #{$margin-inline-start}: 3px;
        }
    }
}
.widget-products__price--new {
    color: $price-new-color;
}
.widget-products__price--old {
    font-weight: $font-weight-normal;
    font-size: 13px;
    text-decoration: line-through;
    color: $price-old-color;
    padding-top: 1px;
}
.widget-products__other {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 14px;
    font-weight: $font-weight-medium;

    .widget-products__other-title {
        font-weight: $font-weight-normal;
        opacity: 0.7;
    }
    .widget-products__other-value {
        text-align: right;
        &.contrast {
            color: map-get($theme-scheme, main);
        }
    }
}

.widget-products__button {
    opacity: 0;
    max-height: 0;
    transition: .5s ease-in-out;

    .buy-button .buy-button__btn,
    .wait-button .wait-button__btn {
        width: 100%;
    }
}
