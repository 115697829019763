/*
// .block-slideshow
*/
@import "../variables";
@import "../mixins/breakpoints";
@import "../mixins/direction";
@import "../adapt";
@import '../svg';

@mixin local-item-active {
    .owl-item.active & {
        @content;
    }
}

.block-slideshow {
    box-shadow: $block-slideshow-shadow;
}

.block-slideshow__item {
    border-radius: 2.5px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 0;

    &,
    &:hover {
        color: map_get($block-slideshow-scheme, opposite);
    }
}

.block-slideshow__item-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.block-slideshow__item-link {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 2.5px;
    overflow: hidden;
    //height: 700px;
    display: flex;
    flex-direction: column;
    padding: adapt-size-px(84) adapt-size-px(100);
    z-index: 1;

    &,
    &:hover {
        color: map_get($block-slideshow-scheme, opposite);
    }
}

.block-slideshow__item-image--mobile {
    display: none;
    background-position: top center;
}

.block-slideshow__item-offer {
    background: map_get($block-slideshow-offer-scheme, main);
    color: map_get($block-slideshow-offer-scheme, opposite);
    align-self: flex-start;
    font-size: adapt-size-px(36);
    line-height: 40px;
    font-weight: $font-weight-bold;
    padding: 2px 8px 0;
    margin-bottom: 12px;
    opacity: 0;
    transition: transform 0.5s 0.5s, opacity 0.5s 0.5s;

    @include direction {
        transform: translateX(#{-24px * $transform-direction});
    }

    @include local-item-active() {
        transform: translateX(0);
        opacity: 1;
    }
}

.block-slideshow__item-title {
    font-size: adapt-size-px(48);
    line-height: 56px;
    font-weight: $font-weight-bold;
    display: block;
    opacity: 0;
    transition: opacity 0.8s 0.2s;

    @include local-item-active() {
        opacity: 1;
    }
}

.block-slideshow__item-details {
    color: map_get($block-slideshow-scheme, opposite-alt);
    font-size: adapt-size-px(18);
    line-height: 30px;
    display: block;
    margin-top: 16px;
    flex-grow: 1;
    transform: translateY(12px);
    opacity: 0;
    transition: transform 0.5s 0.5s, opacity 0.5s 0.5s;

    @include local-item-active() {
        transform: translateX(0);
        opacity: 1;
    }
}

.block-slideshow__item-button {
    margin-top: 24px;
    align-self: flex-start;
    font-size: 18px;
    line-height: 28px;
    padding: 10px 40px;
    border-radius: 2px;
    transition: background-color 0.2s, color 0.2s, opacity 0.5s 0.5s;
    background: map_get($btn-primary-scheme, normal-bg-color);
    color: map_get($btn-primary-scheme, normal-font-color);
    opacity: 0;

    @include local-item-active() {
        opacity: 1;
    }

    &:hover {
        background: map_get($btn-primary-scheme, hover-bg-color);
        color: map_get($btn-primary-scheme, hover-font-color);
    }

    &:active {
        background: map_get($btn-primary-scheme, active-bg-color);
        color: map_get($btn-primary-scheme, active-font-color);
    }
}

.block-slideshow__carousel {
    $local-dot-size: 12px;
    $local-dot-gutter: 10px;

    height: 100%;
    position: relative;

    .owl-carousel {
        height: 100%;
    }
    .owl-stage-outer {
        height: 100%;
    }
    owl-stage > div {
        display: block;
        height: 100%;
        //margin: 0 2px;
    }
    .owl-stage {
        height: 100%;
        display: flex;
    }
    .owl-item {
        height: 100%;
        display: flex;
        align-items: stretch;
        margin-left: 1px;
    }

    .owl-dots {
        position: absolute;
        bottom: 26px;
        width: 100%;
        display: flex;
        justify-content: center;
    }

    .owl-dot.active span {
        background: map-get($theme-scheme, main);
        width: 30px;
    }

    .owl-dot span {
        width: 10px;
        height: 5px;
        margin: 5px 7px;
        background: map-get($theme-scheme, secondary-text);
        display: block;
        -webkit-backface-visibility: visible;
        transition: all 0.2s ease;
        border-radius: 0;
        box-shadow: 0 4px 4px 0 rgba(0, 0, 0, 0.25);
    }
    .owl-nav {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);

        .owl-next, .owl-prev {
            height: 94px;
            width: 64px;
            border-radius: 3px;
            background-color: rgba(map-get($theme-scheme, fill), 0.3);
            background-image: url(svg-carousel-right-arrow(map-get($theme-scheme, main)));
            background-repeat: no-repeat;
            background-size: 18px 33px;
            background-position: center;
        }
        .owl-prev {
            float: left;
            margin-left: 10px;
            transform: rotate(180deg);
        }
        .owl-next {
            float: right;
            margin-right: 10px;
        }
    }

    //.owl-carousel button.owl-dot {
    //    pointer-events: auto;
    //
    //    &:focus {
    //        outline: none;
    //    }
    //    &:before {
    //        display: block;
    //        content: '';
    //        width: $local-dot-size;
    //        height: $local-dot-size;
    //        border-radius: ($local-dot-size * .5);
    //        background: $block-slideshow-dot-normal-color;
    //        margin: ($local-dot-gutter * .5);
    //        transition: background .12s;
    //    }
    //    &:hover:before {
    //        background: $block-slideshow-dot-hover-color;
    //    }
    //    &:active:before {
    //        background: $block-slideshow-dot-active-color;
    //    }
    //    &.active:before {
    //        background: $block-slideshow-dot-current-color;
    //    }
    //}
}

@include media-breakpoint-down(xl) {
    .block-slideshow__item {
        padding: 68px 80px;
        height: 460px;
    }
    .block-slideshow__item-details {
        margin-top: 12px;
    }
}

@include media-breakpoint-down(lg) {
    .block-slideshow__item {
        padding: 60px;
        height: 380px;
    }
    .block-slideshow__item-offer {
        font-size: adapt-size-px(24);
        line-height: 28px;
        padding: 1px 6px 1px;
        margin-bottom: 8px;
    }
    .block-slideshow__item-title {
        font-size: 36px;
        line-height: 44px;
    }
    .block-slideshow__item-details {
        font-size: 16px;
        line-height: 26px;
        margin-top: 8px;
    }
    .block-slideshow__item-button {
        font-size: 16px;
        line-height: 24px;
        padding: 8px 28px;
    }
    .block-slideshow__carousel .owl-dots {
        bottom: 22px;
    }
}

@include media-breakpoint-down(md) {
    .block-slideshow__item {
        padding: 36px 40px 40px;
        height: 320px;
    }
    .block-slideshow__item-offer {
        margin-bottom: 10px;
    }
    .block-slideshow__item-title {
        font-size: 32px;
        line-height: 38px;
    }
    .block-slideshow__item-button {
        font-size: 15px;
        line-height: 24px;
        padding: 6px 24px;
    }
    .block-slideshow__carousel .owl-dots {
        bottom: 10px;
        .owl-dot span {
            width: 10px;
            height: 10px;
            margin: 2px;
        }
        .owl-dot.active span {
            width: 16px;
        }
    }
}

@include media-breakpoint-down(sm) {
    .block-slideshow__item {
        align-items: center;
        text-align: center;
        height: 480px;
    }
    .block-slideshow__item-image--desktop {
        display: none;
    }
    .block-slideshow__item-image--mobile {
        display: block;
    }
    .block-slideshow__item-offer {
        align-self: center;
    }
    .block-slideshow__item-details {
        display: none;
    }
    .block-slideshow__item-button {
        align-self: center;
    }
    .block-slideshow__item--dark {
        justify-content: center;
    }
    //
    .block-slideshow__item {
        padding: 28px 32px 32px;
        height: 360px;
    }
    .block-slideshow__item-offer {
        font-size: 20px;
        line-height: 24px;
        padding: 1px 6px 1px;
        margin-bottom: 8px;
    }
    .block-slideshow__item-title {
        font-size: 24px;
        line-height: 30px;
    }
    .block-slideshow__item-button {
        font-size: 14px;
        line-height: 21px;
        padding: 5.5px 20px;
        margin-top: 20px;
    }
    .block-slideshow__carousel .owl-prev,
    .block-slideshow__carousel .owl-next {
        display: none;
    }
}
