@import "../variables";
@import '../mixins/breakpoints';
@import "../adapt";

$background_color: map-get($theme-scheme, fill);
$border-color: map-get($theme-scheme, secondary-text);
$text-color: rgba(map-get($theme-scheme, contrast-text), 0.6);
$border-color-hover: map-get($theme-scheme, main);
$text-color-hover: map-get($theme-scheme, main);

.pargo-footer {
    width: 100%;
    background-color: $background_color;
    padding: 0;
}

.pargo-footer__widgets {
    padding: adapt-size-px(40) 0;
    border-bottom: 1px solid rgba(map-get($theme-scheme, block-bg), 0.1);
}

.pargo-footer__row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    gap: adapt-size-px(15);
    flex-wrap: wrap;

    @include media-breakpoint-up(lg) {
        flex-direction: row;
        justify-content: space-between;
        align-items: flex-start;
    }
}

.pargo-footer__logo {
    svg {
        height: auto;
        width: adapt-size-px(165);
    }
}

.pargo-footer__bottom {
    width: 100%;
    padding: adapt-size-px(10) 0;
}

.pargo-footer__bottom-row {
    display: flex;
    justify-content: space-between;
    align-items: center;

    .pargo-footer__copyright {
        font-size: $default-font-size;
        font-weight: 400;
        color: $text-color;
    }

    @include media-breakpoint-down(md) {
        flex-direction: column;
        justify-content: center;
    }
}

.footer-login-button {
    background: transparent;
    border: 1px solid $border-color;
    padding: adapt-size-px(10) adapt-size-px(20);
    span {
        color: $text-color;
        font-size: adapt-size-px(14);
        font-weight: 600;
    }
    svg {
        fill: $text-color;
        margin-right: adapt-size-px(15);
    }

    &:hover {
        border: 1px solid $border-color-hover;
        span {
            color: $text-color-hover;
        }

        svg {
            fill: $text-color-hover;
        }
    }
}
