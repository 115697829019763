/*
// .pargo-footer-contacts
*/
@import "../variables";
@import "../mixins/breakpoints";

$font-color: rgba(map_get($footer-scheme, opposite), 0.6);

.pargo-footer-contacts {
    display: block;
    text-align: center;
    @include media-breakpoint-up(xl) {
        text-align: left;
    }
    @include media-breakpoint-up(lg) {
        max-width: 30%;
    }
}
.pargo-footer-contacts__title {
    font-size: $default-font-size;
    color: map_get($footer-scheme, opposite);
    margin-bottom: 22px;
    font-weight: 600;

    @include media-breakpoint-down(md) {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}

.pargo-footer-contacts__item {
    display: flex;
    align-items: center;
    @include media-breakpoint-down(lg) {
        justify-content: center;
    }
    @include media-breakpoint-down(sm) {
        white-space: wrap;
    }

    white-space: wrap;
    div, span {
        font-size: $default-font-size;
        font-weight: 400;
        color: $font-color;
        line-height: 34px;
    }
    svg {
        fill: $font-color;
        margin-right: 15px;

        @include media-breakpoint-down(sm) {
            margin-right: 5px;
        }
    }
}
