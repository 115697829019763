/*
// .cart
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/breakpoints';

.modal {
    z-index: 200002;
}

.modal-dialog__cart {
    @include  media-breakpoint-up(md) {
        max-width: 80%;
    }
    @include media-breakpoint-up(xl) {
        max-width: 1100px;
    }
}

.cart-header {
    font-size: 24px;
}

.modal-header.sticky-top {
    position: sticky;
    top: 0;
    background-color: white;
    z-index: 10;
}

.sticky-bottom {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    z-index: 10;
    padding: 0;
    border-top: 1px solid #dee2e6;
}

.modal-scrollable-content {
    max-height: 60vh;
    overflow-y: auto;
}

.cart__table, .cart-items {
    flex-grow: 1;
}

.cart__totals {
    flex-shrink: 0;
}

.cart__totals-table {
    width: 100%;
    margin-bottom: 32px;
    font-size: 15px;

    a:hover {
        text-decoration: underline;
    }

    &,
    thead,
    tbody,
    tfoot,
    tr,
    th,
    td {
        display: block;
    }

    tr:after {
        display: block;
        content: '';
        clear: both;
    }

    th,
    td {
        padding: 0;
    }

    th {
        @include direction {
            float: $inline-start;
        }
    }

    td {
        @include direction {
            text-align: $inline-end;
        }
    }

    thead,
    tbody {
        line-height: 20px;
    }

    tbody {
        tr {
            & > * {
                padding-top: 8px;
            }
        }

        tr:last-child {
            & > * {
                padding-bottom: 10px;
            }
        }
    }

    tfoot {
        font-size: 20px;

        th {
            font-weight: $font-weight-medium;
        }

        td, th {
            padding-top: 12px;
            border-top: 1px solid map_get($card-scheme, divider);
        }

        td {
            letter-spacing: -.02em;
        }
    }
}

.footer-total {
    display: flex;
    justify-content: flex-end;
    gap: 40px;
    margin-right: 20px;
    align-items: center;
    padding: 6px 0;
}


@include media-breakpoint-down(xl) {
    .cart {
        flex-wrap: wrap;
    }
    .cart__table, .cart-items {
        width: 100%;
    }
}

.cart-continue__shopping {
    padding: 20px 0;
    text-align: center;
    margin-bottom: 20px;

    @include media-breakpoint-up(lg) {
        padding: 0 50px;
        text-align: left;
    }
}

