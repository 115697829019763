@import "../variables";

.general-properties {
    display: flex;
    flex-direction: column;
    gap: 0.8rem;
}

.general-properties__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: $adaptive-13-font-size;
    color: map-get($theme-scheme, secondary-text);

    .value {
        font-weight: $font-weight-semi-bold;
    }
}
