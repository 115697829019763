/*
// .pargo-footer-links
*/
@import '../variables';
@import '../functions';
@import '../mixins/breakpoints';
@import '../mixins/scheme';


.pargo-footer-links {
    display: block;
    text-align: center;
    @include media-breakpoint-up(xl) {
        text-align: left;
    }
}
.pargo-footer-links__title {
    display: flex;
    gap: 10px;
    font-size: $default-font-size;
    font-weight: 600;
    color: map_get($footer-scheme, opposite);
    margin-bottom: 22px;
    align-items: center;
    justify-content: center;
}
.pargo-footer-links__list {
    font-size: $default-font-size;
    font-weight: 400;
    line-height: 24px;
    list-style: none;
    padding: 0;
    margin: 0;

    a {
        color: rgba(map_get($footer-scheme, opposite), 0.6);
        transition: .15s;
    }
    a:hover {
        color: map_get($theme-scheme, main);
    }
}

.pargo-footer-links__button-arrow {
    display: none;
    transition:
        transform .2s,
        color .2s;

    svg {
        display: block;
        height: auto;
        width: 9px;
        fill: #fff;
    }
}

@include media-breakpoint-down(md) {
    .pargo-footer-links__list {
        display: none;
        max-height: 0;
        //visibility: hidden;
        //opacity: 0;
        transition: max-height .2s, opacity 0.2s, visibility 0.2s, height .2s;
    }
    .pargo-footer-links__button-arrow {
        display: block;
    }
    .pargo-footer-links--open {
        .pargo-footer-links__list {
            display: block;
            visibility: visible;
            opacity: 1;
            max-height: max-content;
        }
        .pargo-footer-links__button-arrow {
            transform: rotate(180deg);
        }
    }
}

.pargo-footer-links__item {
    padding: 5px 0;
}


@include media-breakpoint-down(sm) {
    .pargo-footer-links {
        text-align: center;
    }
    .pargo-footer-links__title {
        margin-bottom: 12px;
    }
}
