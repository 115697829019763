@import '../variables';

.block-home-h1 {
    padding: 0 0 30px 0;
    background-color: #fff;

    h1 {
        font-size: 1.6rem;
    }
}
