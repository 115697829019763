/*
// .indicator
*/
@import '../variables';
@import '../mixins/direction';
@import '../mixins/scheme';
@import '../mixins/header';
@import "../adapt";


@mixin local-define-scheme($scheme) {
    .indicator__icon {
        fill: map_get($scheme, contrast-text);
        svg {
            fill: map_get($scheme, contrast-text);;
        }
    }
    .indicator__title {
        color: rgba(map_get($scheme, contrast-text), 0.6);
    }
    .indicator__value {
        color: map_get($scheme, main);
    }

    //.indicator:hover,
    //.indicator--open {
    //    .indicator__button {
    //        background: map_get($scheme, hover);
    //    }
    //}
}


@include desktop-header-variant-selector {
    $local-open-transition-duration: .2s;

    .indicator {
        position: relative;
    }
    .indicator__button {
        display: flex;
        position: relative;
        color: inherit;
        background: transparent;
        border: none;
        align-items: flex-end;

        @include direction {
            #{$padding-inline-end}: 0.8rem;
        }

        &:focus {
            outline: none;
        }
        //&:hover {
        //    color: inherit;
        //    text-decoration: none;
        //}
    }
    .indicator__icon {
        $adapt-size: adapt-size-px(45);
        width: $adapt-size;
        height: $adapt-size;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
            display: block;

            @include direction {
                transform: scaleX($transform-direction);
            }
        }

        &:not(.with-icon-border) {
            svg {
                width: $adapt-size;
                height: auto;
            }
        }

        &.with-icon-border {
            border: 1px solid #E3E3E3;
            border-radius: 100%;
        }

        &.contrast-border {
            transition: background-color 0.3s ease-in-out;
            border-color: map-get($theme-scheme, main);

            &:hover {
                background-color: map-get($theme-scheme, main);
            }
        }
    }
    .indicator__right {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        @include direction {
            #{$margin-inline-start}: 12px;
        }
    }
    .indicator__counter {
        position: absolute;
        top: 5px;
        font-size: 10px;
        line-height: 1;
        padding: 2px 3px 1px;
        border-radius: (13px * .5);
        text-align: center;
        z-index: 0;

        @include direction {
            #{$inset-inline-end}: 4px;
        }

        &:before {
            display: block;
            position: absolute;
            content: '';
            top: 0;
            bottom: 0;
            z-index: -1;
            border-radius: 2.5px;

            @include direction {
                #{$inset-inline-start}: 0;
                #{$inset-inline-end}: 0;
                transform: skewX(#{-11deg * $transform-direction});
            }
        }
    }
    .indicator__title,
    .indicator__value {
        display: block;
        white-space: nowrap;
    }
    .indicator__title {
        font-size: $adaptive-13-font-size;
        max-width: 120px;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .indicator__value {
        font-size: adapt-size-px(14);
        font-weight: 600;
    }
    .indicator__content {
        pointer-events: none;
        top: 100%;
        position: absolute;
        visibility: hidden;
        transform-origin: top;
        transform: rotateX(60deg);
        opacity: 0;
        transition:
            transform $local-open-transition-duration,
            opacity $local-open-transition-duration,
            visibility 0s $local-open-transition-duration;

        //@include direction {
        //    #{$inset-inline-end}: 0;
        //}

        &.right {
            right: 0 !important;
        }

        &.left {
            left: 0 !important;
        }
    }


    /*
    // .indicator--trigger--click.indicator--open,
    // .indicator--trigger--hover:hover
    */


    .indicator--trigger--click.indicator--open,
    .indicator--trigger--hover:hover {
        .indicator__content {
            pointer-events: auto;
            z-index: 1;
            transition-delay: 0s, 0s, 0s;
            opacity: 1;
            visibility: visible;
            transform: rotateX(0deg);
        }
    }


    .indicator--mobile {
        .indicator__button {
            padding: 0 0 2px;
            height: 50px;
            width: 44px;
            min-width: auto;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 0;
        }
        .indicator__icon {
            padding: 0;
            position: relative;
            height: auto;
            width: auto;
        }
        .indicator__counter {
            top: -7px;

            @include direction {
                #{$inset-inline-end}: -7px;
            }
        }
    }


    // define scheme
    @include local-define-scheme($theme-scheme);

    .indicator__counter {
        color: map_get($indicator-counter-scheme, opposite);

        @include scheme-font-weight($indicator-counter-scheme, opposite, $font-weight-medium);

        &:before {
            background-color: map_get($indicator-counter-scheme, main);
        }
    }
}
