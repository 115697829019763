@import "../variables";
@import '../mixins/breakpoints';

.car-picker--letters {
    span {
        font-weight: $font-weight-bold;
        cursor: pointer;
        &.active {
            color: map-get($theme-scheme, main);
            cursor: default;
        }
    }
}

.car-picker--grid {
    display: grid;
    grid-auto-flow: row;
    gap: 10px;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(auto-fill, minmax(auto, 1fr));

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(4, 1fr);
    }

    @include media-breakpoint-down(sm) {
        grid-template-columns: repeat(3, 1fr);
    }

    @include media-breakpoint-down(xs) {
        grid-template-columns: repeat(1, 1fr);
    }
}

.car-picker--item {
    padding: 1rem;
    transition: all 0.3s ease-in-out;
    border: 1px solid map-get($theme-scheme, border);
    font-weight: $font-weight-medium;

    span {
        margin-left: 10px;
        color: map-get($theme-scheme, secondary-text);
        transform: scale(0.8);
    }

    &:hover {
        box-shadow: 0 0 12px map-get($theme-scheme, fill);
        background-color: map-get($theme-scheme, selected);
        border-color: map-get($theme-scheme, main);
    }

    &.car-picker--item--bold {
        font-weight: $font-weight-bold;
    }
}

.car-picker-border {
    width: 100%;
    height: 0;
    border-top: 1px solid map-get($theme-scheme, main);
    margin: 0 0 20px;
    grid-column: 1 / -1;
}
