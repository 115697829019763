@import "../variables";
@import "../mixins/breakpoints";
@import "../adapt";

$default-color: map_get($theme-scheme, fill);
$border-color: map_get($theme-scheme, border);

.block-advantages {
    padding: 60px 0;
    display: block;
    background: #fff;
}

.block-advantages__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    @include media-breakpoint-down(md) {
        margin: 30px 0;
    }
}

.block-advantages__content-header {
    color: $default-color;
    font-size: clamp(18px, 2vw, 40px);
    font-weight: 700;
    margin-bottom: 24px;
}

.block-advantages__content-row {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include media-breakpoint-up(lg) {
        flex-direction: row;
    }
}

.block-advantages__item-header {
    color: $default-color;
    font-size: clamp(15px, 1vw, 20px);
    font-weight: 600;
}

.block-advantages__item {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    max-width: 20%;
    text-align: center;

    .block-advantages__item-description {
        color: rgba($default-color, 0.7);
        font-size: $default-font-size;
        font-weight: 400;
        line-height: 160%;
    }

    svg {
        fill: $default-color;
        height: auto;
        width: adapt-size-px(121);
    }

    .block-advantages__horizontal-divider {
        width: 36px;
        border-top: 1px solid $border-color;
    }

    @include media-breakpoint-down(md) {
        max-width: unset;
    }
}

.block-advantages__vertical-divider {
    color: $border-color;
    height: 40px;
    width: 1px;
    border-right: 1px solid $border-color;
    display: none;
    @include media-breakpoint-up(lg) {
        display: block;
    }
}

