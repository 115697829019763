/*
// .empty-garage-menu
*/
@import '../variables';
@import '../mixins/header';


@include desktop-header-variant-selector {
    .empty-garage-menu {
        display: block;
        width: 360px;
        background-color: map_get($menu-scheme, main);
        box-shadow: map_get($menu-scheme, shadow);
        border-radius: 1.5px;
        color: map_get($menu-scheme, opposite);

        .default-pargo-button-inverse {
            width: 100%;

            svg {
                height: auto;
            }
        }
    }

    .empty-garage-menu__wrapper {
        padding: 1.5rem 1rem;
    }
}
