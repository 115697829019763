@import "../variables";
@import "../bootstrap-variables";
@import "../mixins/breakpoints";
@import "../themes/theme-pargo/theme-variables";

.switch-button {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: inherit;
    border: none;
    padding-left: 0;
}

.switch-input {
    display: none;
}

.switch-label {
    position: relative;
    display: inline-block;
    width: 50px;
    height: 24px;
    background-color: $border-color;
    border-radius: 4px;
    transition: 0.3s;
    &:hover,
    &:focus {
        cursor: pointer;
    }
}

.switch-label::after {
    content: "";
    position: absolute;
    width: 22px;
    height: 20px;
    background-color: white;
    border-radius: 4px;
    top: 2px;
    left: 2px;
    transition: transform 0.3s;
}

.switch-input:checked + .switch-label {
    background-color: #4caf50;
}

.switch-input:checked + .switch-label::after {
    transform: translateX(24px);
}

.switch-label::before {
    // content: "off";
    position: absolute;
    right: 5px;
    width: 18px;
    top: 50%;
    transform: translateY(-50%);
    font-size: 12px;
    color: #ba5757;
}

.switch-input:checked + .switch-label::before {
    // content: "on";
    left: 5px;
    right: 0;
    color: #fff;
}

.form-group-wrapper {
    position: relative;
    display: grid;
    grid-template-columns: 2fr 1fr;
    gap: 8px;
    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: auto;
    }
}

.only-available-styled {
    display: block;
    text-align: end;
    @include media-breakpoint-down(sm) {
        text-align: start;
    }
}

.form-group-checkbox {
    border: 1px dotted #d1d1d1;
    margin-bottom: 10px;
    padding: 16px 4px;
    @include media-breakpoint-down(sm) {
        width: 100%;
        & > label {
            display: block;
            text-align: center;
        }
    }
}

.email-warning {
    font-size: $adaptive-13-font-size;
    color: map-get($theme-scheme, main);
    //position: absolute;
    //bottom: 0;
    //left: 0;
    //@include media-breakpoint-down(sm) {
    //    position: static;
    //}
}

.switch-wrapper {
    justify-content: flex-end;
    padding: 0;
    @include media-breakpoint-down(sm) {
        justify-content: flex-start;
    }
}

.checkbox-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    @include media-breakpoint-down(lg) {
        flex-direction: column;
        align-items: flex-start;
    }
}

.checkbox-label {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    cursor: pointer;
    margin: 8px;
    font-size: 16px;
}

.checkbox-input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

.checkbox-custom {
    position: relative;
    display: block;
    height: 20px;
    width: 20px;
    background-color: #eee;
    outline: 1px solid #ccc;
}

.checkbox-text {
    margin-left: 6px;
}

.checkbox-input:checked + .checkbox-custom:after {
    content: "";
    position: absolute;
    display: block;
    top: 2px;
    left: 2px;
    width: 16px;
    height: 16px;
    background-color: $theme-color;
}

.catalog-email-wrapper {
    position: relative;
    margin-bottom: 40px;
    @include media-breakpoint-down(sm) {
        margin-bottom: 10px;
    }
}

.catalog-button-edit {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 0;
    top: 0;
    min-width: 140px;
    height: 22px;
    border: none;
    outline: none;
    background-color: $theme-color;
    color: #fff;
    transition: 0.3s;
    font-size: $default-font-size;
    @include media-breakpoint-down(sm) {
        min-width: 80px;
        font-size: $adaptive-14-font-size;
    }
    &:hover,
    &:focus {
        cursor: pointer;
        box-shadow: 0 0 1px $fill-color;
    }
}
