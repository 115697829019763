@import "../variables";

.product-option {
    display: flex;
    flex-direction: column;
    gap: 2px;
    align-items: flex-end;
    justify-content: center;
}

.product-option__item {
    display: flex;
    gap: 5px;
    align-items: center;
    justify-content: center;
    background-color: #F7F9FE;
    padding: 5px 10px;
}

.product-option__icon {
    display: flex;
    color: map-get($theme-scheme, main);
}

.product-option__label {
    color: map-get($theme-scheme, main);
}

