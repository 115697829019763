@import "../variables";
@import "../bootstrap-variables";
@import "../mixins/breakpoints";

.account-table {
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}

.account-table tr {
    padding: 0.3em;
    font-size: $default-font-size;
    @include media-breakpoint-down(xs) {
        font-size: $adaptive-14-font-size;
    }
}

.account-table th,
.account-table td {
    padding: 0.2em;
    text-align: center;
}

.account-table th {
    font-size: $default-font-size;
    @include media-breakpoint-down(sm) {
        font-size: $adaptive-14-font-size;
    }
}

@include media-breakpoint-down(xs) {
    .account-table {
        border: 0;
    }

    .account-table thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }

    .account-table tr {
        border-bottom: 3px solid #ddd;
        display: block;
        margin-bottom: 10px;
    }

    .account-table td {
        border-bottom: 1px solid #b5b3b3;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-size: $adaptive-14-font-size;
        text-align: right;
    }

    .account-table td::before {
        content: attr(data-label);
        color: #000;
        float: left;
        font-weight: bold;
    }

    .account-table tfoot td {
        border-bottom: 0;
    }

    .row-total td {
        border-bottom: 0;
        color: #fff;
        & .dark-text {
            color: #000;
        }
    }

    .dark-text td {
        color: #000;
    }
}
