@import "../variables";
@import '../mixins/scheme';
@import '../mixins/breakpoints';

.checkout-form--inactive {
    pointer-events: none;
    filter: opacity(0.4);
}

.is-invalid {
    .select2-selection--single {
        border: 1px solid map_get($form-control-invalid-scheme, focus-border-color) !important;
        background: map_get($form-control-invalid-scheme, normal-bg-color) !important;
    }
}

.checkout__body {
    padding: 2rem;
}

.checkout__delivery-options {
    display: flex;
    flex-direction: column;

    &.is-invalid {
        border: 1px solid $form-invalid-feedback-font-color;
    }
}

.checkout__payment-options {
    display: flex;
    gap: 10px;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0.5rem;

    &.is-invalid {
        border: 1px solid $form-invalid-feedback-font-color;
        //background: ;
    }
}

.checkout__delivery-item {
    border: 1px solid #E3E3E3;
    padding: 15px 20px;
    display: flex;
    flex-direction: column;
}

.checkout__delivery-item-content {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding-top: 10px;
}

.checkout__delivery-item-selected {
    border: 1px solid map_get($theme-scheme, main);
}

.checkout__payment-options-item {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.checkout__options--comment {
    border: 1px solid map_get($theme-scheme, main);
    background: rgba(227, 227, 227, 0.20);
    padding: 18px;
}

.checkout__subtitle {
    font-size: 16px;
    font-weight: $font-weight-semi-bold;
}

.checkout-comment {
    margin-top: 1.5rem;
}

@include media-breakpoint-down(md) {
    .checkout__body {
        padding: 0;
    }
}
