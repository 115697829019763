/*
// .block-products-carousel
*/
@import '../variables';
@import '../mixins/loader';
@import '../mixins/direction';
@import '../mixins/product-card';
@import '../svg';


$local-carousel-expand: 14px;


.block-products-carousel {
    padding: 1.5rem 0;
    display: block;
    background: #fff;
}

.block-products-carousel__carousel {
    position: relative;

    .owl-stage-outer {
        margin: -$local-carousel-expand;
        padding: $local-carousel-expand;
    }
    .owl-stage {
        display: flex;
    }
    .owl-item {
        flex-shrink: 0;
        display: flex;

        &:hover {
            z-index: 3;
        }
    }
    .owl-nav {
        position: absolute;
        width: 100%;
        top: 50%;
        transform: translateY(-50%);
        pointer-events: none;
    }
    .owl-prev {
        float: left;
        margin-left: 8px;
        transform: rotate(180deg);
    }
    .owl-next {
        float: right;
        margin-right: 8px;
    }

    .owl-prev,
    .owl-next {
        height: 94px;
        width: 64px;
        border-radius: 5px;
        background-color: rgba(map-get($theme-scheme, border-contrast), 0.3);
        background-image: url(svg-carousel-right-arrow(map-get($theme-scheme, fill)));
        background-repeat: no-repeat;
        background-size: 18px 33px;
        background-position: center;
        pointer-events: auto;

        &.disabled {
            display: none;
        }
    }

    .nav-disabled {
        .owl-nav {
            display: none;
        }
    }
}
.block-products-carousel__carousel-loader {
    @include loader-overlay(map_get($body-scheme, main), $local-carousel-expand, $local-carousel-expand, 100px, 2px, $body-loader-color);

    z-index: 2;
    visibility: hidden;
    opacity: 0;
    transition:
        opacity .3s,
        visibility 0s .3s;
}
.block-products-carousel__carousel--loading {
    &:not(.block-products-carousel__carousel--has-items) {
        min-height: 120px;
    }

    .block-products-carousel__carousel-loader {
        visibility: visible;
        transition-delay: 0s;
        opacity: 1;
    }
}
.block-products-carousel__column {
    display: flex;
    flex-direction: column;
    width: 100%;
}
.block-products-carousel__cell {
    display: flex;
    width: 100%;
    flex-basis: 100%;
    flex-grow: 1;

    .product-card {
        width: 100%;
    }
}


.block-products-carousel[data-layout="grid-4"],
.block-products-carousel[data-layout="grid-5"] {
    .block-products-carousel__cell + .block-products-carousel__cell {
        margin-top: 20px;
    }
}
.block-products-carousel[data-layout="grid-6"] {
    .block-products-carousel__cell + .block-products-carousel__cell {
        margin-top: 16px;
    }
}
.block-products-carousel[data-layout|="horizontal"] {
    .block-products-carousel__cell + .block-products-carousel__cell {
        margin-top: 14px;
    }
}

@include media-breakpoint-down(md) {
    .block-products-carousel__carousel {
        .owl-prev,
        .owl-next {
            height: 43px;
            width: 28px;
            border-radius: 5px;
            background-size: 8px auto;
        }
    }
}
