.product-brand {

    &.product-brand__link {
        cursor: pointer;
        transition: color 0.2s;

        &:hover,
        &:focus {
            color: map_get($theme-scheme, main);
        }
    }

    img {
        width: 36px;
        max-height: 22px;
        object-fit: contain;
    }
}
