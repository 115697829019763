@import "../variables";

.spinner-loading {
    position: relative;
    pointer-events: none;

    &:before {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.7);
        z-index: 1;
    }

    &:after {
        content: "";
        position: absolute;
        left: 50%;
        top: 50%;

        /* Фиксированные размеры для гарантии круглой формы */
        width: 40px;
        height: 40px;

        /* Центрирование с помощью transform */
        transform: translate(-50%, -50%);

        /* Круглая форма */
        border-radius: 50%;

        /* Стиль границы */
        border: 3px solid rgba(0, 0, 0, 0.1);
        border-top-color: map-get($theme-scheme, main);

        /* Поверх затемняющего слоя */
        z-index: 2;

        /* Анимация вращения */
        animation: spin 1s linear infinite;
    }

    &.small::after {
        width: 30px;
        height: 30px;
        border-width: 1px;
    }

    &.large::after {
        width: 60px;
        height: 60px;
        border-width: 4px;
    }

    /* Анимация вращения */
    @keyframes spin {
        0% { transform: translate(-50%, -50%) rotate(0deg); }
        100% { transform: translate(-50%, -50%) rotate(360deg); }
    }
}
