/*
// .clients
*/
@use 'sass:math';

@import "../variables";
@import "../functions";
@import "../mixins/breakpoints";
@import "../adapt";


.clients {
    display: block;
    position: relative;
}
.clients__button {
    display: flex;
    gap: 10px;
    align-items: center;
    padding: 0;
    position: relative;
    background-color: transparent;
    border: none;
    font-family: inherit;
    transition: color .2s;
    color: $topbar-text-color;
    fill: $topbar-text-color;

    &:focus {
        outline: none;
    }
    &:hover {
        color: $topbar-text-hover-color;
        fill: $topbar-text-hover-color;
    }
}
.clients__button-title {
    font-size: $adaptive-13-font-size;
    transition: color .2s;
}
.clients__button-arrow {
    transition:
        transform .2s,
        color .2s;

    svg {
        display: block;
        height: auto;
        width: adapt-size-px(9);
    }
}
.clients__menu {
    position: absolute;
    z-index: 1;
    visibility: hidden;
    opacity: 0;
    top: 100%;
    transform: translateY(-10px);
    transition:
        opacity .2s,
        transform .2s,
        visibility 0s .2s;
}
.clients__body {
    pointer-events: auto;
    display: flex;
    background-color: map_get($departments-menu-scheme, main);
    box-shadow: map_get($departments-menu-scheme, shadow);
    color: map_get($departments-menu-scheme, opposite);

    //@include direction {
    //    float: $inline-start;
    //}
    padding: 15px 20px;
}
.clients__list {
    white-space: nowrap;
    flex-shrink: 0;
    list-style: none;
    margin: 0;
    padding: 0;
}
.clients__link {
    font-size: $default-font-size;
    font-weight: 500;
    color: $topbar-list-item-color;
    fill: $topbar-list-item-color;
    &:hover {
        color: $topbar-list-item-hover-color;
        fill: $topbar-list-item-hover-color;
    }
}
.clients__item {
    color: inherit;
    display: block;
    position: relative;
    padding: 5px 0;
}
.clients__link-icon {
    margin-right: 15px;
}

.clients--open {
    .clients__menu {
        transition-delay: 0s;
        visibility: visible;
        opacity: 1;
        transform: translateY(0);
    }
    .clients__button-arrow {
        transform: rotate(180deg);
    }
}
.clients__button {
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}
.clients__arrow {
    $local-button-icon-width: 16px;
    $local-button-icon-offset: 14px;

    width: ($local-button-icon-width + $local-button-icon-offset * 2) - 3px;
}

.clients--menu {
    $fontColor: rgba(#fff, 0.7);
    $fontSize: 13px;

    .clients__button {
        height: auto;
    }
    .clients__button-title {
        font-size: $fontSize;
    }
    .clients__button-arrow {
        svg {
            width: 11px;
        }
    }
    &.clients--open {
        .clients__menu {
            max-height: max-content;
        }
    }
    .clients__menu {
        position: unset;
        max-height: 0;
    }
    .clients__body {
        background-color: transparent;
        box-shadow: unset;
    }
    .clients__link {
        color: $fontColor;
        fill: $fontColor;
        font-size: $fontSize;
    }
}
