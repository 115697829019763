@import "../variables";
@import "../bootstrap-variables";
@import "../mixins/breakpoints";
@import "../themes/theme-pargo/theme-variables";

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.request-inn-wrapper {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
}

.request-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
}

.request-label {
    width: 100%;
    margin: 0 auto;
    font-weight: 500;

    @include media-breakpoint-up(lg) {
        width: 80%;
    }
}

.request-pending {
    @include media-breakpoint-up(lg) {
        min-height: 350px;
    }
}

.request-button-apply {
    width: 100%;
    margin-left: auto;
    max-height: 40px;
    padding: 8px;
    cursor: pointer;

    &:disabled {
        opacity: 0.7;
        cursor: no-drop;
    }

    @include media-breakpoint-up(sm) {
        width: 250px;
    }
}

.brands-wrapper {
    @include media-breakpoint-up(sm) {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
    }
}
