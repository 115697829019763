@import "site/page-content";
@import "site/site-layout";

/* ----------------------------------------
// Common
// ---------------------------------------- */
@import 'common/animations';
@import 'common/base';
@import 'common/document';
@import 'common/grid';
@import 'common/header';
@import 'common/site';



/* ----------------------------------------
// Components
// ---------------------------------------- */
@import 'components/alert';
@import 'components/arrow';
@import 'components/breadcrumb';
@import 'components/btn';
@import 'components/button-toggle';
@import "components/buy-button";
@import 'components/card';
@import 'components/card-table';
@import "components/clients";
@import "components/clipboard_button";
@import 'components/collapse-area';
@import "components/collapse-picker";
@import "components/correct-cart-result";
@import "components/complex-pictogram";
@import 'components/container';
@import 'components/decor';
@import "components/expansion_content";
@import 'components/filters-button';
@import 'components/form';
@import 'components/form-control';
@import 'components/google-map';
@import 'components/image';
@import "components/input";
@import "components/input-car-plate";
@import "components/input-car-vin";
@import 'components/input-check';
@import 'components/input-check-color';
@import 'components/input-number';
@import 'components/input-radio';
@import 'components/input-radio-color';
@import 'components/input-radio-label';
@import "components/item-option";
@import 'components/layout-switcher';
@import 'components/loading-bar';
@import "components/map";
@import 'components/modal';
@import 'components/modal_wrapper';
@import 'components/ngx-select-dropdown';
@import 'components/ngx-slider';
@import 'components/ngx-toastr';
@import 'components/nouislider';
@import 'components/owl-prevent-click';
@import 'components/pagination';
@import "components/payment_details";
@import "components/product-brand";
@import "components/product-label";
@import "components/product-replace";
@import 'components/radio';
@import 'components/rating';
@import 'components/scroll-to-top-button';
@import 'components/scrollbar';
@import 'components/section-header';
@import 'components/select2';
@import 'components/select2-component';
@import 'components/share-links';
@import 'components/shared-article';
@import 'components/shop-features';
@import 'components/sidebar';
@import 'components/sign-form';
@import 'components/social-links';
@import 'components/spinner-loading';
@import 'components/status-badge';
@import 'components/tag-badge';
@import 'components/tags';
@import 'components/teammate';
@import 'components/timer';
@import 'components/tooltip';
@import 'components/typography';
@import 'components/vehicle-form';
@import 'vehicle/vehicle-select';
@import 'vehicle/vehicles-list';
@import 'components/view-options';
@import 'components/widget';



/* ----------------------------------------
// Filters
// ---------------------------------------- */
@import 'filters/filter';
@import 'filters/filter-category';
@import 'filters/filter-color';
@import 'filters/filter-list';
@import 'filters/filter-range';
@import 'filters/filter-range-input';
@import 'filters/filter-rating';
@import 'filters/filter-vehicle';



/* ----------------------------------------
// Footer
// ---------------------------------------- */

@import "footer-pargo/pargo-footer";
@import "footer-pargo/footer-contacts";
@import "footer-pargo/footer-links";
@import "footer-pargo/footer-social";



/* ----------------------------------------
// Pages
// ---------------------------------------- */
@import 'pages/about';
@import 'pages/contacts';
@import 'pages/faq';
@import 'pages/not-found';
@import "pages/page-two";
@import "pages/forgot-password";



/* ----------------------------------------
// Blocks
// ---------------------------------------- */
@import "blocks/block-advantages";
@import 'blocks/block-brands';
@import 'blocks/block-categories';
@import "blocks/block-category-slides";
@import "blocks/block-list-categories";
@import 'blocks/block-empty';
@import 'blocks/block-header';
@import 'blocks/block_home_h1';
@import "blocks/block-information";
@import "blocks/block-parent-categories";
@import "blocks/block-popular-brands";
@import 'blocks/block-products-carousel';
@import 'blocks/block-products-columns';
@import 'blocks/block-slideshow';
@import 'blocks/block-space';
@import 'blocks/block-split';
@import "blocks/block-vehicle_make";
@import "blocks/car-picker";


/* ----------------------------------------
// Widgets
// ---------------------------------------- */
@import 'widgets/widget-categories-list';
@import 'widgets/widget-categories';
@import 'widgets/widget-comments';
@import 'widgets/widget-filters';
@import 'widgets/widget-newsletter';
@import 'widgets/widget-posts';
@import 'widgets/widget-products';


/* ----------------------------------------
// Shop
// ---------------------------------------- */
@import 'shop/aggregate-category';
@import 'shop/analogs-table';
@import 'shop/applied-filters';
@import "shop/bom";
@import 'shop/cart';
@import 'shop/cart-items';
@import 'shop/cart-table';
@import 'shop/categories-list';
@import 'shop/category-card';
@import 'shop/checkout';
@import 'shop/checkout-form';
@import 'shop/checkout-login';
@import 'shop/compare';
@import 'shop/compare-table';
@import "shop/general-properties";
@import 'shop/order-header';
@import 'shop/order-list';
@import 'shop/order-success';
@import 'shop/parent-category';
@import 'shop/payment-methods';
@import 'shop/product';
@import 'shop/product-applicability';
@import 'shop/product-bom';
@import 'shop/product-card';
@import 'shop/product-form';
@import 'shop/product-gallery';
@import "shop/product-option";
@import "shop/product-page";
@import "shop/product-properties";
@import "shop/product-short";
@import 'shop/product-tabs';
@import 'shop/products_empty';
@import 'shop/products-list';
@import 'shop/products-view';
@import 'shop/quickview';
@import 'shop/review';
@import 'shop/reviews-list';
@import 'shop/reviews-view';
@import 'shop/spec';
@import "shop/stock-available";
@import 'shop/vehicle-picker-modal';
@import 'shop/wishlist';



/* ----------------------------------------
// Blog
// ---------------------------------------- */
@import 'blog/blog-view';
@import 'blog/comment';
@import 'blog/comments-list';
@import 'blog/comments-view';
@import 'blog/post';
@import 'blog/post-card';
@import 'blog/post-header';
@import 'blog/post-navigation';
@import 'blog/post-view';
@import 'blog/posts-list';
@import 'blog/posts-view';



/* ----------------------------------------
// Account
// ---------------------------------------- */
@import 'account/account-nav';
@import 'account/account-table';
@import 'account/dashboard';
@import "account/order";
@import "account/order-product";
@import "account/product-images";
@import 'account/profile-card';
@import "account/wait-list";
@import "account/account-catalog";
@import "account/account-b2b";

/*---------------------------------------
// Vehicle
// -------------------------------------- */
@import 'vehicle/compatible-vehicle';
@import 'vehicle/current-vehicle';
@import 'vehicle/garage-vehicle-selector';
@import 'vehicle/vehicles-list';
@import 'vehicle/vehicle-picker';
@import 'vehicle/vehicle-select';
@import 'vehicle/vehicle_table';

@import "material";
@import "font";
@import 'custom';
