@import '../variables';
@import '../mixins/status-badge';
@import '../mixins/card';
@import '../mixins/direction';
@import '../mixins/breakpoints';
@import '../mixins/loader';
@import '../mixins/tag-badge';
@import '../mixins/scheme';
@import "../adapt";


@mixin product-card-base() {
    $local-padding: 16px;
    display: block;
    box-shadow: 0 0 0 1px rgba(map-get($theme-scheme, border), 0.6);

    //&.product-card--out-of-stock * {
    //    filter: grayscale(1) opacity(0.9);
    //}
    //
    //&.product-card__out-of_stock .product-card__available_not_info,
    //&.product-card--out-of-stock .product-card__footer,
    //&.product-card--out-of-stock .product-card__footer * {
    //    filter: none;
    //}
    //
    //&.product-card--out-of-stock .product-card__footer .product-card__prices,
    //&.product-card--out-of-stock .product-card__footer .product-card__prices * {
    //    filter: grayscale(1) opacity(0.9);
    //}

    &.product-card--out-of-stock {
        .product-card__image::before {
            position: absolute;
            content: '';
            width: calc(100% - 2px);
            height: 100%;
            left: 50%;
            transform: translateX(-50%);
            z-index: 2;
            pointer-events: none;
            background: rgba(255, 255, 255, 0.8);
        }
    }

    $transition-speed: 0.3s;
    $shadow: map_get($card-scheme, shadow);
    &:hover {
        box-shadow: 0 0 15px 0 rgba(0, 0, 0, 0.35);
        z-index: 3;
        transition: box-shadow $transition-speed ease-in-out;
    }

    @include card;

    .product-card__title {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        text-overflow: ellipsis;
        line-height: 1.5;
        height: 3em; /* 1.5 (line-height) × 2 строки = 3em */
        white-space: normal;
        margin: 15px 0;
        color: inherit;
        font-size: 18px;
        font-weight: 500;
        padding-right: $local-padding;
        padding-left: $local-padding;

        &:hover {
            color: map-get($theme-scheme, main);
        }
    }

    .product-card__labels {
        margin: 10px 0;
        padding-right: $local-padding;
        padding-left: $local-padding;
    }

    .product-card__available_not_info {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: #f5f5f5;
        font-size: $adaptive-13-font-size;
        text-transform: uppercase;
        font-weight: $font-weight-medium;
        padding: 10px;
    }

    .product-card__general-name {
        display: none;
    }

    .product-card_vd {
        display: none;
    }

    .product-card__action {
        position: relative;
        display: block;
        padding: 8px;
        margin: 0;
        border: none;
        transition: color .08s,
        background .08s;

        @include define-button-scheme($btn-muted-scheme);

        svg {
            fill: currentColor;
            display: block;
        }

        &:focus {
            outline: none;
        }

        &:active {
            transition-duration: 0s;
        }

        &:last-child {
            @include direction {
                #{$border-end-start-radius}: 2px;
            }
        }
    }
    .product-card__action--loading {
        &,
        &:hover {
            cursor: default;
            background: transparent;
            color: transparent;
            transition-duration: 0s;
        }

        &:before {
            @include loader(16px, 2px, map_get($btn-muted-scheme, loader-color));
        }
    }
    .product-card__image {
        $image-height: adapt-size-px(190);
        $min-height: 100px;
        display: block;
        height: $image-height;
        min-height: $min-height;
        position: relative;

        .image__tag {
            height: $image-height;
            min-height: $min-height;
        }
    }
    .product-card__rating {
        color: map_get($card-scheme, opposite-alt);
        font-size: .8125rem;
    }
    .product-card__features {
        color: map_get($card-scheme, opposite-alt);
        font-size: 13px;
        line-height: 21px;

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
        }

        li {
            position: relative;

            @include direction {
                #{$padding-inline-start}: 12px;
            }

            &:before {
                display: block;
                position: absolute;
                content: '';
                width: 3px;
                height: 3px;
                border-radius: 1.5px;
                background: currentColor;
                top: 9px;
                opacity: .7;

                @include direction {
                    #{$inset-inline-start}: 3px;
                }
            }
        }
    }

    .product-card__info-name {
        display: flex;
        align-items: center;
        font-weight: 500;
        font-size: $default-font-size;
        line-height: 160%;
        text-align: right;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
    }

    .product-card__info-title {
        font-weight: 400;
        font-size: $default-font-size;
        line-height: 160%;
        opacity: 0.7;
    }

    .product-card__meta {
        font-size: 12px;
        line-height: 1;
        color: map_get($card-scheme, muted);
    }
    .product-card__prices {
        font-weight: $font-weight-medium;
        letter-spacing: -.04em;
        line-height: 1;
        color: $price-color;
    }
    .product-card__price--new {
        color: $price-new-color;
    }
    .product-card__price--old {
        font-weight: $font-weight-normal;
        font-size: 14px;
        text-decoration: line-through;
        color: $price-old-color;
    }
    .product-card__addtocart-full {
        position: relative;
        background: map_get($btn-primary-scheme, normal-bg-color);
        color: map_get($btn-primary-scheme, normal-font-color);
        border: none;
        border-radius: 2px;
        font-weight: $font-weight-medium;
        transition: background-color .15s,
        color .15s;

        &:focus {
            outline: none;
        }

        &:hover {
            background: map_get($btn-primary-scheme, hover-bg-color);
            color: map_get($btn-primary-scheme, hover-font-color);
        }

        &:active {
            background: map_get($btn-primary-scheme, active-bg-color);
            color: map_get($btn-primary-scheme, active-font-color);
        }

        &.product-card__addtocart-full--loading {
            background: map_get($btn-primary-scheme, normal-bg-color);
            color: transparent;
            cursor: default;

            &:after {
                @include loader(22px, 2px, map_get($btn-primary-scheme, normal-font-color));
            }
        }
    }
    .product-card__wishlist,
    .product-card__compare {
        position: relative;
        border: none;
        background: map_get($wishlist-button-scheme, normal-bg-color);
        color: map_get($wishlist-button-scheme, normal-font-color);
        fill: map_get($wishlist-button-scheme, normal-icon-color);
        border-radius: 2px;
        transition: background .15s,
        fill .15s;

        &:focus {
            outline: none;
        }

        &:hover {
            background: map_get($wishlist-button-scheme, hover-bg-color);
            fill: map_get($wishlist-button-scheme, hover-icon-color);
        }

        &:active {
            background: map_get($wishlist-button-scheme, active-bg-color);
            fill: map_get($wishlist-button-scheme, active-icon-color);
        }
    }
    .product-card__wishlist.product-card__wishlist--loading,
    .product-card__compare.product-card__compare--loading {
        background: map_get($wishlist-button-scheme, normal-bg-color);
        fill: transparent;
        cursor: default;
    }
    .product-card__wishlist--loading,
    .product-card__compare--loading {
        &:after {
            @include loader(16px, 2px, map_get($btn-muted-scheme, loader-color));
        }
    }
    .product-card__buy-button {
        $local-padding-x: adapt-size-px(15);
        width: 50%;
        border-radius: unset;
        font-size: $adaptive-13-font-size;
        font-weight: 700;
        padding-top: $local-padding-x;
        padding-bottom: $local-padding-x;

        .product-card__buy-button-content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            svg {
                height: auto;
                width: adapt-size-px(19);
            }
        }
    }
    .product-card__header {
        padding-top: 15px;
        padding-left: $local-padding;
        padding-right: $local-padding;
    }
    .product-card__name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        max-height: 36px;
        position: relative;
        padding-right: 40px;

        .product-card__name--okt {
            //min-height: 36px;

            .shared-article__text {
                font-size: 18px;
            }
        }

        .product-card__name--bom {
            position: absolute;
            right: 0;
            z-index: 3;
        }

        .product-card__name--bom-button {
            border: none;
            background-color: transparent;
            padding: 0;

            svg {
                fill: #8E8E8E;
                height: auto;
                width: adapt-size-px(49);
                transition: fill .2s;
            }

            &:hover {
                svg {
                    fill: map_get($theme-scheme, main);
                }
            }
        }
    }

    .product-card__addtocart-icon {
        position: relative;
        border: none;
        padding: 8px;
        border-radius: 2px;
        //background: transparent;
        background: map_get($addtocart-button-scheme, normal-bg-color);
        color: map_get($addtocart-button-scheme, default-font-color);
        z-index: 0;
        transition: color .2s;

        svg {
            display: block;
            fill: currentColor;
        }

        &:before {
            $local-expand: 4px;

            top: -($local-expand * .5);
            left: -($local-expand * .5);
            display: block;
            content: '';
            position: absolute;
            width: calc(100% + #{$local-expand});
            height: calc(100% + #{$local-expand});
            background: map_get($addtocart-button-scheme, default-bg-color);
            z-index: -1;
            border-radius: 50%;
            transform: scale(0);
            transition: transform .2s,
            background .2s;
        }

        &:focus {
            outline: none;
        }
    }
    &:hover,
    &:active {
        .product-card__addtocart-icon {
            color: map_get($addtocart-button-scheme, normal-font-color);

            &:before {
                transform: scale(1);
                background: map_get($addtocart-button-scheme, normal-bg-color);
            }

            &:hover {
                color: map_get($addtocart-button-scheme, hover-font-color);

                &:before {
                    background: map_get($addtocart-button-scheme, hover-bg-color);
                }
            }

            &:active {
                color: map_get($addtocart-button-scheme, active-font-color);

                &:before {
                    transition-duration: .05s;
                    background: map_get($addtocart-button-scheme, active-bg-color);
                }
            }
        }
    }

    .product-card__addtocart-icon--loading {
        cursor: default;

        &:after {
            @include loader(22px, 2px, map_get($addtocart-button-scheme, normal-font-color));
        }
    }
    &,
    &:hover,
    &:active {
        .product-card__addtocart-icon--loading {
            &,
            &:hover,
            &:active {
                color: transparent;

                &:before {
                    transform: scale(1);
                    background: map_get($addtocart-button-scheme, normal-bg-color);
                }
            }
        }
    }

    .product-card__price--wrapper {
        display: flex;
        flex-direction: column;
        gap: 10px;

         @include media-breakpoint-down(sm) {
           text-align: center;
        }

        .product-card__price--new,
        .product-card__price--current {
            font-size: adapt-size-px(22);
            font-weight: 700;
        }
    }

    .product-card__general {
        display: flex;
        flex-direction: column;
    }

    .product-card__stocks {
        display: flex;
        flex-direction: column;
        align-items: center;
        white-space: nowrap;
        position: absolute;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        right: 0;
        bottom: 120%;
        padding: 10px;
        border-radius: 4px;
        color: map-get($theme-scheme, contrast-text);
        background-color: map-get($theme-scheme, border-contrast);
        font-size: $adaptive-13-font-size;
        font-weight: $font-weight-medium;
        transform: translateY(-10px);
        transition:
            opacity 0.2s, transform 0.2s, visibility 0s 0.2s;

        &.visible {
            opacity: 1;
            visibility: visible;
        }
    }
}


@mixin product-card-grid-base() {
    .product-card__addtocart-full,
    .product-card__wishlist,
    .product-card__compare {
        display: none;
    }

    $local-padding: 16px;
    $local-actions-transition-duration: .2s;
    $local-actions-transition-delay: .05s;

    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 400px;

    &:after {
        display: block;
        position: static;
        content: '';
        order: 1;
        flex-grow: 1;
    }

    .product-card__badges {
        position: absolute;
        top: $local-padding;
        display: flex;
        z-index: 1;
        flex-direction: column;
        align-items: flex-start;

        @include direction {
            #{$inset-inline-start}: $local-padding;
        }

        & > * + * {
            margin-top: 3px;
        }
    }
    .product-card__actions-list {
        position: absolute;
        overflow: hidden;
        z-index: 1;

        @include direction {
            #{$inset-inline-end}: 0;
        }
    }
    .product-card__image {
        display: block;
        position: relative;

        .image__body {
            padding-bottom: unset;
        }
    }
    .product-card__fit {
        position: absolute;
        bottom: 8px;

        @include status-badge-hide-text;

        @include direction {
            #{$inset-inline-start}: calc(#{$local-padding} - 3px);
        }
    }
    .product-card__meta {
        padding: 6px $local-padding;
    }
    //.product-card__name {
    //    padding: 0 $local-padding;
    //    line-height: 1.1875;
    //}
    .product-card__rating {
        margin: -3px 0 -2px 0;
        padding: 8px $local-padding 0;
        display: flex;
        flex-wrap: wrap;
    }
    .product-card__rating-stars,
    .product-card__rating-label {
        margin: 3px 0 2px 0;
    }
    .product-card__rating-stars {
        @include direction {
            #{$margin-inline-end}: 7px;
        }
    }
    .product-card__rating-label {
        line-height: 1;
    }
    .product-card__features {
        padding: 10px $local-padding 0;
        margin-bottom: -2px;
        display: none;
    }
    .product-card__content {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
    }
    .product-card__stocks {
        width: 100%;
    }
    .product-card__footer {
        padding: $local-padding;
        align-items: flex-end;
        display: flex;
        order: 2;
    }
    .product-card__prices {
        flex-grow: 1;
        font-size: 18px;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
    }
    .product-card__addtocart-icon {
        margin: -3px;
    }

    @media (hover: hover) {
        .product-card__action--wishlist,
        .product-card__action--compare {
            transition: transform $local-actions-transition-duration,
            opacity $local-actions-transition-duration,
            color .12s,
            background .12s;
            opacity: 0;
            will-change: transform;

            @include direction {
                transform: translateX(#{100% * $transform-direction}) translateX(6px);
            }
        }

        &:hover,
        &:active {
            .product-card__action--wishlist,
            .product-card__action--compare {
                transform: none;
                opacity: 1;
            }
        }
    }

    .product-card-properties {
        display: flex;
        justify-content: space-between;
        margin-top: -15px;
        margin-bottom: 0;
        padding-right: $local-padding;
        padding-left: $local-padding;
        align-items: center;
    }

    .product-card__info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0 $local-padding;
    }

    .product-card__info-row {
        display: flex;
        gap: 10px;
        cursor: default !important;

        .product-card__info-amount {
            display: flex;
            align-items: center;
            gap: 8px;
            cursor: help;

            svg {
                transition: transform 0.3s ease;
                width: 20px;
                height: 20px;
            }

            &:hover svg,
            &:focus-within svg {
                transform: scale(1.1);
            }
        }

        .product-card__available-group {
            display: flex;
            justify-content: space-between;
            text-wrap: wrap;
            align-items: flex-end;
            margin-bottom: 4px;
            white-space: normal;

            &:not(:last-child) {
                padding-bottom: 8px;
                border-bottom: 1px solid $typography-hr-color;
            }
        }
    }

    .buy-button {
        width: unset;

        .buy-button__btn {
            padding-left: 20px;
            padding-right: 20px;

            .buy-button__content > span {
                display: none;
            }
            svg {
                width: 30px;
            }
        }
    }

    .product-card__replace {
        position: absolute;
        top: 48px;
        right: 50%;
        transform: translateX(50%);
    }

    @include media-breakpoint-down(md) {
        &:hover {
            box-shadow: 0 0 0 1px rgba(227, 227, 227, 0.6);
        }
        .product-card__title {
            font-size: 12px;
            margin: 5px 0;
        }
        .product-card__labels {
            margin: 1px 0;
        }
        .product-card-properties {
            margin-top: -8px;
            margin-bottom: 0;
        }
        .complex-pictogram {
            scale: 0.6;
        }
        .product-card__info-title,
        .product-card__info-name,
        .shared-article__text,
        .product-card__available-group,
        .product-property__item-name,
        .product-property__item-value {
            font-size: 10px !important;
        }
        .product-card__image {
            $image-height: adapt-size-px(120);
            $min-height: 100px;
            height: $image-height;
            min-height: $min-height;
            position: relative;
            margin: 0.8rem 0;

            .image__tag {
                height: $image-height;
                min-height: $min-height;
            }
        }
        .product-card__name {
            padding-right: 28px;
        }
        .product-card__name--bom-button svg {
            width: 28px;
            height: auto;
        }
        .product-card__info-name {
            mat-icon {
                display: none;
            }
        }
        .product-card__info-row {
            align-items: center;
        }
        .product-card__replace {
            top: 32px;
        }
    }
}


@mixin product-card-horizontal-base() {
    .product-card__action--wishlist,
    .product-card__action--compare,
    .product-card__features,
    .product-card__addtocart-icon,
    .product-card__addtocart-full,
    .product-card__wishlist,
    .product-card__compare,
    .product-card__meta,
    .product-card__fit {
        display: none;
    }

    $local-image-width: 112px;

    position: relative;
    display: flex;

    .product-card__image {
        padding: 8px 10px;
        width: $local-image-width;
        flex-shrink: 0;
    }
    .product-card__badges {
        position: absolute;
        top: 10px;
        display: flex;
        z-index: 1;
        flex-direction: column;
        align-items: flex-start;

        @include direction {
            #{$inset-inline-start}: 10px;
        }

        & > * + * {
            margin-top: 3px;
        }
    }

    .tag-badge {
        @include tag-badge-size(16px, 5px, -20deg);
    }

    .product-card__actions-list {
        position: absolute;

        @include direction {
            #{$inset-inline-end}: 0;
        }
    }

    .product-card__rating {
        margin: -3px 0 -2px 0;
        padding-top: 7px;
        display: flex;
        flex-wrap: wrap;
    }
    .product-card__rating-stars,
    .product-card__rating-label {
        margin: 3px 0 2px 0;
    }
    .product-card__rating-stars {
        @include direction {
            #{$margin-inline-end}: 7px;
        }
    }
    .product-card__rating-label {
        line-height: 1;
    }

    .product-card__prices {
        position: absolute;
        bottom: 14px;
        flex-grow: 1;
        font-size: 16px;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;

        @include direction {
            #{$inset-inline-start}: $local-image-width;
        }
    }
}

@mixin product-card-horizontal-base-md() {
    $local-image-width: 136px;

    .product-card__image {
        padding: 8px 16px;
        width: $local-image-width;
    }
    .product-card__info {
        padding-top: (12px + 2px);
        padding-bottom: (16px + 16px + 10px);
    }
    .product-card__prices {
        bottom: 16px;

        @include direction {
            #{$inset-inline-start}: $local-image-width;
        }
    }
}


@mixin product-card-list-base() {
    padding: 10px 24px;
    justify-content: space-between;
    align-items: center;

    .product-card__addtocart-icon,
    .product-card__header,
    .product-card__action--wishlist,
    .product-card__action--compare {
        display: none;
    }

    .product-card__available_not_info {
        margin-bottom: -4px;
        width: 100%;
    }

    .product-card__content {
        flex: 1;
        display: flex;
        justify-content: space-around;
        align-items: flex-start;
    }

    .product-card__vd {
        display: block;
        align-self: stretch;
        border-left: 1px solid rgb(36, 36, 36);
        opacity: 0.1;
        margin: 0 10px;
    }

    .product-card-properties {
        display: flex;
        justify-content: space-between;
        padding: 0;
        align-items: flex-start;
        width: 100%;
        margin: -5px 5px 5px;
    }

    .product-properties {
        max-width: 60%;
    }
    .complex-pictogram {
        height: 40px;

        .complex-pictogram__item-value {
            bottom: -20px;
        }
    }

    .product-card__info {
        display: flex;
        flex-direction: column;
        gap: 8px;
        padding: 0;
        width: 100%;
        max-width: 245px;
        margin: auto 10px;
        align-items: flex-start;

        @include media-breakpoint-down(sm) {
            min-width: 220px;
        }
    }

    .product-card__info-row {
        display: flex;
        justify-content: space-between;
        gap: 10px;

        .product-card__info-name {
            margin-left: 6px;
        }

        .product-card__info-amount {
            display: flex;
            align-items: center;
            justify-content: center;

            svg {
                height: 20px;
                width: 20px;
                transition: transform 0.3s ease;
            }

            &:hover svg,
            &:focus-within svg {
                transform: scale(1.1);
            }
        }

        .product-card__available-group {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            text-wrap: wrap;
            margin-bottom: 5px;
            white-space: normal;

            &:not(:last-child) {
                padding-bottom: 8px;
                border-bottom: 1px solid $typography-hr-color;
            }
        }
    }


    .product-card__general-name {
        display: block;

        .product-card__name {
            padding-right: 32px;
        }

        .product-card__name--bom-button svg {
            width: 32px;
        }
    }

    .buy-button {
        width: 100%;
    }

    display: flex;
    position: relative;

    .product-card__image {
        flex-shrink: 0;
        $image-height: adapt-size-px(180);
        height: $image-height;

        .image__tag {
            height: $image-height;
        }
    }

    .product-card__badges {
        position: absolute;
        display: flex;
        z-index: 1;
        flex-direction: column;
        align-items: flex-start;

        & > * + * {
            margin-top: 3px;
        }
    }
    .product-card__actions-list {
        position: absolute;
        overflow: hidden;
        z-index: 1;

        @include direction {
            #{$inset-inline-end}: 0;
        }
    }
    .product-card__rating {
        margin: -3px 0 -2px 0;
        padding: 8px 0 0;
        display: flex;
        flex-wrap: wrap;
    }
    .product-card__rating-stars,
    .product-card__rating-label {
        margin: 3px 0 2px 0;
    }
    .product-card__rating-stars {
        @include direction {
            #{$margin-inline-end}: 7px;
        }
    }
    .product-card__rating-label {
        line-height: 1;
    }
    .product-card__fit {
        @include status-badge-show-text;
    }
    .product-card__labels {
        order: 1;
        padding: 0;
        margin: 5px 0;
    }
    .shared-article__text {
        font-size: $default-font-size;
    }
    .product-card__title {
        font-size: $default-font-size;
        padding: 0;
        margin: 5px 0;
    }
    .product-card__general {
        width: 100%;
        max-width: 286px;
    }
    .product-card__price--wrapper {
        min-height: 40px;
    }
    .product-card__price--old {
        order: 1;
    }
}


@mixin product-card-list-desktop-base() {
    $local-image-width: 200px;
    $local-image-padding-x: 24px;
    $local-info-padding-x-start: 0;
    $local-info-padding-x-end: 20px;
    $local-info-padding-y-start: 20px;
    $local-info-padding-y-end: 10px;
    $local-fit-bottom: 24px;
    $local-fit-height: 23px;

    .product-card__image {
        padding: 0;
        width: $local-image-width;
    }
    .product-card__badges {
        top: 16px;

        @include direction {
            #{$inset-inline-start}: 16px;
        }
    }
    .product-card__fit {
        position: absolute;
        bottom: $local-fit-bottom;

        @include direction {
            #{$inset-inline-start}: $local-image-width + $local-info-padding-x-start - 2px;
        }
    }

    .product-card__meta {
        position: absolute;
        bottom: 0;
        padding: 0;
        height: 38px;
        line-height: 36px;
        border-top: 1px solid map_get($card-scheme, divider);
        text-align: center;
        width: 180px;

        @include direction {
            #{$inset-inline-end}: 20px;
        }
    }

    .product-card__features {
        font-size: 14px;
        padding-top: 10px;

        li + li {
            margin-top: 2px;
        }
    }

    // footer
    .product-card__footer {
        width: 190px;
        align-items: stretch;
        display: flex;
        order: 2;
        flex-shrink: 0;
        flex-direction: column;
        padding: 0;

        //@include direction {
        //    #{$border-inline-start}: 1px solid map_get($card-scheme, divider);
        //}
    }
    .product-card__prices {
        padding: 8px 0;
        font-size: 20px;
        display: flex;
        align-items: flex-end;
        flex-wrap: wrap;
        justify-content: stretch;
    }
    .product-card__addtocart-full {
        display: block;
        height: 36px;
        padding: 0 20px;
        width: 100%;
        margin-bottom: 10px;
    }
    .product-card__wishlist,
    .product-card__compare {
        font-size: 13px;
        line-height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 7px 10px 5px;

        svg {
            position: relative;
            top: -1px;

            @include direction {
                #{$margin-inline-end}: 8px;
            }
        }
    }
    .product-card__wishlist {
        svg {
            @include direction {
                #{$margin-inline-start}: -3px;
                #{$margin-inline-end}: 7px;
            }
        }
    }
    .product-card__compare {
        svg {
            @include direction {
                #{$margin-inline-start}: -2px;
            }
        }
    }
    .product-card__wishlist--loading:after {
        @include direction {
            #{$inset-inline-start}: 7px;
            #{$inset-inline-end}: auto;
        }
    }
    .product-card__compare--loading:after {
        @include direction {
            #{$inset-inline-start}: 8px;
            #{$inset-inline-end}: auto;
        }
    }
}

@mixin product-card-list-compact {
    padding: 10px;
    .product-card__image {
        $image-height: adapt-size-px(100);
        height: $image-height;
        width: 140px;

        .image__tag {
            height: $image-height;
        }
    }
    .product-card__footer {
        width: 120px;
    }
    .product-card__info {
        max-width: 160px;
    }
    .product-card__general {
        margin-left: 10px;
    }
}

@mixin product-card-list-mini {
    padding: 10px;
    .product-card__image {
        min-height: unset;
        $image-height: adapt-size-px(50);
        height: $image-height;
        width: 60px;

        .image__tag {
            height: $image-height;
            min-height: unset;
        }
    }
    .product-card__footer {
        width: auto;
        align-items: flex-end;
    }
    .product-card__vd,
    .product-card-properties,
    .product-card__name--bom-button,
    .product-card__info {
        display: none;
    }
    .product-label {
        min-height: unset;
    }
    .product-card__name {
        padding: 0;
    }
    .product-card__labels {
        margin: 0 5px 0 0;
    }
    .product-card__general {
        max-width: unset;
        margin: 0 14px;
    }
    .product-card__title {
        margin: 0;
    }
    .shared-article__text {
        font-size: $default-font-size !important;
    }
    .product-card__prices {
        padding: 0;
        margin-bottom: 5px;
    }
    .product-card__price--wrapper {
        min-height: unset;
    }
    .buy-button {
        width: 50px;
        .btn {
            span {
                display: none;
            }
        }
    }
    .owl-nav {
        display: none !important;
    }
}

@mixin product-card-layout-list() {
    @include product-card-list-base;
    @include product-card-list-desktop-base;

    @include media-breakpoint-down(xl) {
        @include product-card-list-compact;
    }

    @include media-breakpoint-down(sm) {
        @include product-card-list-mini;
    }
}
