@import "../variables";

.clipboard_button {
    padding: 0;
    border: none;
    background-color: transparent;

    &:active {
        svg {
            fill: map_get($theme-scheme, main);
        }
    }
}
