@charset "UTF-8";
/* ----------------------------------------
// Components
// ---------------------------------------- */
/*
// .breadcrumb
*/
/* ----------------------------------------
// Header
// ---------------------------------------- */
/*
// .account-menu
*/
.site--desktop-header--pargo-one .account-menu {
  display: block;
  width: 280px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.5px;
  color: #242424;
}
.site--desktop-header--pargo-one .account-menu__form {
  padding: 0 30px 32px;
}
.site--desktop-header--pargo-one .account-menu__form-title {
  text-align: center;
  padding: 32px 0 26px;
  font-weight: 500;
}
.site--desktop-header--pargo-one .account-menu__form-button {
  margin-top: 32px;
  text-align: center;
}
.site--desktop-header--pargo-one .account-menu__form-button .btn {
  padding-left: 28px;
  padding-right: 28px;
}
.site--desktop-header--pargo-one .account-menu__form-link {
  font-size: 14px;
  text-align: center;
}
.site--desktop-header--pargo-one .account-menu__form-link a {
  color: #6c757d;
  transition: color 0.1s;
}
.site--desktop-header--pargo-one .account-menu__form-link a:hover {
  color: inherit;
}
.site--desktop-header--pargo-one .account-menu__form-forgot {
  position: relative;
}
.site--desktop-header--pargo-one .account-menu__form-forgot-link {
  position: absolute;
  top: 5px;
  bottom: 5px;
  border-radius: 2px;
  font-size: 12px;
  background: fff;
  color: #6c757d;
  display: flex;
  align-items: center;
  padding: 0 7px;
  transition: background 0.1s, color 0.1s;
}
[dir=ltr] .site--desktop-header--pargo-one .account-menu__form-forgot-link {
  right: 5px;
}
[dir=rtl] .site--desktop-header--pargo-one .account-menu__form-forgot-link {
  left: 5px;
}
.site--desktop-header--pargo-one .account-menu__form-forgot-link:hover {
  background: #333;
  color: #fff;
}
.site--desktop-header--pargo-one .account-menu__form-forgot-link:active {
  background: #4d4d4d;
  color: #fff;
}
.site--desktop-header--pargo-one .account-menu__divider {
  height: 1px;
  background: #ebebeb;
}
.site--desktop-header--pargo-one .account-menu__user {
  display: flex;
  align-items: center;
  padding: 14px 20px;
  color: inherit;
}
.site--desktop-header--pargo-one .account-menu__user:hover {
  color: inherit;
  background: #f2f2f2;
}
.site--desktop-header--pargo-one .account-menu__user-avatar {
  width: 44px;
  flex-shrink: 0;
}
[dir=ltr] .site--desktop-header--pargo-one .account-menu__user-avatar {
  margin-right: 14px;
}
[dir=rtl] .site--desktop-header--pargo-one .account-menu__user-avatar {
  margin-left: 14px;
}
.site--desktop-header--pargo-one .account-menu__user-avatar img {
  border-radius: 100%;
  max-width: 100%;
}
.site--desktop-header--pargo-one .account-menu__user-info {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.site--desktop-header--pargo-one .account-menu__user-name {
  font-size: 15px;
  line-height: 20px;
}
.site--desktop-header--pargo-one .account-menu__user-email {
  font-size: 14px;
  line-height: 18px;
  color: #6c757d;
  margin-top: 1px;
  max-width: 180px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site--desktop-header--pargo-one .account-menu__links {
  list-style: none;
  padding: 12px 0;
  margin: 0;
}
.site--desktop-header--pargo-one .account-menu__links a,
.site--desktop-header--pargo-one .account-menu__links button {
  display: block;
  color: inherit;
  font-size: 15px;
  font-family: inherit;
  line-height: inherit;
  padding: 5px 20px;
  border: none;
  width: 100%;
  background: transparent;
  font-weight: 500;
}
[dir=ltr] .site--desktop-header--pargo-one .account-menu__links a,
[dir=ltr] .site--desktop-header--pargo-one .account-menu__links button {
  text-align: left;
}
[dir=rtl] .site--desktop-header--pargo-one .account-menu__links a,
[dir=rtl] .site--desktop-header--pargo-one .account-menu__links button {
  text-align: right;
}
.site--desktop-header--pargo-one .account-menu__links a:hover,
.site--desktop-header--pargo-one .account-menu__links button:hover {
  background: #f2f2f2;
}
.site--desktop-header--pargo-one .account-menu__links a:focus,
.site--desktop-header--pargo-one .account-menu__links button:focus {
  outline: none;
}

/*
// .departments
*/
.site--desktop-header--pargo-one .contacts {
  display: block;
  position: relative;
}
.site--desktop-header--pargo-one .contacts__button {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: none;
  font-family: inherit;
  transition: color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  fill: rgba(255, 255, 255, 0.7);
}
.site--desktop-header--pargo-one .contacts__button:focus {
  outline: none;
}
.site--desktop-header--pargo-one .contacts__button:hover {
  color: #F28B00;
  fill: #F28B00;
}
.site--desktop-header--pargo-one .contacts__button-title {
  font-size: 14px;
  font-weight: 500;
  transition: color 0.2s;
}
.site--desktop-header--pargo-one .contacts__button-title--number {
  font-size: 16px;
}
.site--desktop-header--pargo-one .contacts__button-icon svg {
  height: auto;
  width: clamp(0.9375rem, 1.0416666667vw, 1.25rem);
}
.site--desktop-header--pargo-one .contacts__button-arrow {
  transition: transform 0.2s, color 0.2s;
}
.site--desktop-header--pargo-one .contacts__button-arrow svg {
  display: block;
  height: auto;
  width: clamp(0.421875rem, 0.46875vw, 0.5625rem);
}
.site--desktop-header--pargo-one .contacts__menu {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  right: 0;
  transform: translateY(-10px);
  transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
}
.site--desktop-header--pargo-one .contacts__body {
  pointer-events: auto;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  color: #242424;
  padding: 15px 20px;
  min-width: 320px;
}
[dir=ltr] .site--desktop-header--pargo-one .contacts__body {
  float: left;
}
[dir=rtl] .site--desktop-header--pargo-one .contacts__body {
  float: right;
}
.site--desktop-header--pargo-one .contacts__list {
  list-style: none;
  margin: 0;
  padding: 0;
  width: 100%;
}
.site--desktop-header--pargo-one .contacts__link {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
  color: #242424;
}
.site--desktop-header--pargo-one .contacts__link:hover {
  color: #F28B00;
}
.site--desktop-header--pargo-one .contacts__item {
  color: inherit;
  display: block;
  position: relative;
  padding: 5px 0;
}
.site--desktop-header--pargo-one .contacts__item a {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
  color: #242424;
}
.site--desktop-header--pargo-one .contacts__item a:hover {
  color: #F28B00;
}
.site--desktop-header--pargo-one .contacts__link-icon {
  margin-right: 15px;
}
.site--desktop-header--pargo-one .contacts__link-icon svg {
  height: auto;
  width: clamp(0.9375rem, 1.0416666667vw, 1.25rem);
}
.site--desktop-header--pargo-one .contacts--open .contacts__menu {
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.site--desktop-header--pargo-one .contacts--open .contacts__button-arrow {
  transform: rotate(180deg);
}
.site--desktop-header--pargo-one .mobile-contacts__button {
  height: 100%;
  padding-top: 5px;
  padding-bottom: 5px;
}
.site--desktop-header--pargo-one .contacts__arrow {
  width: 41px;
}
.site--desktop-header--pargo-one .contacts__schedule {
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  padding: 10px 10px;
  gap: 10px;
  background-color: #E3E3E3;
}
.site--desktop-header--pargo-one .contacts__schedule span {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
  color: #646464;
}

/*
// .departments
*/
.site--desktop-header--pargo-one .departments {
  display: block;
  position: relative;
}
.site--desktop-header--pargo-one .departments__button {
  position: relative;
  fill: currentColor;
  border: none;
  border-radius: 2px;
  font-family: inherit;
  font-size: 15px;
  padding-top: 5px;
  padding-bottom: 5px;
  transition: background-color 0.2s, color 0.2s;
}
[dir=ltr] .site--desktop-header--pargo-one .departments__button {
  padding-left: 35px;
  padding-right: 11px;
  text-align: left;
}
[dir=rtl] .site--desktop-header--pargo-one .departments__button {
  padding-right: 35px;
  padding-left: 11px;
  text-align: right;
}
.site--desktop-header--pargo-one .departments__button:focus {
  outline: none;
}
.site--desktop-header--pargo-one .departments__button-icon {
  position: absolute;
  fill: currentColor;
  display: inline-block;
  transition: color 0.2s;
}
[dir=ltr] .site--desktop-header--pargo-one .departments__button-icon {
  left: 10px;
  transform: scaleX(1);
}
[dir=rtl] .site--desktop-header--pargo-one .departments__button-icon {
  right: 10px;
  transform: scaleX(-1);
}
.site--desktop-header--pargo-one .departments__button-icon svg {
  display: block;
}
.site--desktop-header--pargo-one .departments__button-arrow {
  display: none;
  fill: currentColor;
  position: absolute;
  top: calc(50% - 4px);
  transition: transform 0.2s, color 0.2s;
}
[dir=ltr] .site--desktop-header--pargo-one .departments__button-arrow {
  right: 14px;
}
[dir=rtl] .site--desktop-header--pargo-one .departments__button-arrow {
  left: 14px;
}
.site--desktop-header--pargo-one .departments__button-arrow svg {
  display: block;
}
.site--desktop-header--pargo-one .departments__menu {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  transform: translateY(-10px);
  transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
}
.site--desktop-header--pargo-one .departments__body {
  border-radius: 1.5px;
  pointer-events: auto;
  display: flex;
  background-color: #242424;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  color: #fff;
}
[dir=ltr] .site--desktop-header--pargo-one .departments__body {
  float: left;
}
[dir=rtl] .site--desktop-header--pargo-one .departments__body {
  float: right;
}
.site--desktop-header--pargo-one .departments__list {
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;
  font-size: 15px;
  line-height: 20px;
  font-weight: 400;
}
.site--desktop-header--pargo-one .departments__item-link {
  color: inherit;
  display: block;
  position: relative;
}
.site--desktop-header--pargo-one .departments__item-arrow {
  fill: #bfbfbf;
  position: absolute;
  top: calc(50% - 6px);
}
.site--desktop-header--pargo-one .departments__item-arrow svg {
  display: block;
}
.site--desktop-header--pargo-one .departments__item-menu {
  display: none;
}
.site--desktop-header--pargo-one .departments__item--hover .departments__item-link {
  color: #F28B00;
}
.site--desktop-header--pargo-one .departments__item--hover .departments__item-arrow {
  fill: #F28B00;
}
.site--desktop-header--pargo-one .departments__megamenu {
  min-height: 100%;
  display: none;
}
[dir=ltr] .site--desktop-header--pargo-one .departments__megamenu {
  box-shadow: 1px 0 #ebebeb inset;
}
[dir=rtl] .site--desktop-header--pargo-one .departments__megamenu {
  box-shadow: -1px 0 #ebebeb inset;
}
.site--desktop-header--pargo-one .departments__megamenu--open {
  display: block;
}
.site--desktop-header--pargo-one .departments__arrow {
  position: absolute;
  width: 31px;
  height: 21px;
  pointer-events: none;
  bottom: 100%;
  overflow: hidden;
}
.site--desktop-header--pargo-one .departments__arrow:before {
  position: absolute;
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  top: 100%;
  margin-top: -3.5px;
  background-color: #242424;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1px;
}
[dir=ltr] .site--desktop-header--pargo-one .departments__arrow:before {
  left: 50%;
  margin-left: -3.5px;
  transform: rotate(45deg) translateX(-0.5px) translateY(-0.5px);
}
[dir=rtl] .site--desktop-header--pargo-one .departments__arrow:before {
  right: 50%;
  margin-right: -3.5px;
  transform: rotate(45deg) translateX(0.5px) translateY(-0.5px);
}
.site--desktop-header--pargo-one .departments__button {
  height: 100%;
  display: flex;
  align-items: center;
  text-transform: uppercase;
}
.site--desktop-header--pargo-one .departments__button-title {
  padding-left: 6px;
}
.site--desktop-header--pargo-one .departments__button-icon svg {
  width: 20px;
}
.site--desktop-header--pargo-one .departments--open .departments__menu {
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.site--desktop-header--pargo-one .departments--open .departments__button-arrow {
  transform: rotate(180deg);
}
@media (min-width: 1400px) {
  .site--desktop-header--pargo-one .departments__list {
    width: 268px;
  }
  .site--desktop-header--pargo-one .departments__list-padding {
    height: 14px;
  }
  .site--desktop-header--pargo-one .departments__item-link {
    padding-top: 7px;
    padding-bottom: 7px;
  }
  [dir=ltr] .site--desktop-header--pargo-one .departments__item-link {
    padding-left: 20px;
    padding-right: 29px;
  }
  [dir=rtl] .site--desktop-header--pargo-one .departments__item-link {
    padding-right: 20px;
    padding-left: 29px;
  }
  [dir=ltr] .site--desktop-header--pargo-one .departments__item-arrow {
    right: 14px;
    transform: scaleX(1);
  }
  [dir=rtl] .site--desktop-header--pargo-one .departments__item-arrow {
    left: 14px;
    transform: scaleX(-1);
  }
  .site--desktop-header--pargo-one .departments__megamenu {
    padding: 27px 30px 30px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--xl {
    width: 1082px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--lg {
    width: 871.6px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--md {
    width: 661.2px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--nl {
    width: 450.8px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--sm {
    width: 240.4px;
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .departments__list {
    width: 210px;
  }
  .site--desktop-header--pargo-one .departments__list-padding {
    height: 10px;
  }
  .site--desktop-header--pargo-one .departments__item-link {
    padding-top: 6px;
    padding-bottom: 6px;
  }
  [dir=ltr] .site--desktop-header--pargo-one .departments__item-link {
    padding-left: 17px;
    padding-right: 26px;
  }
  [dir=rtl] .site--desktop-header--pargo-one .departments__item-link {
    padding-right: 17px;
    padding-left: 26px;
  }
  [dir=ltr] .site--desktop-header--pargo-one .departments__item-arrow {
    right: 11px;
    transform: scaleX(1);
  }
  [dir=rtl] .site--desktop-header--pargo-one .departments__item-arrow {
    left: 11px;
    transform: scaleX(-1);
  }
  .site--desktop-header--pargo-one .departments__megamenu {
    padding: 17px 20px 20px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .row {
    margin: 0 -10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-1 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-2 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-3 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-4 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-5 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-6 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-7 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-8 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-9 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-10 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-11 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-12 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-1of1 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-1of2 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-1of3 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-1of4 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu .col-1of5 {
    padding: 0 10px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--xl {
    width: 910px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--lg {
    width: 732px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--md {
    width: 554px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--nl {
    width: 376px;
  }
  .site--desktop-header--pargo-one .departments__megamenu--size--sm {
    width: 198px;
  }
}
.site--desktop-header--pargo-one .departments__button {
  font-weight: 500;
  background: #F28B00;
  color: #FFF;
  border-radius: 0;
}
.site--desktop-header--pargo-one .departments__button .departments__button-icon {
  color: #FFF;
}
.site--desktop-header--pargo-one .departments__button .departments__button-arrow {
  color: #F28B00;
}
.site--desktop-header--pargo-one .departments__button:hover,
.site--desktop-header--pargo-one .departments--open .departments__button {
  font-weight: 500;
  background: #F28B00;
  color: #FFF;
  background: black;
}
.site--desktop-header--pargo-one .departments__button:hover .departments__button-icon,
.site--desktop-header--pargo-one .departments--open .departments__button .departments__button-icon {
  color: #FFF;
}
.site--desktop-header--pargo-one .departments__button:hover .departments__button-arrow,
.site--desktop-header--pargo-one .departments--open .departments__button .departments__button-arrow {
  color: #F28B00;
}

/*
// .dropcart
*/
.site--desktop-header--pargo-one .dropcart {
  display: block;
  width: 320px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.5px;
  padding: 24px;
  color: #242424;
}
.site--desktop-header--pargo-one .dropcart__empty {
  text-align: center;
  font-size: 15px;
  padding: 20px 0;
}
.site--desktop-header--pargo-one .dropcart__list {
  list-style: none;
  padding: 0;
  margin: -8px 0 0;
  max-height: 300px;
  overflow-y: auto;
}
.site--desktop-header--pargo-one .dropcart__divider {
  height: 1px;
  background: #ebebeb;
  margin: 8px 0;
}
.site--desktop-header--pargo-one .dropcart__item {
  display: flex;
}
.site--desktop-header--pargo-one .dropcart__item-image {
  flex-shrink: 0;
  width: 70px;
}
.site--desktop-header--pargo-one .dropcart__item-image img {
  max-width: 100%;
}
.site--desktop-header--pargo-one .dropcart__item-info {
  padding: 4px 0 5px;
  flex-grow: 1;
  margin: 0 10px;
}
.site--desktop-header--pargo-one .dropcart__item-name {
  font-size: 15px;
  line-height: 18px;
}
.site--desktop-header--pargo-one .dropcart__item-name a {
  color: inherit;
  transition: color 0.2s;
}
.site--desktop-header--pargo-one .dropcart__item-name a:hover {
  color: #F28B00;
}
.site--desktop-header--pargo-one .dropcart__item-features {
  list-style: none;
  padding: 0;
  margin-top: 2px;
  font-size: 13px;
  line-height: 17px;
  color: #6c757d;
}
.site--desktop-header--pargo-one .dropcart__item-meta {
  display: flex;
  align-items: center;
  margin-top: 4px;
}
.site--desktop-header--pargo-one .dropcart__item-remove {
  position: relative;
  flex-shrink: 0;
  width: 26px;
  height: 26px;
  border-radius: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  border: none;
  fill: currentColor;
  background-color: #fff;
  color: #ccc;
}
.site--desktop-header--pargo-one .dropcart__item-remove:hover {
  background-color: #f2f2f2;
  color: #999;
}
.site--desktop-header--pargo-one .dropcart__item-remove:active {
  background-color: #ebebeb;
  color: #808080;
}
.site--desktop-header--pargo-one .dropcart__item-remove:focus {
  outline: none;
}
.site--desktop-header--pargo-one .dropcart__item-remove svg {
  display: block;
}
.site--desktop-header--pargo-one .dropcart__item-remove.dropcart__item-remove--loading {
  fill: transparent;
  cursor: default;
  background: transparent;
}
.site--desktop-header--pargo-one .dropcart__item-remove.dropcart__item-remove--loading:after {
  left: calc(50% - 8px);
  top: calc(50% - 8px);
  width: 16px;
  height: 16px;
  border-radius: 8px;
  border-width: 2px;
  border-color: rgba(0, 0, 0, 0.1);
  border-top-color: rgba(0, 0, 0, 0.5);
  border-style: solid;
  animation-name: loader-animation;
  animation-duration: 0.5s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  position: absolute;
  display: block;
  content: "";
}
.site--desktop-header--pargo-one .dropcart__item-quantity,
.site--desktop-header--pargo-one .dropcart__item-price {
  position: relative;
  height: 16px;
  z-index: 0;
  font-size: 11px;
  padding: 1px 10.82352px 0;
}
.site--desktop-header--pargo-one .dropcart__item-quantity:before,
.site--desktop-header--pargo-one .dropcart__item-price:before {
  position: absolute;
  display: block;
  content: "";
  width: calc(100% - 5.82352px);
  height: 100%;
  top: 0;
  z-index: -1;
  border-radius: 2.5px 3px;
}
[dir=ltr] .site--desktop-header--pargo-one .dropcart__item-quantity:before,
[dir=ltr] .site--desktop-header--pargo-one .dropcart__item-price:before {
  left: 0;
  transform: skewX(-20deg);
  transform-origin: left bottom;
}
[dir=rtl] .site--desktop-header--pargo-one .dropcart__item-quantity:before,
[dir=rtl] .site--desktop-header--pargo-one .dropcart__item-price:before {
  right: 0;
  transform: skewX(20deg);
  transform-origin: right bottom;
}
.site--desktop-header--pargo-one .dropcart__item-quantity {
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--desktop-header--pargo-one .dropcart__item-quantity {
    font-weight: 400;
  }
}
[dir=ltr] .site--desktop-header--pargo-one .dropcart__item-quantity {
  padding-left: 6px;
  margin-right: -2.82352px;
}
[dir=rtl] .site--desktop-header--pargo-one .dropcart__item-quantity {
  padding-right: 6px;
  margin-left: -2.82352px;
}
.site--desktop-header--pargo-one .dropcart__item-quantity:before, .site--desktop-header--pargo-one .dropcart__item-quantity:after {
  background: #F28B00;
}
.site--desktop-header--pargo-one .dropcart__item-quantity:after {
  position: absolute;
  display: block;
  content: "";
  width: 8.32352px;
  height: 100%;
  top: 0;
  z-index: -1;
}
[dir=ltr] .site--desktop-header--pargo-one .dropcart__item-quantity:after {
  left: 0;
  border-top-left-radius: 2.5px;
  border-bottom-left-radius: 2.5px;
}
[dir=rtl] .site--desktop-header--pargo-one .dropcart__item-quantity:after {
  right: 0;
  border-top-right-radius: 2.5px;
  border-bottom-right-radius: 2.5px;
}
.site--desktop-header--pargo-one .dropcart__item-price {
  color: #242424;
  font-weight: 500;
}
.site--desktop-header--pargo-one .dropcart__item-price:before {
  background: #ebebeb;
}
.site--desktop-header--pargo-one .dropcart__totals {
  font-size: 15px;
  margin-top: 18px;
  margin-bottom: 24px;
}
.site--desktop-header--pargo-one .dropcart__totals table {
  width: 100%;
}
.site--desktop-header--pargo-one .dropcart__totals th, .site--desktop-header--pargo-one .dropcart__totals td {
  padding: 0;
}
.site--desktop-header--pargo-one .dropcart__totals th {
  font-weight: 500;
}
[dir=ltr] .site--desktop-header--pargo-one .dropcart__totals td {
  text-align: right;
}
[dir=rtl] .site--desktop-header--pargo-one .dropcart__totals td {
  text-align: left;
}
.site--desktop-header--pargo-one .dropcart__totals tr + tr > * {
  padding-top: 4px;
}
.site--desktop-header--pargo-one .dropcart__actions {
  display: flex;
}
.site--desktop-header--pargo-one .dropcart__actions > * {
  flex-grow: 1;
}
[dir=ltr] .site--desktop-header--pargo-one .dropcart__actions > * + * {
  margin-left: 8px;
}
[dir=rtl] .site--desktop-header--pargo-one .dropcart__actions > * + * {
  margin-right: 8px;
}

/*
// .empty-garage-menu
*/
.site--desktop-header--pargo-one .empty-garage-menu {
  display: block;
  width: 360px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  border-radius: 1.5px;
  color: #242424;
}
.site--desktop-header--pargo-one .empty-garage-menu .default-pargo-button-inverse {
  width: 100%;
}
.site--desktop-header--pargo-one .empty-garage-menu .default-pargo-button-inverse svg {
  height: auto;
}
.site--desktop-header--pargo-one .empty-garage-menu__wrapper {
  padding: 1.5rem 1rem;
}

/*
// .header
*/
.site--desktop-header--pargo-one .header {
  display: grid;
  grid-template-columns: max-content auto max-content;
  grid-template-rows: clamp(2.15625rem, 2.3958333333vw, 2.875rem) auto;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .header {
    grid-template-columns: max-content auto max-content;
  }
}
.site--desktop-header--pargo-one .header__megamenu-area {
  grid-column: 1/4;
  grid-row: 1;
}
.site--desktop-header--pargo-one .header__topbar-pargo-bg {
  grid-column: 2/4;
  grid-row: 1;
}
.site--desktop-header--pargo-one .header__topbar-pargo {
  margin-left: clamp(20px, 1.45vw, 28px);
  grid-column: 2/4;
  grid-row: 1;
}
.site--desktop-header--pargo-one .header__logo {
  grid-column: 1;
  grid-row: 1/span 2;
}
.site--desktop-header--pargo-one .header__search {
  margin-left: clamp(20px, 1.45vw, 28px);
  grid-column: 2;
  grid-row: 2;
  display: flex;
  gap: 1.5rem;
  align-items: stretch;
}
.site--desktop-header--pargo-one .header__search .search {
  flex: 1;
  max-width: 780px;
}
.site--desktop-header--pargo-one .header__indicators {
  grid-column: 3;
  grid-row: 2;
}
.site--desktop-header--pargo-one .header {
  position: relative;
  z-index: 10;
}
@media (max-width: 991.98px) {
  .site--desktop-header--pargo-one .header {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .site--desktop-header--pargo-one .desktop-header {
    display: none;
  }
}
.site--desktop-header--pargo-one .header__logo .logo__image {
  justify-content: center;
  padding-right: clamp(1.25rem, 1.35vw, 1.625rem);
  border-right: 1px solid rgba(227, 227, 227, 0.1);
}
.site--desktop-header--pargo-one .header__logo .logo__image svg {
  height: auto;
  width: clamp(120px, 8.6vw, 160px);
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .header__logo {
    min-width: 210px;
  }
}
.site--desktop-header--pargo-one .header__search {
  align-self: center;
}
[dir=ltr] .site--desktop-header--pargo-one .header__search {
  margin-right: 24px;
}
[dir=rtl] .site--desktop-header--pargo-one .header__search {
  margin-left: 24px;
}
.site--desktop-header--pargo-one .header__indicators {
  align-self: center;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: clamp(0.75rem, 0.8333333333vw, 1rem) 0;
}
[dir=ltr] .site--desktop-header--pargo-one .header__indicators .indicator + .indicator {
  margin-left: 4px;
}
[dir=rtl] .site--desktop-header--pargo-one .header__indicators .indicator + .indicator {
  margin-right: 4px;
}
.site--desktop-header--pargo-one .header {
  color: #fff;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
}
.site--desktop-header--pargo-one .header__topbar-pargo-bg {
  border-bottom: 1px solid rgba(227, 227, 227, 0.1);
}
.site--desktop-header--pargo-one .header-login-button {
  border: 1px solid #F28B00;
  background-color: transparent;
  padding: 6px 18px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #F28B00;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 600;
  transition: color 0.3s ease;
}
.site--desktop-header--pargo-one .header-login-button:hover {
  color: #FFF;
}
.site--desktop-header--pargo-one .header-login-button .header-login-button__secondary {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
@media (max-width: 1279px) {
  .site--desktop-header--pargo-one .departments {
    display: none;
  }
}

/*
// .indicator
*/
.site--desktop-header--pargo-one {
  /*
  // .indicator--trigger--click.indicator--open,
  // .indicator--trigger--hover:hover
  */
}
.site--desktop-header--pargo-one .indicator {
  position: relative;
}
.site--desktop-header--pargo-one .indicator__button {
  display: flex;
  position: relative;
  color: inherit;
  background: transparent;
  border: none;
  align-items: flex-end;
}
[dir=ltr] .site--desktop-header--pargo-one .indicator__button {
  padding-right: 0.8rem;
}
[dir=rtl] .site--desktop-header--pargo-one .indicator__button {
  padding-left: 0.8rem;
}
.site--desktop-header--pargo-one .indicator__button:focus {
  outline: none;
}
.site--desktop-header--pargo-one .indicator__icon {
  width: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  height: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  display: flex;
  justify-content: center;
  align-items: center;
}
.site--desktop-header--pargo-one .indicator__icon svg {
  display: block;
}
[dir=ltr] .site--desktop-header--pargo-one .indicator__icon svg {
  transform: scaleX(1);
}
[dir=rtl] .site--desktop-header--pargo-one .indicator__icon svg {
  transform: scaleX(-1);
}
.site--desktop-header--pargo-one .indicator__icon:not(.with-icon-border) svg {
  width: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  height: auto;
}
.site--desktop-header--pargo-one .indicator__icon.with-icon-border {
  border: 1px solid #E3E3E3;
  border-radius: 100%;
}
.site--desktop-header--pargo-one .indicator__icon.contrast-border {
  transition: background-color 0.3s ease-in-out;
  border-color: #F28B00;
}
.site--desktop-header--pargo-one .indicator__icon.contrast-border:hover {
  background-color: #F28B00;
}
.site--desktop-header--pargo-one .indicator__right {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
[dir=ltr] .site--desktop-header--pargo-one .indicator__right {
  margin-left: 12px;
}
[dir=rtl] .site--desktop-header--pargo-one .indicator__right {
  margin-right: 12px;
}
.site--desktop-header--pargo-one .indicator__counter {
  position: absolute;
  top: 5px;
  font-size: 10px;
  line-height: 1;
  padding: 2px 3px 1px;
  border-radius: 6.5px;
  text-align: center;
  z-index: 0;
}
[dir=ltr] .site--desktop-header--pargo-one .indicator__counter {
  right: 4px;
}
[dir=rtl] .site--desktop-header--pargo-one .indicator__counter {
  left: 4px;
}
.site--desktop-header--pargo-one .indicator__counter:before {
  display: block;
  position: absolute;
  content: "";
  top: 0;
  bottom: 0;
  z-index: -1;
  border-radius: 2.5px;
}
[dir=ltr] .site--desktop-header--pargo-one .indicator__counter:before {
  left: 0;
  right: 0;
  transform: skewX(-11deg);
}
[dir=rtl] .site--desktop-header--pargo-one .indicator__counter:before {
  right: 0;
  left: 0;
  transform: skewX(11deg);
}
.site--desktop-header--pargo-one .indicator__title,
.site--desktop-header--pargo-one .indicator__value {
  display: block;
  white-space: nowrap;
}
.site--desktop-header--pargo-one .indicator__title {
  font-size: clamp(11px, 0.68vw, 13px);
  max-width: 120px;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site--desktop-header--pargo-one .indicator__value {
  font-size: clamp(0.65625rem, 0.7291666667vw, 0.875rem);
  font-weight: 600;
}
.site--desktop-header--pargo-one .indicator__content {
  pointer-events: none;
  top: 100%;
  position: absolute;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.site--desktop-header--pargo-one .indicator__content.right {
  right: 0 !important;
}
.site--desktop-header--pargo-one .indicator__content.left {
  left: 0 !important;
}
.site--desktop-header--pargo-one .indicator--trigger--click.indicator--open .indicator__content,
.site--desktop-header--pargo-one .indicator--trigger--hover:hover .indicator__content {
  pointer-events: auto;
  z-index: 1;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  visibility: visible;
  transform: rotateX(0deg);
}
.site--desktop-header--pargo-one .indicator--mobile .indicator__button {
  padding: 0 0 2px;
  height: 50px;
  width: 44px;
  min-width: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 0;
}
.site--desktop-header--pargo-one .indicator--mobile .indicator__icon {
  padding: 0;
  position: relative;
  height: auto;
  width: auto;
}
.site--desktop-header--pargo-one .indicator--mobile .indicator__counter {
  top: -7px;
}
[dir=ltr] .site--desktop-header--pargo-one .indicator--mobile .indicator__counter {
  right: -7px;
}
[dir=rtl] .site--desktop-header--pargo-one .indicator--mobile .indicator__counter {
  left: -7px;
}
.site--desktop-header--pargo-one .indicator__icon {
  fill: #FFF;
}
.site--desktop-header--pargo-one .indicator__icon svg {
  fill: #FFF;
}
.site--desktop-header--pargo-one .indicator__title {
  color: rgba(255, 255, 255, 0.6);
}
.site--desktop-header--pargo-one .indicator__value {
  color: #F28B00;
}
.site--desktop-header--pargo-one .indicator__counter {
  color: #262626;
  font-weight: 500;
}
.site--desktop-header--pargo-one .indicator__counter:before {
  background-color: #ffdf40;
}

/*
// .language-selector
*/
.language-selector {
  display: flex;
  align-items: center;
  gap: 12px;
  fill: rgba(255, 255, 255, 0.7);
  color: rgba(255, 255, 255, 0.7);
  font-size: clamp(11px, 0.68vw, 13px);
}
.language-selector svg {
  height: auto;
  width: clamp(0.65625rem, 0.7291666667vw, 0.875rem);
}
@media (max-width: 767.98px) {
  .language-selector svg {
    width: 14px;
  }
}

.language-selector__button {
  background: transparent;
  border: none;
  padding: 0;
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.language-selector__button:hover {
  color: #F28B00;
}
@media (max-width: 767.98px) {
  .language-selector__button {
    font-size: 13px;
  }
}

.language-selector__button-active {
  color: #F28B00;
}

/*
// .logo
*/
.site--desktop-header--pargo-one .logo__slogan {
  padding-top: 11px;
  font-size: 11px;
  line-height: 1;
  text-transform: uppercase;
}
.site--desktop-header--pargo-one .logo__image {
  display: flex;
  align-items: center;
  height: 100%;
}
.site--desktop-header--pargo-one .logo__slogan {
  display: none;
}
.site--desktop-header--pargo-one .logo__part-primary {
  fill: rgba(255, 255, 255, 0.9);
}
.site--desktop-header--pargo-one .logo__part-secondary {
  fill: rgba(255, 255, 255, 0.6);
}
.site--desktop-header--pargo-one .ny-logo {
  position: relative;
  /* Анимация левого колокольчика */
  /* Анимация правого колокольчика */
}
.site--desktop-header--pargo-one .ny-logo .ny-logo__img {
  height: 56px;
  width: 165px;
}
.site--desktop-header--pargo-one .ny-logo .ny-logo__hat {
  position: absolute;
  top: -6px;
  left: -9px;
  width: 34px;
  height: auto;
  transform-origin: bottom right; /* Позиция для поворота */
  animation: rotate-hat 3s ease-in-out infinite alternate;
}
@keyframes rotate-hat {
  0% {
    transform: rotate(0deg); /* Исходное положение */
  }
  50% {
    transform: rotate(-12deg); /* Поворот влево */
  }
  100% {
    transform: rotate(0deg); /* Поворот вправо */
  }
}
.site--desktop-header--pargo-one .ny-logo .ny-logo__bell-left {
  position: absolute;
  bottom: -9px;
  left: -16px;
  width: 10px;
  height: auto;
  transform-origin: center top; /* Точка вращения: верхняя центральная часть */
  animation: bell-left-ding 3s infinite ease-in-out;
}
.site--desktop-header--pargo-one .ny-logo .ny-logo__bell-right {
  position: absolute;
  bottom: -9px;
  right: -8px;
  width: 10px;
  height: auto;
  transform: scaleX(-1);
  transform-origin: center top; /* Точка вращения: верхняя центральная часть */
  animation: bell-right-ding 3s infinite ease-in-out;
}
@keyframes bell-left-ding {
  0%, 90% {
    transform: rotate(0deg);
  }
  91% {
    transform: rotate(-20deg); /* Вздрагивание влево */
  }
  93% {
    transform: rotate(20deg); /* Вздрагивание вправо */
  }
  97% {
    transform: rotate(-10deg); /* Легкий откат */
  }
  100% {
    transform: rotate(0deg); /* Возврат в исходное положение */
  }
}
@keyframes bell-right-ding {
  0%, 90% {
    transform: scaleX(-1) rotate(0deg);
  }
  91% {
    transform: scaleX(-1) rotate(20deg); /* Вздрагивание вправо */
  }
  93% {
    transform: scaleX(-1) rotate(-20deg); /* Вздрагивание влево */
  }
  97% {
    transform: scaleX(-1) rotate(10deg); /* Легкий откат */
  }
  100% {
    transform: scaleX(-1) rotate(0deg); /* Возврат в исходное положение */
  }
}
.site--desktop-header--pargo-one .mobile-logo .ny-logo__img {
  height: 34px;
  width: 103px;
}
.site--desktop-header--pargo-one .mobile-logo .ny-logo__hat {
  width: 26px;
}
.site--desktop-header--pargo-one .mobile-logo .ny-logo__bell-left, .site--desktop-header--pargo-one .mobile-logo .ny-logo__bell-right {
  display: none;
}
.site--desktop-header--pargo-one .mobile-logo svg {
  display: block;
  height: 32px;
  width: 103px;
}

/*
// .main-menu
*/
.site--desktop-header--pargo-one .main-menu {
  height: 100%;
}
.site--desktop-header--pargo-one .main-menu__list {
  position: relative;
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  height: 100%;
}
.site--desktop-header--pargo-one .main-menu__link {
  display: block;
  padding: 5px 11px;
  border-radius: 2px;
  color: inherit;
  white-space: nowrap;
  font-size: 15px;
}
.site--desktop-header--pargo-one .main-menu__link:hover {
  color: inherit;
}
.site--desktop-header--pargo-one .main-menu__submenu {
  color: #242424;
}
.site--desktop-header--pargo-one .main-menu__item--has-submenu .main-menu__link {
  position: relative;
}
[dir=ltr] .site--desktop-header--pargo-one .main-menu__item--has-submenu .main-menu__link {
  padding-right: 23px;
}
[dir=rtl] .site--desktop-header--pargo-one .main-menu__item--has-submenu .main-menu__link {
  padding-left: 23px;
}
.site--desktop-header--pargo-one .main-menu__item--has-submenu .main-menu__link svg {
  top: 14px;
  position: absolute;
  fill: currentColor;
}
[dir=ltr] .site--desktop-header--pargo-one .main-menu__item--has-submenu .main-menu__link svg {
  right: 10px;
}
[dir=rtl] .site--desktop-header--pargo-one .main-menu__item--has-submenu .main-menu__link svg {
  left: 10px;
}
.site--desktop-header--pargo-one .main-menu__item--submenu--menu {
  position: relative;
}
.site--desktop-header--pargo-one .main-menu__item--submenu--menu .main-menu__submenu {
  position: absolute;
  z-index: 1;
  top: 100%;
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(45deg);
  transition: transform 0.2s, opacity 0.2s;
}
[dir=ltr] .site--desktop-header--pargo-one .main-menu__item--submenu--menu .main-menu__submenu {
  left: 0;
}
[dir=rtl] .site--desktop-header--pargo-one .main-menu__item--submenu--menu .main-menu__submenu {
  right: 0;
}
.site--desktop-header--pargo-one .main-menu__item--submenu--megamenu .main-menu__submenu {
  position: absolute;
  z-index: 1;
  opacity: 0;
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(45deg);
  transition: transform 0.2s, opacity 0.2s;
}
.site--desktop-header--pargo-one .main-menu__item--has-submenu.main-menu__item--hover .main-menu__submenu {
  visibility: visible;
  opacity: 1;
  transform: rotateX(0);
}
.site--desktop-header--pargo-one .main-menu__megamenu {
  padding: 26px 30px 30px;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
}
@media (min-width: 1400px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--xxl {
    width: calc((1350px - 30px) / 6 * 6 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--xxl {
    width: calc((1110px - 30px) / 6 * 6 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--xl {
    width: calc((1350px - 30px) / 6 * 5 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--xl {
    width: calc((1110px - 30px) / 6 * 5 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--lg {
    width: calc((1350px - 30px) / 6 * 4 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--lg {
    width: calc((1110px - 30px) / 6 * 4 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--md {
    width: calc((1350px - 30px) / 6 * 3 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--md {
    width: calc((1110px - 30px) / 6 * 3 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--nl {
    width: calc((1350px - 30px) / 6 * 2 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--nl {
    width: calc((1110px - 30px) / 6 * 2 + 30px);
  }
}
@media (min-width: 1400px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--sm {
    width: calc((1350px - 30px) / 6 * 1 + 30px);
  }
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .main-menu__megamenu--size--sm {
    width: calc((1110px - 30px) / 6 * 1 + 30px);
  }
}
.site--desktop-header--pargo-one .main-menu__link {
  height: 100%;
  padding-top: 0;
  padding-bottom: 0;
  display: flex;
  align-items: center;
}
.site--desktop-header--pargo-one .main-menu__item--has-submenu .main-menu__link svg {
  top: calc(50% - 2px);
}
.site--desktop-header--pargo-one .main-menu .main-menu__link {
  font-weight: 500;
}
.site--desktop-header--pargo-one .main-menu .main-menu__item--has-submenu .main-menu__link svg {
  color: #bfbfbf;
}
.site--desktop-header--pargo-one .main-menu .main-menu__item:hover .main-menu__link {
  background-color: #f2f2f2;
}
.site--desktop-header--pargo-one .main-menu__link {
  border-radius: 0;
}

/*
// .megamenu
*/
.site--desktop-header--pargo-one .megamenu {
  position: relative;
}
.site--desktop-header--pargo-one .megamenu__image {
  position: absolute;
  bottom: 0;
}
[dir=ltr] .site--desktop-header--pargo-one .megamenu__image {
  right: 0;
  transform-origin: bottom right;
}
[dir=rtl] .site--desktop-header--pargo-one .megamenu__image {
  left: 0;
  transform-origin: bottom left;
}
.site--desktop-header--pargo-one .megamenu__image img,
.site--desktop-header--pargo-one .megamenu__image picture {
  display: block;
  max-width: 200px;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .megamenu__image {
    transform: scale(0.81);
  }
}

/*
// .megamenu-links
*/
.site--desktop-header--pargo-one .megamenu-links {
  list-style: none;
  padding: 0;
  margin: 0;
}
.site--desktop-header--pargo-one .megamenu-links__item {
  line-height: 18px;
}
.site--desktop-header--pargo-one .megamenu-links__item + .megamenu-links__item {
  margin-top: 10px;
}
.site--desktop-header--pargo-one .megamenu-links__item--has-submenu + .megamenu-links__item {
  margin-top: 24px;
}
.site--desktop-header--pargo-one .megamenu-links__item-link {
  font-size: 16px;
  color: inherit;
  transition: color 0.15s;
  font-weight: 400;
}
.site--desktop-header--pargo-one .megamenu-links__item-link:hover {
  color: #F28B00;
}
.site--desktop-header--pargo-one .megamenu-links__item-link + .megamenu-links {
  border-top: 1px solid #ebebeb;
  padding-top: 12px;
  margin-top: 5px;
}
.site--desktop-header--pargo-one .megamenu-links:not(.megamenu-links--root) .megamenu-links__item {
  line-height: 16px;
}
.site--desktop-header--pargo-one .megamenu-links:not(.megamenu-links--root) .megamenu-links__item + .megamenu-links__item {
  margin-top: 9px;
}
.site--desktop-header--pargo-one .megamenu-links:not(.megamenu-links--root) .megamenu-links__item-link {
  font-weight: 400;
  font-size: 14px;
  color: #6c757d;
}
.site--desktop-header--pargo-one .megamenu-links:not(.megamenu-links--root) .megamenu-links__item-link:hover {
  color: #F28B00;
}
@media (min-width: 1200px) and (max-width: 1399.98px) {
  .site--desktop-header--pargo-one .megamenu-links__item-link {
    font-size: 15px;
  }
}

/*
// .phone
*/
/*
// .search
*/
.site--desktop-header--pargo-one .search__dropdown {
  color: #242424;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  position: absolute;
  top: 100%;
  border-radius: 1.5px;
  opacity: 0;
  transform: translateY(26px);
  visibility: hidden;
  transition: opacity 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), transform 0.25s cubic-bezier(0.645, 0.045, 0.355, 1), visibility 0s 0.25s;
}
[dir=ltr] .site--desktop-header--pargo-one .search__dropdown {
  left: 0;
  right: 0;
}
[dir=rtl] .site--desktop-header--pargo-one .search__dropdown {
  right: 0;
  left: 0;
}
.site--desktop-header--pargo-one .search__dropdown--open {
  opacity: 1;
  transform: translateY(0);
  visibility: visible;
  transition-delay: 0s;
}
.site--desktop-header--pargo-one .search__button-icon svg {
  display: block;
}

.search_button-loader.loader {
  width: 20px;
  aspect-ratio: 1;
  --_g: no-repeat radial-gradient(circle closest-side, #F18C16 90%,#0000);
  background: var(--_g) 0 0, var(--_g) 0 100%, var(--_g) 100% 100%;
  background-size: 40% 40%;
  animation: l11 0.8s infinite linear;
}
@keyframes l11 {
  25% {
    background-position: 100% 0, 0 100%, 100% 100%;
  }
  50% {
    background-position: 100% 0, 0 0, 100% 100%;
  }
  75% {
    background-position: 100% 0, 0 0, 0 100%;
  }
  100% {
    background-position: 100% 100%, 0 0, 0 100%;
  }
}

.site--desktop-header--pargo-one .search__body {
  display: flex;
  z-index: 0;
  position: relative;
  height: clamp(2.109375rem, 2.34375vw, 2.8125rem);
}
.site--desktop-header--pargo-one .search__input,
.site--desktop-header--pargo-one .site-search__field,
.site--desktop-header--pargo-one .search__button {
  background: transparent;
  border: none;
  font-family: inherit;
  padding: 0;
  flex-basis: 0;
}
.site--desktop-header--pargo-one .search__input:focus,
.site--desktop-header--pargo-one .site-search__field:focus,
.site--desktop-header--pargo-one .search__button:focus {
  outline: none;
}
.site--desktop-header--pargo-one .site-search__field {
  display: flex;
  position: relative;
  flex-grow: 1;
  padding-top: 0;
  padding-bottom: 0;
}
.site--desktop-header--pargo-one .search__button {
  flex-shrink: 0;
  border: 2px solid transparent;
  fill: currentColor;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 9px;
  white-space: nowrap;
}
.site--desktop-header--pargo-one .search__button-icon {
  flex-shrink: 0;
}
.site--desktop-header--pargo-one .search__button-title {
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 500;
  line-height: 1;
  margin-top: 1px;
}
[dir=ltr] .site--desktop-header--pargo-one .search__button-title {
  margin-left: 9px;
}
[dir=rtl] .site--desktop-header--pargo-one .search__button-title {
  margin-right: 9px;
}
.site--desktop-header--pargo-one .search__input {
  color: #262626;
  background-color: #fff;
  border-color: #fff;
  flex-grow: 1;
  font-size: 15px;
  padding-top: 0;
  padding-bottom: 0;
  border-width: 1px;
  border-style: solid;
  border-radius: unset;
  transition: background-color 0.2s, border-color 0.2s, color 0.2s;
}
.site--desktop-header--pargo-one .search__input::placeholder {
  color: #6c757d;
}
[dir=ltr] .site--desktop-header--pargo-one .search__input {
  padding-left: 13px;
  padding-right: clamp(2.109375rem, 2.34375vw, 2.8125rem);
}
[dir=rtl] .site--desktop-header--pargo-one .search__input {
  padding-right: 13px;
  padding-left: clamp(2.109375rem, 2.34375vw, 2.8125rem);
}
.site--desktop-header--pargo-one .search__input:hover {
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.site--desktop-header--pargo-one .search__input:focus {
  background-color: #fff;
  border-color: #fff;
}
.site--desktop-header--pargo-one .search__button--start {
  border: 1px solid transparent;
  background-repeat: no-repeat;
  background-size: 5px 10px;
  border-radius: unset;
  transition: color 0.15s, background-color 0.15s;
  background-color: #F28B00;
  color: #fff;
  padding: 0 clamp(1.171875rem, 1.3020833333vw, 1.5625rem);
  margin-right: 5px;
  margin-left: 15px;
}
.site--desktop-header--pargo-one .search__button--start .search__button-icon svg {
  height: auto;
  width: clamp(1.546875rem, 1.71875vw, 2.0625rem);
  fill: #fff;
}
.site--desktop-header--pargo-one .search__button--start:hover {
  background-color: #fff;
  color: #262626;
}
.site--desktop-header--pargo-one .search__button--start:hover .search__button-icon svg {
  height: auto;
  width: clamp(1.546875rem, 1.71875vw, 2.0625rem);
  fill: #262626;
}
.site--desktop-header--pargo-one .search__button--start:active {
  transition-duration: 0s;
  background-color: #fff;
}
.site--desktop-header--pargo-one .search__button--end {
  transition: color 0.2s;
  position: absolute;
  height: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  width: clamp(2.109375rem, 2.34375vw, 2.8125rem);
  border: none;
  color: #b2b2b2;
}
[dir=ltr] .site--desktop-header--pargo-one .search__button--end {
  right: 0;
}
[dir=rtl] .site--desktop-header--pargo-one .search__button--end {
  left: 0;
}
.site--desktop-header--pargo-one .search__button--end:hover {
  color: #404040;
}
.site--desktop-header--pargo-one .search__button--hover {
  background-color: #fff;
  color: #262626;
}
.site--desktop-header--pargo-one .search__button--hover .search__button-icon svg {
  height: auto;
  width: clamp(1.546875rem, 1.71875vw, 2.0625rem);
  fill: #262626;
}
.site--desktop-header--pargo-one .search__dropdown--vehicle-picker {
  max-width: clamp(20.625rem, 22.9166666667vw, 27.5rem);
  right: 0 !important;
  left: unset !important;
}
.site--desktop-header--pargo-one .search__dropdown-arrow {
  position: absolute;
  width: 46px;
  height: 21px;
  pointer-events: none;
  bottom: 100%;
  overflow: hidden;
}
.site--desktop-header--pargo-one .search__dropdown-arrow:before {
  position: absolute;
  display: block;
  content: "";
  width: 7px;
  height: 7px;
  top: 100%;
  margin-top: -3.5px;
  background-color: #fff;
  border-radius: 1px;
}
[dir=ltr] .site--desktop-header--pargo-one .search__dropdown-arrow:before {
  left: 50%;
  margin-left: -3.5px;
  transform: rotate(45deg) translateX(-0.5px) translateY(-0.5px);
}
[dir=rtl] .site--desktop-header--pargo-one .search__dropdown-arrow:before {
  right: 50%;
  margin-right: -3.5px;
  transform: rotate(45deg) translateX(0.5px) translateY(-0.5px);
}
.site--desktop-header--pargo-one .search__input {
  color: #262626;
  background-color: #fff;
  border-color: #fff;
}
.site--desktop-header--pargo-one .search__input::placeholder {
  color: #6c757d;
}
.site--desktop-header--pargo-one .search__input:hover {
  background-color: #ebebeb;
  border-color: #ebebeb;
}
.site--desktop-header--pargo-one .search__input:focus {
  background-color: #fff;
  border-color: #fff;
}
.site--desktop-header--pargo-one .search__button--end {
  color: #b2b2b2;
}
.site--desktop-header--pargo-one .search__button--end:hover {
  color: #404040;
}
/*
// .suggestions
*/
.site--desktop-header--pargo-one .suggestions {
  padding: 14px 0;
}
.site--desktop-header--pargo-one .suggestions__group + .suggestions__group {
  margin-top: 10px;
}
.site--desktop-header--pargo-one .suggestions__group-title {
  padding: 3px 20px 3px;
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  color: #999;
}
.site--desktop-header--pargo-one .suggestions__category {
  display: block;
  padding: 6px 20px;
  color: inherit;
  font-size: 15px;
  line-height: 17px;
}
.site--desktop-header--pargo-one .suggestions__category:hover {
  color: inherit;
  background: #f2f2f2;
}
.site--desktop-header--pargo-one .suggestions__product {
  display: flex;
  padding: 6px 20px;
  color: inherit;
}
.site--desktop-header--pargo-one .suggestions__product:hover {
  color: inherit;
  background: #f2f2f2;
}
.site--desktop-header--pargo-one .suggestions__product-image {
  position: relative;
  border-radius: 1px;
  overflow: hidden;
  flex-shrink: 0;
}
[dir=ltr] .site--desktop-header--pargo-one .suggestions__product-image {
  margin-right: 10px;
}
[dir=rtl] .site--desktop-header--pargo-one .suggestions__product-image {
  margin-left: 10px;
}
.site--desktop-header--pargo-one .suggestions__product-image:before {
  display: block;
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  border: 1px solid rgba(0, 0, 0, 0.08);
  border-radius: inherit;
  pointer-events: none;
}
.site--desktop-header--pargo-one .suggestions__product-image img {
  width: 40px;
  height: 40px;
  object-fit: contain;
}
.site--desktop-header--pargo-one .suggestions__product-info {
  flex-grow: 1;
  flex-shrink: 1;
  padding-top: 4px;
  min-width: 0;
}
.site--desktop-header--pargo-one .suggestions__product-name {
  font-size: 15px;
  line-height: 17px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.site--desktop-header--pargo-one .suggestions__product-rating {
  display: flex;
  margin-top: 2px;
  gap: 10px;
}
[dir=ltr] .site--desktop-header--pargo-one .suggestions__product-rating-stars {
  margin-right: 8px;
}
[dir=rtl] .site--desktop-header--pargo-one .suggestions__product-rating-stars {
  margin-left: 8px;
}
.site--desktop-header--pargo-one .suggestions__product-rating-label {
  font-size: 13px;
  line-height: 1;
  color: #6c757d;
}
.site--desktop-header--pargo-one .suggestions__product-price {
  white-space: nowrap;
  flex-shrink: 0;
  font-size: 13px;
  font-weight: 700;
  padding-top: 3px;
}
[dir=ltr] .site--desktop-header--pargo-one .suggestions__product-price {
  margin-left: 14px;
  text-align: right;
}
[dir=rtl] .site--desktop-header--pargo-one .suggestions__product-price {
  margin-right: 14px;
  text-align: left;
}

/*
// .topbar
*/
.site--desktop-header--pargo-one .topbar {
  display: flex;
  height: 100%;
  font-size: 14px;
  line-height: 1;
  align-items: center;
}
.site--desktop-header--pargo-one .topbar__item-spring {
  flex-grow: 1;
}
.site--desktop-header--pargo-one .topbar__item-text {
  display: flex;
  align-items: center;
  margin-right: clamp(22px, 1.56vw, 30px);
}
.site--desktop-header--pargo-one .topbar__link {
  transition: color 0.12s;
}
.site--desktop-header--pargo-one .topbar__link-label {
  transition: inherit;
}
.site--desktop-header--pargo-one .topbar__button {
  display: flex;
  align-items: center;
  position: relative;
  background: transparent;
  font-family: inherit;
  border: none;
  padding: 0 clamp(22px, 1.56vw, 30px);
  transition: background 0.12s;
  height: 100%;
}
.site--desktop-header--pargo-one .topbar__button:focus {
  outline: none;
}
.site--desktop-header--pargo-one .topbar__button-title,
.site--desktop-header--pargo-one .topbar__button-label {
  transition: color 0.12s;
}
[dir=ltr] .site--desktop-header--pargo-one .topbar__button-label {
  margin-right: 3px;
}
[dir=rtl] .site--desktop-header--pargo-one .topbar__button-label {
  margin-left: 3px;
}
[dir=ltr] .site--desktop-header--pargo-one .topbar__button--has-arrow {
  padding-right: 18px;
  padding-right: 22px;
}
[dir=rtl] .site--desktop-header--pargo-one .topbar__button--has-arrow {
  padding-left: 18px;
  padding-left: 22px;
}
.site--desktop-header--pargo-one .topbar__button-arrow {
  position: absolute;
  fill: currentColor;
  top: calc(50% - 3px);
}
[dir=ltr] .site--desktop-header--pargo-one .topbar__button-arrow {
  right: 8px;
  right: 10px;
}
[dir=rtl] .site--desktop-header--pargo-one .topbar__button-arrow {
  left: 8px;
  left: 10px;
}
.site--desktop-header--pargo-one .topbar__button-arrow svg {
  display: block;
}
.site--desktop-header--pargo-one .topbar__menu {
  position: relative;
}
.site--desktop-header--pargo-one .topbar__menu-body {
  visibility: hidden;
  transform-origin: top;
  transform: rotateX(60deg);
  opacity: 0;
  transition: transform 0.2s, opacity 0.2s, visibility 0s 0.2s;
}
.site--desktop-header--pargo-one .topbar__menu-body {
  color: #242424;
  background-color: #fff;
  box-shadow: 0 1px 15px rgba(0, 0, 0, 0.1), 0 1px 3px rgba(0, 0, 0, 0.1);
  position: absolute;
  width: 150px;
  padding: 0.5rem 0;
  font-size: 14px;
  line-height: 26px;
  z-index: 1;
  font-weight: 500;
}
[dir=ltr] .site--desktop-header--pargo-one .topbar__menu-body {
  right: 0;
}
[dir=rtl] .site--desktop-header--pargo-one .topbar__menu-body {
  left: 0;
}
.site--desktop-header--pargo-one .topbar__menu-item {
  display: block;
  padding: 3px 1rem;
  background: transparent;
  color: #242424;
  font-family: inherit;
  font-weight: inherit;
  border: none;
  width: 100%;
}
[dir=ltr] .site--desktop-header--pargo-one .topbar__menu-item {
  text-align: left;
}
[dir=rtl] .site--desktop-header--pargo-one .topbar__menu-item {
  text-align: right;
}
.site--desktop-header--pargo-one .topbar__menu-item:focus {
  outline: none;
}
.site--desktop-header--pargo-one .topbar__menu-item:hover {
  color: #242424;
  background: #f2f2f2;
}
[dir=ltr] .site--desktop-header--pargo-one .topbar__menu-item img {
  margin-right: 8px;
}
[dir=rtl] .site--desktop-header--pargo-one .topbar__menu-item img {
  margin-left: 8px;
}
.site--desktop-header--pargo-one .topbar__menu-item span {
  position: relative;
  top: 1px;
}
.site--desktop-header--pargo-one .topbar__menu--open .topbar__menu-body {
  visibility: visible;
  transition-delay: 0s, 0s, 0s;
  opacity: 1;
  transform: rotateX(0deg);
}
.site--desktop-header--pargo-one .topbar--spaceship-end {
  justify-content: flex-end;
}
.site--desktop-header--pargo-one .topbar--pargo .topbar__item-text {
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--desktop-header--pargo-one .topbar--pargo .topbar__item-text {
    font-weight: 400;
  }
}
.site--desktop-header--pargo-one .topbar--pargo .topbar__link {
  font-size: clamp(11px, 0.68vw, 13px);
  font-weight: 500;
  color: rgba(255, 255, 255, 0.7);
}
.site--desktop-header--pargo-one .topbar--pargo .topbar__link:hover {
  color: #F28B00;
}
.site--desktop-header--pargo-one .topbar--pargo .topbar__separator {
  width: clamp(0.125rem, 0.2vw, 0.25rem);
  height: clamp(0.125rem, 0.2vw, 0.25rem);
  background-color: #F28B00;
}
.site--desktop-header--pargo-one .topbar--pargo .topbar__button-label {
  color: #F28B00;
  font-weight: 400;
}
.site--desktop-header--pargo-one .topbar--pargo .topbar__button-title {
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--desktop-header--pargo-one .topbar--pargo .topbar__button-title {
    font-weight: 400;
  }
}
.site--desktop-header--pargo-one .topbar--pargo .topbar__button-arrow {
  color: rgba(0, 0, 0, 0.25);
}
.site--desktop-header--pargo-one .topbar--pargo .topbar__menu--open .topbar__menu-button,
.site--desktop-header--pargo-one .topbar--pargo .topbar__button:hover {
  background: rgba(0, 0, 0, 0.1);
}
.site--desktop-header--pargo-one .topbar--pargo .topbar__menu--open .topbar__menu-button .topbar__button-label,
.site--desktop-header--pargo-one .topbar--pargo .topbar__button:hover .topbar__button-label {
  color: #fff;
}

/*
// .vehicle-picker
*/
.site--desktop-header--pargo-one .vehicle-picker__panel {
  display: none;
}
.site--desktop-header--pargo-one .vehicle-picker__panel--active {
  display: block;
}
.site--desktop-header--pargo-one .vehicle-picker__panel-body {
  padding: 20px 20px 28px;
}
.site--desktop-header--pargo-one .vehicle-picker__text {
  font-size: clamp(14px, 0.83vw, 16px);
  line-height: 18px;
  color: #333;
  margin-bottom: 20px;
  background: #f0f0f0;
  padding: 8px;
}
.site--desktop-header--pargo-one .vehicle-picker__text:first-child {
  margin-top: -3px;
}
.site--desktop-header--pargo-one .vehicle-picker__actions {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: 32px;
}
[dir=ltr] .site--desktop-header--pargo-one .vehicle-picker__actions > * + * {
  margin-left: 20px;
}
[dir=rtl] .site--desktop-header--pargo-one .vehicle-picker__actions > * + * {
  margin-right: 20px;
}
.site--desktop-header--pargo-one .search__car-selector-link a {
  font-size: 14px;
  color: #999;
}
.site--desktop-header--pargo-one .search__car-selector-link a:hover {
  text-decoration: underline;
}

/* ----------------------------------------
// Pages
// ---------------------------------------- */
/*
// .about
*/
/* ----------------------------------------
// Blog
// ---------------------------------------- */
/*
// .post-header
*/