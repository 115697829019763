@import "../variables";

.product-label {
    display: flex;
    gap: 5px;
    justify-content: flex-start;
    overflow: hidden;
    min-height: 22px;
    color: map-get($theme-scheme, contrast-text);
}

.label-wrapper {}

.sale-label {
    background-color: #ff9800;
}

.novelty-label {
    background-color: #4caf50;
}

.markdown-label {
    background-color: #f44336;
}

.label-info {
    display: inline-block;
    cursor: default;
    width: 100%;
    text-align: center;
    border-radius: 2px;
    padding: 5px;
    font-size: 8px;
    font-weight: 600;
    text-transform: uppercase;
}

.page-product {
    position: absolute;
    top: 40px;
    right: 40px;
    flex-direction: row;
    justify-content: flex-start;
    opacity: 1;
    padding-bottom: 1rem;
    flex-wrap: wrap;
    z-index: 3;
}
