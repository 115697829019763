@import "../variables";
@import "../mixins/breakpoints";

$font-color: map-get($theme-scheme, fill);

.block-information__content {
    width: 100%;
    display: flex;
    max-width: 1920px;
    margin: auto;

    img {
        //height: 100%;
        //width: auto;
        //max-width: 798px;
        overflow: hidden;
        //object-fit: cover;
        align-self: stretch;
        flex: 0;
        object-fit: cover;
        flex-grow: 1.5;
    }
}

.block-information__container {
    flex: 1;
    display: flex;
    padding: 80px 10px;
    justify-content: center;
    flex-grow: 3;

    @include media-breakpoint-down(md) {
        padding: 10px;
    }
}

.block-information__text {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    color: $font-color;
    gap: 20px;
    max-width: 730px;
}

.block-information__text-header {
    font-size: clamp(18px, 2vw, 40px);
    font-weight: 700;
}

.block-information__text-par {
    font-size: $default-font-size;
    font-weight: 400;
}

@include media-breakpoint-down(md) {
    .block-information__content {
        flex-direction: column;

        img {
            max-height: 100vw;
        }
    }
}




