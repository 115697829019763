$theme-color: #F28B00;
$fill-color: #242424;
$border-color: #E3E3E3;
$border-color-contrast: #a9a9a9;
$contrast-text-color: #FFF;
$block-bg-color: #F2F2F2;
$secondary-text-color: #646464;
$image-bg: #EBEDEC;
$selected: #F7F9FE;
$background: #FFF;
$picker-selected: rgba($border-color, 0.5);
$picker-hover: $picker-selected;

$theme-scheme: () !default;
$theme-scheme: map_merge((
    // colors
    main: $theme-color,
    fill: $fill-color,
    contrast-text: $contrast-text-color,
    secondary-text: $secondary-text-color,
    muted: #F28B00,
    block-bg: $block-bg-color,
    image-bg: $image-bg,
    border: $border-color,
    border-contrast: $border-color-contrast,
    selected: $selected,
    picker-selected: $picker-selected,
    picker-hover: $picker-hover,
    // states
    hover: rgba(#000, .1),
    background: $background,
), $theme-scheme);

$header-scheme: (
    main: $fill-color,
    divider: rgba($border-color, 0.1),
    border: $theme-color,
    button-hover: black,
) !default;

// logo variants
$logo-scheme-variants: (
    pargo-one: (
        primary: rgba(#fff, .9),
        secondary: rgba(#fff, .6),
    ),
    mobile-one: (
        primary: rgba(#fff, .9),
        secondary: rgba(#fff, .6),
    ),
) !default;


