@import "../variables";
@import '../mixins/breakpoints';

@mixin compact-start-panel {
    flex-direction: column-reverse;
    padding: 1rem;
    gap: 0.4rem;

    .brand-button {
        font-weight: normal;
        font-size: $default-font-size;
        border-radius: 4px;
        width: 100%;
        justify-content: center;
        gap: 2rem;

        svg {
            height: 12px;
            width: 22px;
        }
    }

    .search-wrapper {
        flex-direction: column;
        gap: 0.4rem;
        padding: 0;
        width: 100%;
    }
}

.vehicle-picker__wrapper {
    width: 100%;

    &.hide {
        visibility: hidden;
        height: 0;
    }

    &.without-borders {
        .vehicle-picker__start-panel,
        .vehicle-picker__tabs-wrapper {
            border: none;
            box-shadow: none;
        }
    }

    .no-section {
        .section-header__title {
            font-weight: $font-weight-medium;
        }
    }
}

.vehicle-picker__start-panel {
    display: flex;
    align-items: center;
    border: 1px solid map-get($theme-scheme, border);
    border-radius: 5px;
    box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.15);

    &.hide {
        display: none;
    }

    .brand-button {
        flex: 1;
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 15px 20px;
        font-weight: 700;
        color: map-get($theme-scheme, contrast-text);
        border-radius: 5px 0 0 5px;
        background-color: map-get($theme-scheme, main);
        font-size: 22px;
    }

    .search-wrapper {
        flex: 3;
        display: flex;
        gap: 35px;
        padding: 0 35px 0 80px;

        .form-group {
            margin: 0;
        }
    }

    .search__car-plate {
        flex: 2;
    }

    .search__car-vin {
        flex: 3;
    }

    &.compact {
        @include compact-start-panel;
    }

    @include media-breakpoint-down(md) {
        @include compact-start-panel;
    }
}

@mixin vehicle-picker__compact {
    padding: 1rem;
    border-radius: 10px;

    .tabs-header {
        display: flex;
        flex-direction: column;
        gap: 0.4rem;
    }

    .tab-item {
        display: none;
        font-weight: normal;
        border-radius: 6px;
        min-height: 54px;
        padding: 12px 20px;

        &.selected {
            $textColor: rgba(map-get($theme-scheme, secondary-text), 0.7);
            display: flex;
            background-color: map-get($theme-scheme, picker-selected);
            color: $textColor;
            svg {
                fill: $textColor;
                flex-shrink: 0;
            }

            .select-contain {
                flex-direction: row-reverse;
                justify-content: space-between;
                width: 100%;
                text-align: left;
            }
        }

        &.active {
            display: flex;
            background-color: map-get($theme-scheme, main) !important;

            .select-contain {
                flex-direction: row !important;
                justify-content: flex-start !important;
            }
        }

        &.disabled {
            display: none;
        }
    }

    .vehicle-picker__content {
        margin: 0 10px;
    }

    .vehicle-picker__search-wrapper {
        margin: 1rem 0 1rem 0;
    }
}

.vehicle-picker__content {
    padding: 0;
}

.vehicle-picker__search-wrapper {
    position: relative;
    margin: 0.5rem 1.5rem 0.5rem 1.5rem;

    .form-control {
        width: 100%;
        font-size: 14px;
        height: unset;
        padding: 0.8rem 3rem 0.8rem 0.8rem;
        border-radius: 4px;
    }

    svg {
        position: absolute;
        right: 18px;
        top: 0;
        bottom: 0;
        margin: auto;
    }
}

.vehicle-picker__tabs-wrapper {
    border: 1px solid map-get($theme-scheme, border);
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

    .tabs-header {
        display: flex;
    }

    .tab-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 15px 20px;
        cursor: pointer;
        flex: 1;
        font-weight: 700;
        color: rgba(map-get($theme-scheme, secondary-text), 0.6);
        text-align: center;
        transition: background-color 0.3s;

        &:hover {
            background-color: #f5f5f5;
        }

        &.active {
            background-color: map-get($theme-scheme, main);
            color: map-get($theme-scheme, contrast-text) !important;
            svg {
                fill: map-get($theme-scheme, contrast-text) !important;
            }
        }

        &.selected {
            background-color: map-get($theme-scheme, main);
            color: rgba(map-get($theme-scheme, contrast-text), 0.5);
            svg {
                fill: rgba(map-get($theme-scheme, contrast-text), 0.5);
            }
        }

        &.active .tab-open-icon {
            display: block;
        }

        &.disabled {
            cursor: default;
            pointer-events: none;
        }

        .select-contain {
            display: flex;
            align-items: center;
            gap: 1rem;
        }
    }

    .tab-open-icon {
        display: none;
        margin-left: 1rem;
    }

    .tabs-dropdown {
        overflow: hidden;
    }

    .dropdown-content {}

    .dropdown-close {
        text-align: center;
        padding: 10px;
        background-color: #f0f0f0;
        cursor: pointer;

        &:hover {
            background-color: #e0e0e0;
        }
    }

    &.compact {
        @include vehicle-picker__compact;
    }

    @include media-breakpoint-down(md) {
        @include vehicle-picker__compact;
    }
}
