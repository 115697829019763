@import "../variables";
@import "../adapt";
@import "../mixins/breakpoints";

.product-page__body {
    display: flex;
    padding: 2rem 0;
    margin-bottom: 30px;

    @include media-breakpoint-down(lg) {
        padding: 1rem 0;
    }
    //@include media-breakpoint-down(sm) {
    //}
}

.product-page--hide {
    display: none;
}

.product-page__section,
.product-page__section-product {
    h1 {
        font-weight: $font-weight-bold;
        text-transform: uppercase;
        font-size: adapt-size-px(30);
    }
    .product-page__section-head {
        font-weight: $font-weight-semi-bold;
        font-size: 1.6rem;
        margin-bottom: 1rem;
    }
}

.product-page__section-product {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .product-buy-block {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        justify-content: space-between;
        gap: 20px;
        padding: 20px;

        .input-number {
            margin-left: auto;
        }

        @include media-breakpoint-down(lg) {
            grid-template-columns: 1fr 1fr;
            padding: 0;

            .buy-button {
                grid-column: 1 / span 2;
                width: auto;
                max-width: 100%;
            }
        }
    }
    .product-buy-block__price {
        font-size: 1.6rem;
        font-weight: $font-weight-bold;
        white-space: nowrap;

        .product-card__prices {
            position: absolute;
            bottom: 14px;
            flex-grow: 1;
            font-size: 16px;
            display: flex;
            align-items: flex-end;
            flex-wrap: wrap;
        }
        .product-card__price--old {
            font-size: 14px;
            font-weight: $font-weight-normal;
            text-decoration: line-through;
            color: $price-old-color;
        }
    }
    .input-number {
        flex: 1;
        height: 50px;
        max-width: 180px;
        min-width: 100px;
    }
    .input-number__input {
        height: 100%;
    }
    .buy-button {
        flex: 1;
        //height: 50px;
        width: 100%;
    }
}

.product-page__section {
    table {
        width: 100%;
        border-collapse: collapse;
        border-spacing: 0;
        border: 1px solid #E3E3E3;

        tr:nth-child(even) {
            background-color: rgba(3, 66, 229, 0.03);
        }

        td {
            color: map-get($theme-scheme, secondary-text);
            font-size: $adaptive-13-font-size;
            padding-top: 10px;
            padding-bottom: 10px;
            border-bottom: 1px solid #E3E3E3;
        }

        td:first-child {
            padding-left: 10px;
        }

        td:last-child {
            font-weight: $font-weight-semi-bold;
        }
    }

    .specification-table {

        td:first-child {
            text-align: left;
            width: 20%;
        }

        td:last-child {
            text-align: left;
            width: 30%;
        }
    }

    .product-page__gallery {
        position: relative;
        max-width: 580px;
    }

    .product-page__brand-description {
        h2 {
            font-size: adapt-size-px(25);
            font-weight: $font-weight-bold;
        }
        .description {
            font-size: $adaptive-13-font-size;
            color: map-get($theme-scheme, secondary-text);
            text-align: justify;
        }
        .product-page__brand-description-header {
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            gap: 20px;
            img {
                max-width: 100px;
            }
        }
    }

    .description {
        display: flex;
        flex-direction: column;
        gap: 10px;

        .description-item {
            display: flex;
            gap: 10px;
            align-items: baseline;

            &.inside-box {
                align-items: center;
            }

            &:nth-child(3) {
                align-items: baseline;
            }

            .description-item__name {
                font-size: $default-font-size;
                opacity: 0.7;

                &.color-base {
                    color: #242424;
                }
            }
            .description-item__value {
                font-size: $default-font-size;
                font-weight: $font-weight-bold;
                color: map-get($theme-scheme, secondary-text);
                transition: 0.25s;

                &:hover.value-contrast, &:focus.value-contrast {
                    color: map-get($theme-scheme, main);
                }
            }
            .description-item__spacer {
                flex: 1;
                border-bottom: 1px dotted map-get($theme-scheme, border);
            }

            svg {
                width: 19px;
                height: 19px;
            }
        }
    }

    .product-sidebar {

        .block-products-carousel {
            padding: 0;
        }
    }

    .h4 {
        font-size: 1.2rem;

        @include media-breakpoint-down(sm) {
            font-size: 1rem;
        }
    }
}

.stock-available__map-container {
    border-top: 1px solid map-get($theme-scheme, main);

    @include  media-breakpoint-up(md) {
        display: grid;
        grid-template-columns: 1fr 200px;
    }

    @include  media-breakpoint-up(lg) {
        grid-template-columns: 1fr 300px;
    }
}

.cross-table__link {
    color: inherit;

    &:hover {
        color: #f28b00;
        transition: 0.25s;
    }
}
