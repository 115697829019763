/*
// .widget
*/
@import '../variables';


.widget {
    & + & {
        margin-top: 20px;
    }
}

.widget-wrapper {
    border: 1px solid map-get($theme-scheme, border);
    box-shadow: 0 0 4px 0 rgba(0, 0, 0, 0.15);
}

.widget__header {
    padding: ($widget-padding - .5rem) $widget-padding;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 0.5rem;
    font-size: 16px;
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;
    margin-bottom: 0;
    border-bottom: 1px solid map-get($theme-scheme, border);

    &.close-body {
        background-color: map-get($theme-scheme, main);
        color: map-get($theme-scheme, contrast-text);
        svg {
            fill: map-get($theme-scheme, contrast-text);
        }
        border: none;
    }

    svg {
        flex-shrink: 0;
    }
}

.widget__body {
    overflow: hidden;
}
