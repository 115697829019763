/*
// .filter-range
*/
@import '../variables';


.filter-range-input {}

.filter-range-input__form {
  display: flex;
  gap: 8px;
  align-items: center;
}

.filter-range-input__input {
  width: 100%;
  height: 45px;
  padding: 6px 12px;
    border: 1px solid rgb(232, 232, 233);
    transition: border 0.3s ease-in-out;

    &:focus-visible {
        outline: none;
        border-color: map-get($theme-scheme, main);
    }
}

.filter-range-input__description {
    display: flex;
    justify-content: space-between;
    margin-top: 5px;

    .filter-range-input__description-count {
        color: #6c757d;
        font-size: 12px;
    }
}
