/*
// .account-nav
*/
@import '../variables';
@import '../mixins/card';
@import '../mixins/breakpoints';
@import '../mixins/direction';

$secondary-text-color: map-get($theme-scheme, secondary-text);

.account-nav {
    @include card;
}
.account-nav__title {
    padding: 1.375rem 1.5rem;
    margin-bottom: 0;
    background-color: map-get($theme-scheme, fill);

    .account-nav__title-name {
        font-weight: $font-weight-bold;
        color: map-get($theme-scheme, contrast-text);
    }
    .account-nav__title-email {
        font-size: $adaptive-13-font-size;
        color: $secondary-text-color;
    }
}
.account-nav__list {
    list-style: none;
    padding: 0;
    margin: 0;
    font-size: 15px;
    line-height: 20px;
}
.account-nav__item a {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: 10px;
    color: $secondary-text-color;
    padding: 14px 1.5rem;
    font-weight: $font-weight-semi-bold;
    svg {
        fill: $secondary-text-color;
    }
}
.account-nav__item:hover a {
    background: map_get($card-scheme, hover);
}
.account-nav__item--active a {
    color: inherit;
    svg {
        fill: map-get($theme-scheme, main);
    }
    background-color: rgba(map-get($theme-scheme, main), 0.07);
}
.account-nav__divider {
    height: 1px;
    background: map_get($card-scheme, divider);
    margin: 10px 0;
}


@include media-breakpoint-up(lg) {
    .account-nav__list {
        padding-bottom: 1.375rem;
    }
    .account-nav__item--active a {
        @include direction {
            box-shadow: #{3px * $transform-direction} 0 $account-nav-border-color inset;
        }
    }
}
@include media-breakpoint-down(md) {
    .account-nav {
        overflow-x: auto;
        max-width: 100%;
    }
    .account-nav__list {
        display: flex;
        white-space: nowrap;
        padding: 0;
    }
    .account-nav__title {
        display: none;
    }
    .account-nav__item a {
        padding: 14px 20px;
    }
    .account-nav__item--active a {
        box-shadow: 0 -3px $account-nav-border-color inset;
        height: 100%;
    }
}
