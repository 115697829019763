/*
// .block-popular-brands
*/
@import '../variables';
@import '../mixins/breakpoints';
@import "../adapt";


@mixin local-layout($columns) {
    .block-popular-brands__item {
        width: calc((100% - #{$columns * 1px}) / #{$columns});
    }
    .block-popular-brands__divider {
        width: 1px;
    }
    .block-popular-brands__divider:nth-child(#{$columns * 2}n) {
        height: 1px;
        width: 100%;

        &:last-child {
            display: none;
        }
    }
}


$local-transition-duration: .15s;


.block-popular-brands__body {
    padding-top: 40px;
    padding-bottom: 40px;
    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }
}
.block-popular-brands__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    //border: 1px solid $block-popular-brands-divider;
}
.block-popular-brands__item {
    text-align: center;
    flex-shrink: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 120px;
}
.block-popular-brands__item-link {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: adapt-size-px(8);
    width: 100%;
    height: 100%;
    border: 1px solid $block-popular-brands-divider;
    background-color: #fff;

    img {
        max-width: 100%;
        height: adapt-size-px(150);
        object-fit: contain;
        //filter: grayscale(1);
        //opacity: .7;
        transition:
            filter $local-transition-duration,
            opacity $local-transition-duration;
    }
}
.block-popular-brands__item-link:hover {
    border: none;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;

    &:before {
        opacity: 1;
    }
    img {
        //filter: grayscale(0);
        opacity: 1;
    }
}

.block-popular-brands--layout--columns-7-full {
    @include media-breakpoint-up(xxl) {
        @include local-layout(7);
    }
    @include media-breakpoint-only(xl) {
        @include local-layout(6);
    }
    @include media-breakpoint-only(lg) {
        @include local-layout(5);
    }
}


// for all layouts
@include media-breakpoint-only(md) {
    @include local-layout(3);
}
@include media-breakpoint-only(sm) {
    @include local-layout(3);
}
@media (min-width: 420px) and (max-width: 575px) {
    @include local-layout(2);
}
@media (max-width: 419px) {
    @include local-layout(2);
}
