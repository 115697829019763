@import '../variables';
@import '../mixins/breakpoints';

@mixin vehicle-table__compact {
    height: 354px;
    overflow-y: auto;

    .table-header, .table-body {
        display: none;
    }

    .selectable-list-item {
        display: flex;
        flex-direction: column;
        height: min-content;

        &:after {
            top: unset;
            bottom: unset;
        }
    }
}

.vehicle-table_wrapper {
    width: 100%;
    overflow: hidden;

    .selectable-list-item {
        display: none;
    }

    .table-header {
        background-color: map-get($theme-scheme, border);
    }

    .table-header-row {
        display: table;
        width: 100%;
        table-layout: fixed;
    }

    .table-header-cell {
        display: table-cell;
        padding: 12px 15px;
        font-weight: bold;
        text-align: left;
        text-transform: uppercase;
    }

    .table-body {
        display: block;
        height: 307px;
        overflow-y: auto;
    }

    .table-row {
        display: table;
        width: 100%;
        table-layout: fixed;
        cursor: pointer;
    }

    .table-row:hover {
        background-color: map-get($theme-scheme, picker-hover);
    }

    .table-cell {
        display: table-cell;
        padding: 10px 15px;
        border-bottom: 1px solid map-get($theme-scheme, border);
        text-align: left;
    }

    .col-name {
        width: 25%;
    }

    .col-engine {
        width: 20%;
    }

    @include media-breakpoint-down(md) {
        @include vehicle-table__compact;
    }
}

.compact .vehicle-table_wrapper {
    @include vehicle-table__compact;
}
