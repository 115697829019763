@import "../variables";
@import '../mixins/direction';
@import "../mixins/form-control";

.sign-form {
  display: flex;
  color: map_get($light-scheme, header);
}

.sign-form-logo {
  display: flex;
  align-items: center;
  background-color: #EBEDEC;

  img {
    width: 369px;
    height: 298px;
  }
}

.sign-form-content {
  display: flex;
  flex-direction: column;
  flex: auto;
  width: 480px;
}

.sign-form-content__list {
  display: flex;
  padding: 0;
  margin: 0 0 -1px;
  list-style: none;
  overflow-x: auto;
}

.sign-form-content__title {
  text-align: center;
  font-weight: $font-weight-bold;
}

.sign-form-content__subheader {
  font-size: 16px;
  text-align: center;
  margin-bottom: 1rem;
}

.sign-form-content__body {
  display: flex;
  flex-direction: column;
  border-top: 1px solid map_get($card-scheme, divider);
  padding-top: 20px;

  .form-group {
    margin-bottom: 0.6rem;
  }
}

.sign-form-content__item {
  position: relative;
  flex: auto;

  span {
    cursor: pointer;
    font-size: 14px;
    font-weight: $font-weight-semi-bold;
    color: map_get($light-scheme, primary-header);
    display: flex;
    justify-content: center;
    padding: 8px 18px;
    transition:
            background .12s,
            box-shadow .12s;

    &:hover {
      background: $product-tabs-hover-bg-color;
      box-shadow: 0 -1px $product-tabs-hover-border-color inset;
    }
    &:active {
      background: $product-tabs-active-bg-color;
      box-shadow: 0 -1px $product-tabs-active-border-color inset;
    }
  }
}

.sign-form-content__item--active {
  span {
    &,
    &:hover,
    &:active {
      background: transparent;
      color: $product-tabs-current-border-color;
      box-shadow: 0 -1px $product-tabs-current-border-color inset;
    }
  }
}

.sign-form {
  label {
    font-size: inherit;
  }
  .sign-form-label {
    font-size: $adaptive-13-font-size;
    font-weight: $font-weight-normal;
  }
}

.sign-form-row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.sign-form__password-container {
  position: relative;
  display: flex;
  align-items: center;

  .form-control {
    padding-right: 40px;
  }
}

.sign-form__toggle-password {
  position: absolute;
  right: 12px;
  border: none;
  background: none;
  cursor: pointer;
  color: #a7a7a7;
}
.reset-form__toggle-password {
@extend .sign-form__toggle-password;
    transform: translateY(50%);
}
.sign-form__submit-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 13px !important;
  font-size: $default-font-size !important;
  font-weight: $font-weight-semi-bold;
  border-radius: unset;
}

.sign-form__field-button {
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: $default-font-size !important;
  font-weight: $font-weight-normal;
  border-radius: unset;
  @include form-control--size(nl);
}

.sign-form-link {
  a {
    color: map_get($theme-scheme, main);
    text-decoration: underline;
  }
}

.sign-form__divider {
  width: 100%;
  line-height: 1;
  font-size: $adaptive-13-font-size;
  font-weight: $font-weight-semi-bold;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  color: map_get($card-scheme, muted);
  margin: 1rem 0;

  &:before,
  &:after {
    display: block;
    content: '';
    height: 1px;
    flex-grow: 1;
    background: map_get($card-scheme, divider);
  }
  &:before {
    @include direction {
      #{$margin-inline-end}: 10px;
    }
  }
  &:after {
    @include direction {
      #{$margin-inline-start}: 10px;
    }
  }
}

.sign-form__service-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
  margin: 1rem auto;
    max-height: 40px;

  a {
    border: 1px solid map_get($theme-scheme, border);
    border-radius: 100%;
    background: transparent;
    width: 40px;
    height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
