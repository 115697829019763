/*
// .block-list-categories
*/
@use "sass:math";

@import "../variables";
@import "../functions";
@import "../mixins/breakpoints";
@import "../mixins/grid";
@import "../mixins/direction";
@import "../mixins/scheme";
@import "../mixins/header";

//$sub-min-width: 250px;

@mixin list-categories-open() {
    background-color: map_get($list-categories-menu-scheme, main);
    box-shadow: map_get($list-categories-menu-scheme, shadow);
    height: max-content;

    .list-categories__list {
        visibility: visible;
        opacity: 1;
        max-height: max-content;
        //transform: translateY(0); -- она делает стрелку от категории к подкатегории еле заметной, на неё накладывается тень от бади
    }

    .list-categories__button-arrow {
        fill: currentColor;
    }
}

@mixin local-list-categories-layout($args) {
    $container-width: map_get($args, container-width);
    $list-width: map_get($args, list-width);
    $list-padding-y: map_get($args, list-padding-y);
    $item-padding-x: map_get($args, item-padding-x);
    $item-padding-y: map_get($args, item-padding-y);

    .list-categories__list-padding {
        height: $list-padding-y;
    }
    .list-categories__item-link {
        padding-top: $item-padding-y;
        padding-bottom: $item-padding-y;

        @include direction {
            #{$padding-inline-start}: ($item-padding-x);
            #{$padding-inline-end}: ($item-padding-x);
        }
    }
    .list-categories__item-arrow {
        @include direction {
            #{$inset-inline-end}: $item-padding-x;
            transform: scaleX(#{$transform-direction});
        }
    }
}

@mixin local-define-list-categories-button($scheme, $is-normal-state: false) {
    background: map_get($scheme, main);
    color: map_get($scheme, opposite);

    @if ($is-normal-state) {
        @include scheme-font-weight($scheme, opposite, $font-weight-medium);
    }

    .list-categories__button-icon {
        color: map_get($scheme, icon-color);
    }
    .list-categories__button-arrow {
        color: map_get($scheme, arrow-color);
    }
}

.list-categories {
    display: block;
    position: relative;

    @include media-breakpoint-up(lg) {
        @include list-categories-open;
    }
}

.list-categories__button {
    display: flex;
    gap: 15px;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    border: none;
    font-family: inherit;
    padding: clamp(0.75rem, 0.78vw, 1rem) clamp(1.5rem, 1.5vw, 1.875rem);
    transition: background-color 0.2s, color 0.2s;

    &:focus {
        outline: none;
    }

    .list-categories__button-start {
        display: flex;
        gap: 15px;
        justify-content: flex-start;
        align-items: center;
    }

    &--mobile {
        display: none;
    }
}

.list-categories__button-icon {
    fill: currentColor;
    transition: color 0.2s;

    svg {
        display: block;
        height: auto;
        width: clamp(1.1rem, 1.3vw, 1.6rem);
    }
}

.list-categories__button-title {
    font-size: clamp(13px, 0.9vw, 18px);
    font-weight: 700;
    letter-spacing: 2.7px;
}

.list-categories__button-arrow {
    fill: $block-list-categories-header--active-background;
    svg {
        display: block;
        height: auto;
        width: clamp(1.1rem, 1.3vw, 1.6rem);
    }
}

.list-categories__container {
    display: flex;
}

.list-categories__body {
    border-radius: 1.5px;
    pointer-events: auto;
    display: flex;
    flex-direction: column;
    //background-color: map_get($list-categories-menu-scheme, main);
    //box-shadow: map_get($list-categories-menu-scheme, shadow);
    color: map_get($list-categories-menu-scheme, opposite);
    min-width: 100%;
}

.list-categories__list {
    flex-shrink: 0;
    list-style: none;
    margin: 0;
    padding: 0;
    font-size: clamp(13px, 0.83vw, 16px);
    line-height: 20px;
    width: 100%;
    max-height: 0;
    visibility: hidden;
    opacity: 0;
    transition: max-height 0.2s, opacity 0.2s, transform 0.2s,
        visibility 0s 0.2s;

    @include scheme-font-weight(
        $list-categories-menu-scheme,
        opposite,
        $font-weight-medium
    );
}

.list-categories--open {
    @include list-categories-open;
}

.list-categories__item-link {
    color: inherit;
    display: flex;
    align-items: center;
    position: relative;
    font-weight: 700;
    gap: 15px;
    overflow: hidden;
    border-bottom: 1px solid map-get($theme-scheme, border);
    min-height: 62px;
    padding-left: 10px;
    white-space: nowrap;

    img {
        height: clamp(2.4rem, 2.7vw, 3.25rem);
        max-width: clamp(2.4rem, 2.7vw, 3.25rem);
        object-fit: contain;
    }
}

.list-categories__item-arrow {
    fill: map_get($list-categories-menu-scheme, arrow-color);
    position: absolute;
    top: calc(50% - 6px);

    svg {
        display: block;
    }
}

.list-categories__item-menu {
    display: none;
}

.list-categories__item--hover {
    background-color: hsla(34, 100%, 47%, 0.2);
    color: #f28b00;
    transition: 0.25s;
    .list-categories__item-link {
        //background: map_get($list-categories-menu-scheme, hover);
        color: inherit;
        border-bottom: 1px solid map-get($theme-scheme, main);
    }
}

.list-categories__item--arrow_subcategory {
    position: absolute;
    right: -13px;
    z-index: 11;
    height: 100%;
    width: 12px;
    top: 0;

    .list-categories__item--arrow_subcategory--content {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }
}

.list-categories__subcategory-container {
    //margin-left: 15px;
    z-index: 10;
}

.list-categories__subcategory {
    min-height: 100%;
    display: none;
    margin-left: 13px;
    position: absolute;
    opacity: 0;
    transition: opacity 1s ease-in-out;
    //top: 0;
    //right: -($sub-min-width + 20px);
    //width: $sub-min-width;
    //z-index: 100;

    &.list-categories {
        min-width: fit-content;
    }
}

.list-categories__subcategory--open {
    display: block;
    opacity: 1;
    transition: opacity 1s ease-in-out;
}

.list-categories__separator {
    margin: unset;
}

// define scheme
.list-categories__button {
    background-color: $block-list-categories-header-background;
    color: $block-list-categories-header-foreground;
}

@include media-breakpoint-up(xxl) {
    @include local-list-categories-layout(
        (
            container-width: map_get($container-max-widths, xxl),
            list-width: 100%,
            list-padding-y: clamp(0.375rem, 0.4vw, 0.5rem),
            megamenu-gutter: 30px,
            item-padding-x: clamp(1rem, 1vw, 1.25rem),
            item-padding-y: clamp(0.375rem, 0.4vw, 0.5rem),
        )
    );
}

@include media-breakpoint-only(xl) {
    @include local-list-categories-layout(
        (
            container-width: map_get($container-max-widths, xl),
            list-width: 100%,
            list-padding-y: clamp(0.375rem, 0.4vw, 0.5rem),
            megamenu-gutter: 20px,
            item-padding-x: clamp(0.75rem, 0.78vw, 1rem),
            item-padding-y: clamp(0.375rem, 0.4vw, 0.5rem),
        )
    );
}

@include media-breakpoint-down(md) {
    .list-categories__container,
    .list-categories__subcategory-container,
    .list-categories__item--arrow_subcategory {
        display: none;
    }
    .list-categories__button {
        justify-content: center;

        &--mobile {
            display: flex;
        }
    }
}
