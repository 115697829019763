@import "../variables";

$color: #fff;
$color2: map_get($theme-scheme, main);
$color3: #e3e3e3;

.radio {
  margin: 0.3rem;
  input[type="radio"] {
    position: absolute;
    opacity: 0;
    + .radio-label {
      &:before {
        content: '';
        flex-shrink: 0;
        background: $color;
        border-radius: 100%;
        border: 1px solid $color3;
        display: inline-block;
        width: 1.4em;
        height: 1.4em;
        position: relative;
        margin-right: 0.5em;
        vertical-align: top;
        cursor: pointer;
        text-align: center;
        transition: all 250ms ease;
      }
    }
    &:checked {
      + .radio-label {
        &:before {
          background-color: $color2;
          box-shadow: inset 0 0 0 2px $color;
          border: 1px solid $color2;
        }
      }
    }
    &:focus {
      + .radio-label {
        &:before {
          outline: none;
          border-color: $color2;
        }
      }
    }
    &:disabled {
      + .radio-label {
        &:before {
          box-shadow: inset 0 0 0 4px $color;
          border-color: darken($color, 25%);
          background: darken($color, 25%);
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
  .radio-label {
    display: flex;
    align-items: center;

    .label-content {
      display: flex;
      align-items: center;
      gap: 14px;
    }
  }

  .label-no-center {
    align-items: unset;
  }
}
