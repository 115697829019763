/*
// .contacts
*/
@import '../variables';
@import '../mixins/breakpoints';


.contacts {}

.contacts-block {
    border: 1px solid map-get($theme-scheme, border);
}

.contacts__block--contact {
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    align-items: center;
    transition: color 0.2s;

    &.map-available {
        &:hover,
        &:focus {
            color: map-get($theme-scheme, main);
            outline: none;
        }
    }

    svg {
        overflow: visible;
    }

    a {
        color: inherit;
        transition: color 0.2s ease-in-out;

        &:hover {
            color: map-get($theme-scheme, main);
        }
    }
}

.contacts__block--contact-name {
    cursor: pointer;
}

.contacts__block--address {
    color: inherit;
}
