@import "../_adapt.scss";
@import "../_variables.scss";
@import '../mixins/loader';
@import '../mixins/breakpoints';

.buy-button {
    $local-padding-y: adapt-size-px(14);
    width: 50%;

    .buy-button__btn {
        border-radius: unset;
        font-size: $adaptive-13-font-size;
        font-weight: 700;
        padding-top: $local-padding-y;
        padding-bottom: $local-padding-y;
        border: 1px solid map-get($theme-scheme, main);
        width: 100%;

        @include media-breakpoint-down(lg) {
            width: 100%;
        }

        .buy-button__content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;

            svg {
                height: auto;
                width: adapt-size-px(19);
            }
        }
    }

    .buy-button__btn--loading {
        cursor: default;

        &:after {
            @include loader(22px, 2px, map_get($addtocart-button-scheme, normal-font-color));
        }
    }

    .wait-button__btn {
        border-radius: unset;
        font-size: $adaptive-13-font-size;
        font-weight: 700;
        padding-top: $local-padding-y;
        padding-bottom: $local-padding-y;
        background-color: transparent;
        border: 1px solid map-get($theme-scheme, main);
        @include media-breakpoint-up(sm) {
            max-width: 200px;
        }

        .wait-button__content {
            display: flex;
            justify-content: center;
            align-items: center;
            gap: 10px;
            color: map-get($theme-scheme, main);

            svg {
                height: auto;
                width: adapt-size-px(12);
                color: map-get($theme-scheme, main);
            }

            span {
                white-space: nowrap;
            }
        }
    }

    .wait-button__btn--loading {
        cursor: default;

        &:after {
            @include loader(22px, 2px, map_get($addtocart-button-scheme, normal-font-color));
        }
    }
}
