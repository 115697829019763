@import '../variables';
@import '../mixins/breakpoints';

.checkout-login-body {
    border: 1px solid map-get($theme-scheme, border);
}

.checkout-login-header {
    z-index: 1;
    position: relative;
    margin-bottom: -1px;
}

.checkout-login-tabs {
    height: 56px;
}

.checkout-login-tabs__item {
    display: flex;
    justify-content: center;
    align-items: center;
    float: left;
    border: solid map-get($theme-scheme, border);
    border-width: 1px 1px 0;
    padding: 0 20px 0 15px;
    height: 54px;
    margin-right: -1px;
    cursor: pointer;

    &:first-child {

    }

    span {
        font-weight: $font-weight-bold;
    }
}

.checkout-login-tabs__item--active {
    background: #fff;
    height: 56px;
    cursor: default;

    span {
        color: map-get($theme-scheme, main);
    }
}

.checkout-login-tabs__item-desktop {
    @include media-breakpoint-down(xs) {
        display: none;
    }
}

.checkout-login-tabs__item-mobile {
    display: none;
    @include media-breakpoint-down(xs) {
        display: block;
    }
}

