/*
// .block-category-slides
*/
@import '../variables';
@import "../mixins/breakpoints";

.block-category-slides__body {
    padding-top: 30px;

    @include media-breakpoint-down(md) {
        padding-top: 12px;
        padding-bottom: 15px;
    }
}

//@media (max-width: 474px) {
//    .block-category-slides__body {
//        padding-top: 0;
//        padding-bottom: 10px;
//        .container {
//            padding: 0;
//        }
//    }
//}
