/*
// .card
*/
@import "../variables";
@import "../mixins/card";
@import "../mixins/breakpoints";
@import "../mixins/loader";

.card {
    @include card;

    border: none;
    border-radius: 0;
}

.card-bordered {
    border: 1px solid map-get($theme-scheme, border);
}

.card__loader {
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
    pointer-events: none;
    z-index: 2;

    @include loader-overlay(
        map_get($card-scheme, main),
        0px,
        0px,
        48px,
        2px,
        #000
    );

    &:before {
        opacity: 1;
    }
}
.card--loading .card__loader {
    pointer-events: auto;
    opacity: 1;
}
.card-body {
    .mat-elevation-z8 {
        box-shadow: unset !important;
    }
}

.card-body--padding--2 {
    padding: 2rem;

    @include media-breakpoint-down(xs) {
        padding: 1.5rem;
    }
}

.card-insert-body {
    padding: 20px;
    border: 1px solid map-get($theme-scheme, border);
    background: rgba(227, 227, 227, 0.2);
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    gap: 10px;
    height: 100%;
    h6 {
        font-weight: $font-weight-bold;
    }
}
.card-divider {
    height: 1px;
    background: map_get($card-scheme, divider);
}
.card-header {
    border-radius: 0;
    background: transparent;
    padding: 1.25rem 2rem;
    border: none;
    align-items: center;
    @include media-breakpoint-down(sm) {
        padding: 1.25rem 0.5rem;
    }

    & > h1 {
        @include media-breakpoint-down(sm) {
            font-size: $h5-font-size;
        }
    }

    & > h5 {
        margin-bottom: 0;
        font-weight: $font-weight-bold;
        @include media-breakpoint-down(sm) {
            font-size: $default-font-size;
        }
    }
}
.card-footer {
    border-radius: 0;
    background: transparent;
    padding: 1.25rem 2rem;
    border: none;
}
.card-footer__body {
    display: grid;
    grid-template-columns: 1fr 300px;
    align-items: center;

    @include media-breakpoint-down(sm) {
        grid-template-columns: 1fr;
        grid-template-rows: auto auto;
        //row-gap: 10px;
    }
}
.card-title {
    font-size: 24px;
    font-weight: $font-weight-medium;
    //padding-bottom: 8px;
    //border-bottom: 1px solid map_get($card-scheme, divider);
    margin-bottom: 1rem;
    margin-top: -4px;
}
.card-title--lg {
    font-size: 28px;
}
.card-bottom-button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: map-get($theme-scheme, main);
    color: map-get($theme-scheme, contrast-text);
    transition: all ease-in-out 0.2s;
    border: unset;
    padding: 12px;
    font-weight: $font-weight-semi-bold;
    text-transform: uppercase;

    &:hover {
        background-color: map-get($theme-scheme, fill);
    }
}
