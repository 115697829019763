@import "../variables";
@import '../mixins/breakpoints';

.compatible-vehicle {
    &__wrapper {
        display: flex;
        align-items: stretch;
    }

    &__message {
        flex: 1;
        display: flex;
        align-items: center;
        gap: 1rem;
        padding: 1rem;
        background-color: map-get($theme-scheme, border);
        line-height: 1.3;
        font-size: $adaptive-14-font-size;

        &.wrong {
            background-color: $warning-body-color;
            svg {
                fill: $warning-icon-color;
            }
        }
    }

    &__add-button {
        min-width: 80px;

        span {
            font-size: 22px;
            font-weight: $font-weight-bold;
        }
    }
}
