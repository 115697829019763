/*
// .layout-switcher
*/
@import '../variables';
@import '../mixins/scheme';


.layout-switcher {}
.layout-switcher__list {
    display: flex;
    @include media-breakpoint-down(sm) {
        display: none;
    }
}
.layout-switcher__button {
    margin: 0;
    border-radius: 0;
    border: 1px solid map-get($theme-scheme, border);
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;

    @include define-button-state($layout-switcher-scheme, normal);

    &:focus {
        outline: none;
    }

    svg {
        display: block;
        fill: currentColor;
    }
}
.layout-switcher__button:hover {
    @include define-button-state($layout-switcher-scheme, hover);
}
.layout-switcher__button--active {
    @include define-button-invert-state($layout-switcher-scheme, current);

    &:hover {
        @include define-button-invert-state($layout-switcher-scheme, current);
    }
}
