@import '../variables';
@import '../mixins/card';
@import '../mixins/direction';
@import '../mixins/breakpoints';
@import '../mixins/decor';

.icon-settings {
    width: 80px;
    height: 80px;
    color: #F28B00;

    & svg {
        height: 100%;
        width: 100%;
    }

    @include media-breakpoint-up(md) {
        width: 120px;
        height: 120px;
    }
}

.header-settings {
    font-size: 24px;
    @include media-breakpoint-up(md) {
        font-size: 32px;
    }
}

.header-settings__subtitle {
    font-size: 14px;
    @include media-breakpoint-up(md) {
        font-size: 22px;
    }
}

.form-settings {
    width: 100%;
    @include media-breakpoint-up(md) {
        width: 50%;
    }
    @include media-breakpoint-up(lg) {
        width: 40%;
    }
}

.reset-password__email {
    width: 100%;
}

.input-settings {
   min-height: 40px;
    padding: 0 15px;
    font-size: 18px;
}

.btn-reset__password {
    width: 100%;
    margin: 0 auto;
    @include media-breakpoint-up(md) {
        width: 70%!important;
    }
}

