@import "../variables";

.product-applicability-brand {
    .product-applicability-brand__title {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 10px;
        border-bottom: 1px solid map-get($theme-scheme, border);
        transition: background-color 0.4s ease-in-out;

        span {
            font-size: $adaptive-18-font-size;
            font-weight: $font-weight-bold;
            overflow: hidden;
            text-overflow: ellipsis;
            transition: color 0.4s ease-in-out;
        }

        svg {
            width: 21px;
            height: 12px;
            transition: all .4s ease-in-out;
            fill: map-get($theme-scheme, border);
        }

        &.open {
            $mainColor: map-get($theme-scheme, main);
            $fontColor: map-get($theme-scheme, contrast-text);

            background-color: $mainColor;
            border-color: $mainColor;

            span {
                color: $fontColor;
            }
            svg {
                fill: $fontColor;
                transform: rotate(180deg);
            }
        }
    }
}
