@import "../variables";

.product-properties {
    display: flex;
    flex-wrap: wrap;
    gap: 5px 15px;
    max-width: 70%;

    .product-property__item {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        cursor: help;

        &-name {
            font-size: $default-font-size;
            font-weight: 400;
            opacity: 0.7;
        }

        &-value {
            font-size: $default-font-size;
            font-weight: 500;
            margin-left: 4px;
        }
    }
}
