/*
// .vehicle-select
*/
@import '../variables';
@import '../svg';
@import '../mixins/breakpoints';
@import '../mixins/loader';

.vehicle-featured-border {
    width: 100%;
    height: 0;
    border-top: 1px solid map-get($theme-scheme, main);
    margin: 0;
    grid-column: 1 / -1;
}

@mixin vehicle-select-grid {
    display: grid;
    grid-auto-flow: row;
    gap: 1px;
    grid-auto-rows: min-content;
    padding: 1px;
    height: 354px;
    overflow: scroll;
}

.vehicle-select--brands {
    @include vehicle-select-grid;

    grid-template-columns: repeat(6, 1fr);

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.vehicle-select--models {
    @include vehicle-select-grid;

    grid-template-columns: repeat(6, 1fr);

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.vehicle-select--years {
    @include vehicle-select-grid;

    grid-template-columns: repeat(6, 1fr);

    @include media-breakpoint-down(lg) {
        grid-template-columns: repeat(4, 1fr);
    }
}

.selectable-list-item {
    height: 100%;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s ease-in-out;
    border: 1px solid transparent;
    border-left: 3px solid transparent;
    font-weight: $font-weight-medium;
    background-color: map-get($theme-scheme, background);
    box-shadow: 0 0 0 1px map-get($theme-scheme, border);

    .selectable-list-item--center {
        text-align: center;
    }

    .addition {
        color: map-get($theme-scheme, secondary-text);
        font-size: $adaptive-13-font-size;
    }

    &.selectable-list-item--bold {
        font-weight: $font-weight-bold;
    }

    &.selectable-list-item--mark {
        box-shadow: 0 0 6px map-get($theme-scheme, main);
    }

    &:hover {
        background-color: map-get($theme-scheme, picker-hover);
    }

    .values {
        display: flex;
        gap: 0.5rem;

        .title {
            color: map-get($theme-scheme, secondary-text);
        }
    }
}

.selectable-list-item--active {
    color: map-get($theme-scheme, main);
    background-color: map-get($theme-scheme, picker-selected);
}

.vehicle-card {
    cursor: pointer;
    transition: all 0.3s ease-in-out;

    &.vehicle-card--mark {
        box-shadow: 0 0 6px map-get($theme-scheme, main);
    }

    &:hover {
        box-shadow: 0 0 12px map-get($theme-scheme, fill);
    }
}

.vehicle-component {
    .mdc-tab-indicator__content--underline {
        display: none;
    }
    .mdc-tab-indicator--active {
        background-color: map-get($theme-scheme, main);
        position: relative;

        .mdc-tab__text-label {
            color: white !important;
        }

        &:after {
            content: "";
            position: absolute;
            right: -20px;
            top: -1px;
            border-top: 49px solid transparent;
            border-left: 20px solid map-get($theme-scheme, main);
        }
    }
}

@mixin vehicle-select__compact {
    .vehicle-select--brands,
    .vehicle-select--models,
    .vehicle-select--years {
        grid-template-columns: repeat(1, 1fr);
    }

    .selectable-list-item {
        text-align: left !important;
        position: relative;
        padding-right: 2rem;

        &:after {
            content: '';
            position: absolute;
            right: 10px;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 17px;
            height: 17px;
            border: 1px solid map-get($theme-scheme, border);
            border-radius: 100%;
        }
    }

    .selectable-list-item--active {
        &:after {
            background-color: map-get($theme-scheme, main);
        }
    }

    @include media-breakpoint-down(xs) {
        .selectable-list-item {
            padding: 0.6rem 2rem 0.6rem 0.6rem;
        }
    }
}

.compact {
    @include vehicle-select__compact;
}

@include media-breakpoint-down(md) {
    @include vehicle-select__compact;
}
