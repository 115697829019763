/*
// .image
*/
@use 'sass:math';

@import '../variables';

.image {};
.image--type--product,
.image--type--category {
    .image__body {
        display: block;
        position: relative;
        width: 100%;

        &:hover {
            .owl-nav {
                opacity: 1;
            }
        }

        .owl-nav {
            position: absolute;
            top: 42%;
            width: 100%;
            color: map-get($theme-scheme, main);
            opacity: 0;
            transition: opacity 0.4s;

            .owl-prev {
                position: absolute;
                transform: scaleY(3);
                left: 4px;
                width: 20px;
                padding: 0 4px;

                &:hover {
                    transform: scaleY(3.2);
                }
            }
            .owl-next {
                position: absolute;
                transform: scaleY(3);
                right: 4px;
                width: 20px;
                text-align: end;
                padding: 0 4px;

                &:hover {
                    transform: scaleY(3.2);
                }
            }

            .disabled {
                cursor: default;
                filter: grayscale(1);

                &:hover {
                    transform: scaleY(3);
                }
            }
        }
    }
    .image__tag {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        object-fit: scale-down;

        &-carousel-item {
            position: unset;
        }
    }
}
.image--type--product {
    .image__body {
        padding-bottom: #{100% * math.div(1, $product-image-ratio)};
    }
}
.image--type--category {
    .image__body {
        padding-bottom: #{100% * math.div(1, $category-image-ratio)};
    }
}

//.change-photos:hover {
//    cursor: pointer;
//}
