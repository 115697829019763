/*
// .mobile-header
*/
@import "../variables";
@import "../mixins/direction";
@import "../mixins/breakpoints";
@import "../mixins/header";

@include mobile-header-variant-selector {
    .site__mobile-header {
        position: sticky;
        top: 0;
        z-index: 100;
    }
    .mobile-header__wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 1rem;
        padding: 16px 0;
    }
    .mobile-header {
        display: block;
        background: map_get($mobile-header-scheme, header);
        box-shadow: map_get($mobile-header-scheme, shadow);
        position: relative;
        z-index: 100;
        width: 100%;

        @include media-breakpoint-up(lg) {
            display: none;
        }
    }
    .mobile-header__logo {
        margin-left: 1rem;
    }
    .mobile-header__menu-button {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 0;
        margin: 0;
        border: none;
        background: transparent;

        svg {
            display: block;
        }
    }
    .mobile-header__logo {
        display: flex;
        align-items: center;
        padding: 0;
    }
    .mobile-header__search {
        display: flex;
        align-items: stretch;
        max-width: 440px;
        flex-grow: 1;
        padding: 0 12px;
        margin: 0 auto;
        gap: 10px;
        width: 100%;
        height: 40px;

        input {
            border-radius: 1px;

            &::placeholder {
                font-size: $adaptive-13-font-size;
            }
        }
    }
    .mobile-header__indicators {
        display: flex;
        align-items: center;
        gap: 10px;
    }
    .mobile-header__xs-contacts {
        display: none;
    }
    .mobile-header__contacts {
        svg {
            width: 24px !important;
        }
    }

    @include media-breakpoint-down(xs) {
        /* Mobile Header Grid Layout */
        .mobile-header__wrapper {
            display: grid;
            grid-template-rows: auto auto;
            grid-template-columns: repeat(7, 1fr);
            gap: 10px;
        }

        /* First row positioning */
        .mobile-header__menu-button {
            grid-row: 1;
            grid-column: 1;
            margin-right: auto;
        }

        .mobile-header__logo {
            grid-row: 1;
            grid-column: 2 / 7;
            justify-self: center;
            margin-left: 0;
        }

        .mobile-header__contacts {
            grid-row: 1;
            grid-column: 7;
            margin-left: auto;
        }

        /* Second row positioning */
        .mobile-header__search {
            grid-row: 2;
            grid-column: 1 / 6;
            width: 100%;
            padding: 0;
        }

        .mobile-header__indicators {
            grid-row: 2;
            grid-column: 6 / 8;
            margin-left: auto;
        }
    }
}
