/*
// .departments
*/
@use 'sass:math';

@import '../variables';
@import '../functions';
@import '../mixins/breakpoints';
@import '../mixins/grid';
@import '../mixins/direction';
@import '../mixins/scheme';
@import '../mixins/header';
@import "../adapt";


@include desktop-header-variant-selector {
    .contacts {
        display: block;
        position: relative;
    }
    .contacts__button {
        display: flex;
        gap: 10px;
        align-items: center;
        padding: 0;
        position: relative;
        background-color: transparent;
        border: none;
        font-family: inherit;
        transition: color .2s;
        color: $topbar-text-color;
        fill: $topbar-text-color;

        &:focus {
            outline: none;
        }
        &:hover {
            color: $topbar-text-hover-color;
            fill: $topbar-text-hover-color;
        }
    }
    .contacts__button-title {
        font-size: 14px;
        font-weight: $font-weight-medium;
        transition: color .2s;
    }
    .contacts__button-title--number {
        font-size: 16px;
    }
    .contacts__button-icon {
        svg {
            height: auto;
            width: adapt-size-px(20);
        }
    }
    .contacts__button-arrow {
        transition:
            transform .2s,
            color .2s;

        svg {
            display: block;
            height: auto;
            width: adapt-size-px(9);
        }
    }
    .contacts__menu {
        position: absolute;
        z-index: 1;
        visibility: hidden;
        opacity: 0;
        top: 100%;
        right: 0;
        transform: translateY(-10px);
        transition:
            opacity .2s,
            transform .2s,
            visibility 0s .2s;
    }
    .contacts__body {
        pointer-events: auto;
        display: flex;
        background-color: map_get($departments-menu-scheme, main);
        box-shadow: map_get($departments-menu-scheme, shadow);
        color: map_get($departments-menu-scheme, opposite);

        @include direction {
            float: $inline-start;
        }
        padding: 15px 20px;
        min-width: 320px;
    }
    .contacts__list {
        //white-space: nowrap;
        //flex-shrink: 0;
        list-style: none;
        margin: 0;
        padding: 0;
        width: 100%;
    }
    .contacts__link {
        font-size: $default-font-size;
        font-weight: 500;
        color: $topbar-list-item-text-color;
        &:hover {
            color: $topbar-list-item-hover-color;
        }
    }
    .contacts__item {
        color: inherit;
        display: block;
        position: relative;
        padding: 5px 0;

        a {
            font-size: $default-font-size;
            font-weight: 500;
            color: $topbar-list-item-text-color;
            &:hover {
                color: $topbar-list-item-hover-color;
            }
        }
    }
    .contacts__link-icon {
        margin-right: 15px;
        svg {
            height: auto;
            width: adapt-size-px(20);
        }
    }

    .contacts--open {
        .contacts__menu {
            transition-delay: 0s;
            visibility: visible;
            opacity: 1;
            transform: translateY(0);
        }
        .contacts__button-arrow {
            transform: rotate(180deg);
        }
    }
    .mobile-contacts__button {
        height: 100%;
        padding-top: 5px;
        padding-bottom: 5px;
    }
    .contacts__arrow {
        $local-button-icon-width: 16px;
        $local-button-icon-offset: 14px;

        width: ($local-button-icon-width + $local-button-icon-offset * 2) - 3px;
    }
    .contacts__schedule {
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        padding: 10px 10px;
        gap: 10px;
        background-color: #E3E3E3;
        span {
            font-size: $default-font-size;
            font-weight: 500;
            color: $topbar-list-item-color;
        }
    }
}
