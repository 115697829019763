/*
// .block-parent-categories
*/
@import '../variables';
@import '../mixins/breakpoints';
@import "../adapt";


@mixin local-layout($columns) {
    .block-parent-categories__item {
        width: calc((100% - #{$columns * 1px}) / #{$columns});
    }
    .block-parent-categories__divider {
        width: 1px;
    }
    .block-parent-categories__divider:nth-child(#{$columns * 2}n) {
        height: 1px;
        width: 100%;

        &:last-child {
            display: none;
        }
    }
}


$local-transition-duration: .15s;


.block-parent-categories__body {
    padding-top: 60px;
    padding-bottom: 60px;

    @include media-breakpoint-down(md) {
        padding: 30px 0;
    }
}
.block-parent-categories__list {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    flex-wrap: wrap;
    //border: 1px solid $block-parent-categories-divider;
}
.block-parent-categories__item {
    text-align: center;
    flex-shrink: 0;
    display: flex;
    justify-content: center;
}
.block-parent-categories__item-link {
    position: relative;
    display: block;
    padding: 7px;
    width: 100%;
    border: 1px solid $block-parent-categories-divider;
    background-color: #fff;
    min-height: 120px;

    //&:before {
    //    position: absolute;
    //    content: '';
    //    width: 100%;
    //    height: 100%;
    //    left: 0;
    //    top: 0;
    //    //background: $block-parent-categories-item-hover-overlay-color;
    //    opacity: 0;
    //    transition: opacity $local-transition-duration;
    //}

    img {
        max-width: 100%;
        //height: adapt-size-px(195);
        height: clamp(3.140625rem, 10.15625vw, 12.1875rem);
        //min-height: 100px;

        //filter: grayscale(1);
        //opacity: .7;
        transition:
            filter $local-transition-duration,
            opacity $local-transition-duration;
        object-fit: contain;
    }
}
.block-parent-categories__item-name {
    display: block;
    font-size: $default-font-size;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: .04em;
    color: $block-parent-categories-item-normal-font-color;
    margin-top: adapt-size-px(20);
    //margin-bottom: adapt-size-px(20);
    transition: color $local-transition-duration;

    @include media-breakpoint-down(sm) {
        font-size: 10px;
        word-break: break-word;
    }
}
.block-parent-categories__item-link:hover {
    border: none;
    box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    z-index: 1;

    &:before {
        opacity: 1;
    }
    img {
        //filter: grayscale(0);
        opacity: 1;
    }
    .block-parent-categories__item-name {
        color: $block-parent-categories-item-hover-font-color;
    }
}
.block-parent-categories__divider {
    background: $block-parent-categories-divider;
}


//.block-parent-categories--layout--columns-8-full {
//    @include media-breakpoint-up(xxl) {
//        @include local-layout(8);
//    }
//    @include media-breakpoint-only(xl) {
//        @include local-layout(7);
//    }
//    @include media-breakpoint-only(lg) {
//        @include local-layout(6);
//    }
//}
//
//
//.block-parent-categories--layout--columns-7-sidebar {
//    @include media-breakpoint-up(xxl) {
//        @include local-layout(7);
//    }
//    @include media-breakpoint-only(xl) {
//        @include local-layout(6);
//    }
//    @include media-breakpoint-only(lg) {
//        @include local-layout(5);
//    }
//}

.block-parent-categories--layout--columns-5-full {
    @include media-breakpoint-up(xxl) {
        @include local-layout(5);
    }
    @include media-breakpoint-only(xl) {
        @include local-layout(4);
    }
    @include media-breakpoint-only(lg) {
        @include local-layout(5);
    }
}


// for all layouts
@include media-breakpoint-only(md) {
    @include local-layout(3);
}
@include media-breakpoint-only(sm) {
    @include local-layout(3);
}
@media (min-width: 420px) and (max-width: 575px) {
    @include local-layout(2);
}
@media (max-width: 419px) {
    @include local-layout(2);
}
