@import "../variables";
@import "../mixins/breakpoints";

.stock-available {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
}

.stock-available-item {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    .stock-available-item__header {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 10px;
        font-size: 12px;
    }

    .stock-available-group {
        display: flex;
        flex-direction: column;
        gap: 10px;
    }

    .stock-available-group__show-map {
        cursor: pointer;
    }

    .stock-available-group__item {
        display: flex;
        gap: 12px;
        row-gap: 0;
        justify-content: space-between;
        flex-wrap: wrap;

        &.receiving {
            background-color: #efefef;
            padding: 20px;
        }

        &.open {
            flex-direction: column;
            align-items: start;
            border-bottom: 1px dotted map-get($theme-scheme, main);
            margin-bottom: 12px;
            cursor: pointer;
        }

        &.partner {
            display: grid;
            grid-template-columns: 1fr 60px;
            grid-template-rows: auto auto;
            grid-template-areas:
        "group-name group-partner"
        "group-available group-partner";
            grid-gap: 4px;
            align-items: center;

            .group-name {
                grid-area: group-name;
            }

            .group-available {
                grid-area: group-available;
            }

            .group-partner {
                grid-area: group-partner;
            }
        }

        .stock-available-group__line {
            display: flex;
            gap: 10px;
        }

        &.map-available {
            &:hover,
            &:focus {
                color: map-get($theme-scheme, main);
                outline: none;
            }
        }

        &--name,
        &--amount {
            font-weight: $font-weight-semi-bold;
            white-space: nowrap;
            color: map-get($theme-scheme, main);
        }

        &--partner {
            color: map-get($theme-scheme, border-contrast);
            font-size: small;
        }
    }
}


