@import "../variables";
@import "../mixins/loader";

.bom {
    .products-list[data-layout=list] .product-card {
        border: 1px solid map-get($theme-scheme, border);

        .product-card__image {
            width: 120px;
        }
        .product-card__vd {
            display: none;
        }
        .product-card-properties {
            display: none;
        }
        .buy-button {
            width: min-content;
        }
        .product-card__footer {
            width: min-content;
        }
    }
    .mat-expansion-panel {
        border-radius: 0 !important;
    }
    .mat-expansion-panel-body {
        padding: 0 8px 8px;
    }
    .mat-mdc-chip-selected {
        background-color: map-get($theme-scheme, main) !important;
    }
    .bom-loader {
        display: flex;
        justify-content: center;
        @include loader-overlay(#ebebeb, 0px, 0px, 20px, 2px, #000);
        position: relative;
        width: 100%;
        height: 50px;
        z-index: 10;
        pointer-events: auto;
        transition: opacity .2s ease-in-out;
    }
    .bom-loader--content {
        @include loader-overlay(#ebebeb, 0px, 0px, 40px, 2px, #000);
    }
    .bom-root-header {
        font-weight: $font-weight-semi-bold;
    }
    .bom-left {
        max-height: 68vh;
        overflow: auto;
        direction: rtl;
        padding-left: 8px;
        padding-right: 8px;
        & > * {
            direction: ltr;
        }
    }

    h2 {
        font-weight: $font-weight-bold;
        color: map-get($theme-scheme, secondary-text);
        text-align: center;
        opacity: 0.6;
    }

    h4 {
        font-size: 1.2rem;
        font-weight: $font-weight-bold;
        text-align: center;
    }
}
