//.map-marker {
//    display: block;
//    width: 30px;
//    height: 40px;
//    background-image: url("/assets/images/marker.png");
//    background-position: center;
//    background-size: contain;
//    margin-top: -12px;
//}
//
//.leaflet-container a.leaflet-popup-close-button {
//    background: unset !important;
//}
//
//.leaflet-popup-content .map-card .map-card-body .cta-wrapper {
//    display: none !important;
//}
//
//.leaflet-popup-content .map-card .map-card-body .top-card .content .map-card-title {
//    font-size: 1.2rem;
//}
//
//.leaflet-popup-content .map-card .map-card-body .top-card .content .map-address {
//    font-size: 0.9rem;
//}
//
//.leaflet-popup-content .map-card .map-card-body .top-card img {
//    width: unset !important;
//    height: unset !important;
//    margin-right: 20px;
//    margin-bottom: auto;
//    margin-top: 8px;
//}

.gm-style-iw-d {
    overflow: visible !important;
}

.gm-style-iw-c {
    padding: 0.8rem !important;
}


