/*
// .site
*/
@import "../variables";
@import "../mixins/breakpoints";

.site {
    height: 100%;
}
.site__container {
    min-height: 100%;
    display: flex;
    flex-direction: column;
    //overflow: hidden;
    //clip-path: inset(0 0 0 0);
}
.site__header {
    flex-shrink: 0;
    background-color: map-get($theme-scheme, fill);
    position: sticky;
    top: 0;
    z-index: 100;
    //@include media-breakpoint-up(sm) {
    //    position: sticky;
    //    top: 0;
    //    width: 100%;
    //    z-index: 100;
    //}
}
.site__body {
    flex-grow: 1;
}
.site__footer {
    flex-shrink: 0;
}
