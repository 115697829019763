/*
// .language-selector
*/
@import '../variables';
@import "../adapt";
@import "../mixins/breakpoints";

.language-selector {
    display: flex;
    align-items: center;
    gap: 12px;
    fill: $topbar-text-color;
    color: $topbar-text-color;
    font-size: $adaptive-13-font-size;

    svg {
        height: auto;
        width: adapt-size-px(14);
    }

    @include media-breakpoint-down(sm) {
        svg {
            width: 14px;
        }
    }
}

.language-selector__button {
    background: transparent;
    border: none;
    padding: 0;
    font-size: $adaptive-13-font-size;
    font-weight: 500;
    color: $topbar-text-color;

    &:hover {
        color: $topbar-text-hover-color;
    }

    @include media-breakpoint-down(sm) {
        font-size: 13px;
    }
}

.language-selector__button-active {
    color: $topbar-text-hover-color;
}
