@import "../variables";

.expansion-content {
    --mat-expansion-container-background-color: transparent;
    --mat-expansion-header-hover-state-layer-color: transparent;
    --mat-expansion-container-shape: 0;
    --mat-expansion-header-indicator-color: #f28b00;

    .mat-expansion-panel,
    .mat-expansion-panel:not([class*=mat-elevation-z]) {
        --mat-expansion-container-shape: 0;
        box-shadow: none !important;
    }
    .mat-expansion-panel {
        transition: margin 225ms cubic-bezier(0.4, 0, 0.2, 1);
    }
    .mat-expansion-panel-body,
    .mat-expansion-panel-header {
        padding: 0;
    }
    .mat-expansion-panel-header {
        padding-right: 2px;
    }
}

.expansion-content-title {
    font-size: 1.6rem;
    font-weight: $font-weight-semi-bold;
}
