/** Кнопка в map-info-window кнопка закрытия окна и title */
.gm-style-iw-chr {
    display: none;
}

/** Окно map-info-window */
.gm-style-iw-t {
    opacity: 0.95;
}

.gm-style-iw-tc {
    opacity: 0.9;
}

.google-map--window {
    display: flex;
    max-width: 220px;

    a {
        cursor: pointer;
    }
}

.google-map--close {
    cursor: pointer;
    font-size: 16px;
    margin: 0 5px;
}
