@import "../adapt";

.complex-pictogram {
    position: relative;
    height: 60px;
    width: 30%;

    .complex-pictogram__item {
        position: absolute;
        top: 50%;
        left: 50%;
        right: 50%;
        bottom: 50%;
        transform: translate(-50%, -50%);
        //max-height: adapt-size-px(40);
        width: auto !important;
    }

    .complex-pictogram__item-value {
        position: absolute;
        left: 50%;
        right: 50%;
        bottom: -10px;
        transform: translate(-50%, -50%);
        font-size: 11px;
        font-weight: 600;
        width: max-content;
        letter-spacing: normal;
    }
}
