// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$storefront-primary: mat.define-palette(mat.$orange-palette);
$storefront-accent: mat.define-palette(mat.$green-palette);

// The warn palette is optional (defaults to red).
$storefront-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$storefront-theme: mat.define-light-theme((
    color: (
        primary: $storefront-primary,
        accent: $storefront-accent,
        warn: $storefront-warn,
    )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($storefront-theme);

.mat-mdc-tab-group,
.mat-mdc-tab-nav-bar {
    --mdc-tab-indicator-active-indicator-color: #f18c16;
    --mat-tab-header-disabled-ripple-color: rgba(0, 0, 0, 0.38);
    --mat-tab-header-pagination-icon-color: #000;
    --mat-tab-header-inactive-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-label-text-color: #f18c16;
    --mat-tab-header-active-ripple-color: #f18c16;
    --mat-tab-header-inactive-ripple-color: #f18c16;
    --mat-tab-header-inactive-focus-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-inactive-hover-label-text-color: rgba(0, 0, 0, 0.6);
    --mat-tab-header-active-focus-label-text-color: #f18c16;
    --mat-tab-header-active-hover-label-text-color: #f18c16;
    --mat-tab-header-active-focus-indicator-color: #f18c16;
    --mat-tab-header-active-hover-indicator-color: #f18c16;
}

.mat-mdc-icon-button.mat-default {
    --mdc-icon-button-icon-color: #f18c16;
    --mat-mdc-button-persistent-ripple-color: #f18c16;
    --mat-mdc-button-ripple-color: rgba(242, 139, 0, 0.1);
}

mat-checkbox {
    --mdc-checkbox-disabled-selected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-disabled-unselected-icon-color: rgba(0, 0, 0, 0.38);
    --mdc-checkbox-selected-checkmark-color: #fff;
    --mdc-checkbox-selected-focus-icon-color: #f18c16;
    --mdc-checkbox-selected-hover-icon-color: #f18c16;
    --mdc-checkbox-selected-icon-color: #f18c16;
    --mdc-checkbox-selected-pressed-icon-color: #f18c16;
    --mdc-checkbox-unselected-focus-icon-color: #212121;
    --mdc-checkbox-unselected-hover-icon-color: #212121;
    --mdc-checkbox-unselected-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-unselected-pressed-icon-color: rgba(0, 0, 0, 0.54);
    --mdc-checkbox-selected-focus-state-layer-color: #f18c16;
    --mdc-checkbox-selected-hover-state-layer-color: #f18c16;
    --mdc-checkbox-selected-pressed-state-layer-color: #f18c16;
    --mdc-checkbox-unselected-focus-state-layer-color: black;
    --mdc-checkbox-unselected-hover-state-layer-color: black;
    --mdc-checkbox-unselected-pressed-state-layer-color: black;
}

.mdc-data-table__header-row {
    --mat-table-header-headline-color: #fff;
    background-color: #242424 !important;
}

.reverse-checkbox {
    .mdc-form-field {
        display: flex !important;
        flex-direction: row-reverse;
        align-items: center;
    }
}

table tr:nth-child(even) {
    background-color: rgba(3, 66, 229, 0.03);
}

.mat-mdc-form-field-subscript-wrapper {
    display: none;
}

.mat-mdc-form-field {
    --mat-datepicker-toggle-active-state-icon-color: #f18c16;
}

.mat-mdc-form-field-focus-overlay {
    background-color: rgba(242, 139, 0, 0.3);
}

mat-calendar {
    --mat-datepicker-calendar-date-selected-state-background-color: #f18c16;
    --mat-datepicker-calendar-date-selected-disabled-state-background-color: rgba(
        242,
        139,
        0,
        0.4
    );
    --mat-datepicker-calendar-date-focus-state-background-color: rgba(
        242,
        139,
        0,
        0.3
    );
    --mat-datepicker-calendar-date-hover-state-background-color: rgba(
        242,
        139,
        0,
        0.3
    );
    --mat-datepicker-calendar-date-in-range-state-background-color: rgba(
        242,
        139,
        0,
        0.2
    );
}

.mdc-text-field--filled {
    --mdc-filled-text-field-container-shape: 0 !important;
}

html {
    --mdc-filled-text-field-container-shape: 0;
    --mdc-filled-text-field-caret-color: #f18c16;
    --mdc-filled-text-field-focus-active-indicator-color: #f18c16;
    --mdc-filled-text-field-focus-label-text-color: rgba(242, 139, 0, 0.87);
}

.mat-expansion-panel-header {
    @include media-breakpoint-down(sm) {
        padding: 4px !important;
    }
}

.mat-expansion-panel-header-title,
.mat-expansion-panel-header-description {
    @include media-breakpoint-down(xs) {
        margin-right: 8px !important;
        display: grid !important;
        grid-template-columns: 1fr auto;
        grid-template-rows: 3 / 1;
        & > div {
            grid-column: 1 / 2;
        }
        &:nth-child(4) {
            grid-column: 2;
            grid-row: 1 / span 3;
        }
    }
}

.mat-expansion-panel-body {
    @include media-breakpoint-down(xs) {
        padding: 0 8px 8px !important;
    }
}

.mat-date-range-input-wrapper {
    font-size: $default-font-size;
    @include media-breakpoint-down(xs) {
        font-size: $adaptive-14-font-size;
    }
}

.mdc-text-field {
    padding: 0 2px !important;
}


.mat-mdc-table thead {
    @include media-breakpoint-down(xs) {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
}

.mat-mdc-row {
    @include media-breakpoint-down(xs) {
        height: auto !important;
    }
}

.mdc-data-table__row:last-child .mat-mdc-cell {
    @include media-breakpoint-down(xs) {
        border-bottom: 1px solid #b5b3b3 !important;
    }
}
