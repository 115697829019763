/*
// .departments
*/

@import '../variables';
@import '../mixins/direction';
@import '../mixins/loader';
@import "../adapt";


.collapse-picker {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.collapse-picker__button {
    display: flex;
    gap: 10px;
    align-items: center;
    justify-content: space-between;
    background-color: transparent;
    border: 1px solid map_get($theme-scheme, border);
    padding: 10px 20px;
    transition: color .2s;
}

.collapse-picker__button-title {
    font-size: $default-font-size;
    font-weight: 600;
    text-align: start;
}

.collapse-picker__button-arrow {
    transition: background-color .2s,
    transform .2s,
    color .2s;

    svg {
        display: block;
        height: auto;
        width: adapt-size-px(37);
    }
}

.collapse-picker__body {
    display: flex;
    flex-direction: column;
    gap: 8px;
    height: 0;
    overflow: hidden;
    padding: 8px 0;
    visibility: hidden;
    opacity: 0;
    transition: opacity .3s,
    height .3s,
    transform .3s,
    visibility 0s .3s;
}

.collapse-picker__search {
    width: 100%;
}

.collapse-picker__items {
    display: flex;
    flex-wrap: wrap;
    min-height: 30px;
}

.collapse-picker__items-button {
    $padding-x: adapt-size-px(20);
    width: 33.33333%;
    background-color: transparent;
    padding-top: $padding-x;
    padding-bottom: $padding-x;
    border: 1px solid map-get($theme-scheme, border);
    transition: box-shadow 0.3s ease, color 0.3s ease;

    &:hover, &.selected {
        color: map-get($theme-scheme, main);
        border: none;
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.15);
    }
}

.collapse-picker--open {
    .collapse-picker__body {
        transition-delay: 0s;
        height: auto;
        transform: translateY(0);
        overflow: visible;
        visibility: visible;
        opacity: 1;
    }

    .collapse-picker__button {
        background-color: map_get($theme-scheme, main);
        color: map_get($theme-scheme, contrast-text);
        border: 1px solid map-get($theme-scheme, contrast-text);
    }

    .collapse-picker__button-arrow {
        transform: rotate(180deg);
    }
}

.collapse-picker__button {
    height: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
}

.collapse-picker__arrow {
    $local-button-icon-width: 16px;
    $local-button-icon-offset: 14px;

    width: ($local-button-icon-width + $local-button-icon-offset * 2) - 3px;
}

.collapse-picker__items-title {
    margin-top: 8px;
    font-weight: 600;
}

.collapse-picker__loader {
    @include loader-overlay(#ebebeb, 0px, 0px, 20px, 2px, #000);
    position: fixed;
    z-index: 10;
    pointer-events: auto;
    transition: opacity .2s ease-in-out;
}
