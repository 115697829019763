@import "../variables";

:root {
  --select2-single-height: 40px;
  --select2-selection-line-height: 40px;
  --select2-selection-border-radius: 0;
  --select2-selection-focus-border-color: #F28B00;
  --select2-selection-border-color: #ebebeb;
  --select2-selection-text-color: #242424;
  --select2-option-highlighted-background: #F28B00;
}

.select2-selection--single {
  span {
    font-weight: 500;
  }
}

