@import "../variables";

.input-car-vin {
    position: relative;
    height: 47px;
    border-radius: 4px;
    background: rgb(255, 255, 255);
    border: 1px solid map-get($theme-scheme, border);
    //padding: 0 0 0 32px;
    color: map-get($theme-scheme, secondary-text);
    text-align: left;
    overflow: hidden;
    min-width: 260px;

    input {
        font-size: 16px;
        height: 100%;
        width: 100%;
        text-transform: uppercase;
        border: none;
        outline: none;
        text-align: center;
        box-shadow: inset 0 0 5px 0 rgba(0, 0, 0, 0.1);

        &:focus {
            outline: none;
            box-shadow: none;
        }

        &:active {
            outline: none;
            box-shadow: none;
        }

        &::placeholder {
            font-size: $default-font-size;
            font-weight: normal;
            text-transform: none;
            color: map-get($theme-scheme, border-contrast);
        }
    }

    .icon-search {
        position: absolute;
        right: 10px;
        top: 50%;
        transform: translateY(-50%);

        svg {
            fill: map-get($theme-scheme, border-contrast);
            width: 17px;
            height: 17px;
        }
    }
}

.input-car-vin__label {
    position: absolute;
    top: 0;
    left: 0;
    width: 38px;
    height: 100%;
    display: flex;
    flex-direction: column;
    -webkit-box-align: center;
    align-items: center;
    -webkit-box-pack: center;
    justify-content: center;
    color: map-get($theme-scheme, contrast-text);
    background: map-get($theme-scheme, main);
    font-size: 13px;
    border-radius: 4px 0 0 4px;
}
