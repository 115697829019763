@import "../variables";
@import '../mixins/breakpoints';

.order-panel {
    box-shadow: none !important;
    --mat-expansion-container-shape: 0 !important;
    --mat-expansion-header-indicator-color: #f18c16;
    border: 1px solid map-get($theme-scheme, border);

    .expansion-content-title {
        font-size: 1rem;
    }

    @include media-breakpoint-down(sm) {
        .expansion-content-title {
            font-size: 0.8rem;
        }
    }
}

.order-panel__header {
    border-left: 5px solid blue;
    min-height: 80px;
    padding: 10px 24px;

    @include media-breakpoint-down(xs) {
        min-height: 140px;
        &.mat-expanded {
            min-height: 80px;
        }
    }

    &.mat-expanded .mat-content .order-panel__title > *:not(:first-child) {
        display: none;
    }
}

.order-panel__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
}

.order-panel__title-item {
    flex-grow: 1;
    flex-shrink: 1;
    &:first-child {
        flex-basis: 200px;
    }
    &:last-child {
        flex-basis: 180px;
    }
    @include media-breakpoint-down(md) {
        &:first-child {
            flex-basis: 150px;
        }
    }
    @include media-breakpoint-down(xs) {
        &:first-child {
            flex-basis: 120px;
        }
        &:last-child {
            flex-basis: 140px;
        }
    }
}

.order-panel__title-item-name {
    color: map-get($theme-scheme, secondary-text);
    font-size: $adaptive-13-font-size;
}

.order-panel__title-item-value {
    font-weight: $font-weight-bold;
    font-size: $default-font-size;
    display: flex;
    gap: 10px;

    @include media-breakpoint-down(md) {
        font-size: $adaptive-13-font-size;
    }

    .ttn {
        color: map-get($theme-scheme, main);
        font-weight: $font-weight-bold;
    }
}

.order-panel__content-buttons {
    display: flex;
    gap: 10px;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;

    button {
        display: flex;
        align-items: center;
        gap: 8px;
        padding: 14px 20px;
        span {
            font-size: $adaptive-13-font-size;
            text-transform: uppercase;
            font-weight: $font-weight-bold;
            transition: all ease-in-out 0.2s;
        }
        transition: all ease-in-out 0.2s;
    }

    .order-panel__content-buttons-invoice {
        border: 1px solid map-get($theme-scheme, border);
        svg {
            fill: map-get($theme-scheme, fill);
            transition: all ease-in-out 0.2s;
        }

        &:hover {
            $color: map-get($theme-scheme, main);
            border-color: $color;
            span {
                color: $color;
            }
            svg {
                fill: $color;
            }
        }
    }
}
