/*
// .btn
*/
@import '../variables';
@import '../mixins/btn';
@import '../mixins/loader';


.btn {
    @include btn;

    &.disabled,
    &:disabled {
        cursor: default;
    }
}
.btn-icon {
    padding: 0;

    svg {
        display: block;
        margin: auto;
    }
};
.btn-loading {
    position: relative;

    &:after {/* nl size (default) */
        @include loader();
        @include loader-thickness(2px);
        @include loader-size(24px);
    }
    &.btn-xl:after {/* xl size */
        @include loader-size(42px);
    }
    &.btn-lg:after {/* lg size */
        @include loader-size(32px);
    }
    &.btn-sm:after {/* sm size */
        @include loader-size(20px);
    }
    &.btn-xs:after {/* xs size */
        @include loader-size(16px);
    }
}

.btn {
    @include btn-size(nl);
}
.btn-sm {
    @include btn-size(sm);
}
.btn-xs {
    @include btn-size(xs);
}
.btn-lg {
    @include btn-size(lg);
}
.btn-xl {
    @include btn-size(xl);
}

@include btn-variant(primary, $btn-primary-scheme);
@include btn-variant(secondary, $btn-secondary-scheme);
@include btn-variant(light, $btn-light-scheme);
@include btn-variant(muted, $btn-muted-scheme);

.btn-br-4 {
    border-radius: 4px;
}

.btn-shadow {
    box-shadow: 0 0 4px 0 map-get($theme-scheme, main);
}

@mixin default-button-mixin($background, $hover-background, $text-color, $hover-text-color, $border, $hover-border) {
    display: flex;
    align-items: center;
    gap: 10px;
    border: 1px solid $border;
    border-radius: 0;
    padding: 12px !important;
    transition: all .2s ease-in-out;
    background-color: $background;
    span {
        text-transform: uppercase;
        font-size: $adaptive-13-font-size;
        font-weight: $font-weight-semi-bold;
        color: $text-color;
        transition: all .2s ease-in-out;
    }
    svg {
        fill: $text-color;
        transition: all .2s ease-in-out;
        height: $adaptive-13-font-size;
        width: auto;
    }
    &:hover, &.hover {
        border-color: $hover-border;
        background: $hover-background;
        span {
            color: $hover-text-color;
        }
        svg {
            fill: $hover-text-color;
        }
    }
}

.default-pargo-button {
    $fill: map-get($theme-scheme, fill);
    $main: map-get($theme-scheme, main);
    $contrast: map-get($theme-scheme, contrast-text);
    $border: map-get($theme-scheme, border);
    $bg: map-get($theme-scheme, block-bg);
    @include default-button-mixin($bg, $main, $fill, $contrast, $border, $main);
}

.default-pargo-button-inverse {
    $fill: map-get($theme-scheme, fill);
    $main: map-get($theme-scheme, main);
    $contrast: map-get($theme-scheme, contrast-text);
    $bg: map-get($theme-scheme, block-bg);
    @include default-button-mixin($main, $bg, $contrast, $main, $main, $main);
    //margin: 0 auto;

    &.button-responsive {
        width: 100%;

        @include media-breakpoint-up(sm) {
            margin-left: 0;
            margin-right: auto;
            width: unset;
        }
    }
}

.outlined-icon-button {
    display: block;
    border: 1px solid map-get($theme-scheme, border);
    border-radius: 0;
    padding: 12px 18px !important;
    transition: all .2s ease-in-out;
    background-color: transparent;

    @include media-breakpoint-down(xs) {
        padding: 6px 10px !important;
        flex: 1 1 45%;
    }

    svg {
        fill: map-get($theme-scheme, fill);;
        transition: all .2s ease-in-out;
        height: $adaptive-13-font-size;
        width: auto;
    }
    &:hover {
        border-color: map-get($theme-scheme, main);;
    }
}

.buttons-wrapper {
    display: flex;
    width: 100%;
    justify-content: space-between;
    gap: 10px;

    @include media-breakpoint-up(xs) {
        justify-content: flex-start;
    }
    @include media-breakpoint-up(sm) {
        width: unset;
    }
}

.button-mobile-only {
    display: block;

    @include media-breakpoint-up(sm) {
        display: none;
    }
}

