@import "../variables";
@import "../mixins/breakpoints";
@import "../mixins/direction";
@import "../mixins/header";
@import "../themes/theme-pargo/theme-variables";
@import "../adapt";

@include desktop-header-variant-selector {
    @if ($header-layout == pargo) {
        // grid
        .header {
            //$local-grid-padding-xxl: calc(100% * .5 - #{map_get($container-max-widths, xxl) - $grid-gutter-width} * .5);
            //$local-grid-padding-xl: calc(100% * .5 - #{map_get($container-max-widths, xl) - $grid-gutter-width} * .5);

            //$local-grid-padding-xxl: map_get($x-paddings, xxl);
            //$local-grid-padding-xl: map_get($x-paddings, xl);

            display: grid;
            grid-template-columns: max-content auto max-content;
            grid-template-rows: adapt-size-px(46) auto;

            @include media-breakpoint-only(xl) {
                grid-template-columns: max-content auto max-content;
            }
        }
        .header__megamenu-area {
            grid-column: 1 / 4;
            grid-row: 1;
        }
        .header__topbar-pargo-bg {
            grid-column: 2 / 4;
            grid-row: 1;
        }
        .header__topbar-pargo {
            margin-left: clamp(20px, 1.45vw, 28px);
            grid-column: 2 / 4;
            grid-row: 1;
        }
        .header__logo {
            grid-column: 1;
            grid-row: 1 / span 2;
        }
        .header__search {
            //margin-left: 28px;
            margin-left: clamp(20px, 1.45vw, 28px);
            grid-column: 2;
            grid-row: 2;
            display: flex;
            gap: 1.5rem;
            align-items: stretch;

            .search {
                flex: 1;
                max-width: 780px;
            }
        }
        //.header__departments {
        //    margin: 0 clamp(20px, 1.45vw, 28px);
        //    grid-column: 2;
        //    grid-row: 2;
        //}
        .header__indicators {
            grid-column: 3;
            grid-row: 2;
        }

        // styles
        .header {
            position: relative;
            z-index: 10;

            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .desktop-header {
            @include media-breakpoint-down(md) {
                display: none;
            }
        }
        .header__logo {
            //min-width: 230px;

            //@include direction {
            //    #{$margin-inline-end}: 24px;
            //}

            .logo__image {
                justify-content: center;
                padding-right: clamp(1.25rem, 1.35vw, 1.625rem);
                border-right: 1px solid map_get($header-scheme, divider);

                svg {
                    height: auto;
                    width: clamp(120px, 8.6vw, 160px);
                }
            }

            @include media-breakpoint-only(xl) {
                min-width: 210px;
            }
        }
        .header__search {
            align-self: center;

            @include direction {
                #{$margin-inline-end}: 24px;
            }
        }
        .header__indicators {
            align-self: center;
            display: flex;
            justify-content: flex-end;
            align-items: center;
            padding: adapt-size-px(16) 0;
        }
        .header__indicators .indicator + .indicator {
            @include direction {
                #{$margin-inline-start}: 4px;
            }
        }

        // define scheme
        .header {
            //background: map_get($header-scheme, main);
            color: map_get($header-scheme, opposite);
            box-shadow: map_get($navbar-scheme, shadow);
        }
        .header__topbar-pargo-bg {
            border-bottom: 1px solid map_get($header-scheme, divider);
        }

        @if (
            map_get($header-scheme, main) == map_get($topbar-pargo-scheme, main)
        ) {
            .header {
                grid-template-rows: 34px auto;
            }
            .header__topbar-pargo-bg {
                border-bottom: 1px solid map_get($header-scheme, divider);
            }
            .header__topbar-pargo {
                padding-bottom: 1px;
            }
        }

        .header-login-button {
            border: 1px solid map_get($theme-scheme, main);
            background-color: transparent;
            padding: 6px 18px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            color: map_get($theme-scheme, main);
            font-size: $adaptive-13-font-size;
            font-weight: 600;
            transition: color 0.3s ease;

            &:hover {
                //background-color: map_get($theme-scheme, main);;
                color: map_get($theme-scheme, contrast-text);
            }

            .header-login-button__secondary {
                color: rgba(map_get($theme-scheme, contrast-text), 0.5);
                font-weight: 400;
            }
        }

        @media (max-width: 1279px) {
            .departments {
                display: none;
            }
        }
    }
}
