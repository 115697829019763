/* ----------------------------------------
// Table of Contents:
// - Mobile
//   - .mobile-header
//   - .mobile-indicator
//   - .mobile-logo
//   - .mobile-menu
//   - .mobile-search

/* ----------------------------------------
// Mobile
// ---------------------------------------- */
/*
// .mobile-header
*/
.site--mobile-header--mobile-one .site__mobile-header {
  position: sticky;
  top: 0;
  z-index: 100;
}
.site--mobile-header--mobile-one .mobile-header__wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1rem;
  padding: 16px 0;
}
.site--mobile-header--mobile-one .mobile-header {
  display: block;
  background: #242424;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.09);
  position: relative;
  z-index: 100;
  width: 100%;
}
@media (min-width: 992px) {
  .site--mobile-header--mobile-one .mobile-header {
    display: none;
  }
}
.site--mobile-header--mobile-one .mobile-header__logo {
  margin-left: 1rem;
}
.site--mobile-header--mobile-one .mobile-header__menu-button {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0;
  margin: 0;
  border: none;
  background: transparent;
}
.site--mobile-header--mobile-one .mobile-header__menu-button svg {
  display: block;
}
.site--mobile-header--mobile-one .mobile-header__logo {
  display: flex;
  align-items: center;
  padding: 0;
}
.site--mobile-header--mobile-one .mobile-header__search {
  display: flex;
  align-items: stretch;
  max-width: 440px;
  flex-grow: 1;
  padding: 0 12px;
  margin: 0 auto;
  gap: 10px;
  width: 100%;
  height: 40px;
}
.site--mobile-header--mobile-one .mobile-header__search input {
  border-radius: 1px;
}
.site--mobile-header--mobile-one .mobile-header__search input::placeholder {
  font-size: clamp(11px, 0.68vw, 13px);
}
.site--mobile-header--mobile-one .mobile-header__indicators {
  display: flex;
  align-items: center;
  gap: 10px;
}
.site--mobile-header--mobile-one .mobile-header__xs-contacts {
  display: none;
}
.site--mobile-header--mobile-one .mobile-header__contacts svg {
  width: 24px !important;
}
@media (max-width: 575.98px) {
  .site--mobile-header--mobile-one {
    /* Mobile Header Grid Layout */
    /* First row positioning */
    /* Second row positioning */
  }
  .site--mobile-header--mobile-one .mobile-header__wrapper {
    display: grid;
    grid-template-rows: auto auto;
    grid-template-columns: repeat(7, 1fr);
    gap: 10px;
  }
  .site--mobile-header--mobile-one .mobile-header__menu-button {
    grid-row: 1;
    grid-column: 1;
    margin-right: auto;
  }
  .site--mobile-header--mobile-one .mobile-header__logo {
    grid-row: 1;
    grid-column: 2/7;
    justify-self: center;
    margin-left: 0;
  }
  .site--mobile-header--mobile-one .mobile-header__contacts {
    grid-row: 1;
    grid-column: 7;
    margin-left: auto;
  }
  .site--mobile-header--mobile-one .mobile-header__search {
    grid-row: 2;
    grid-column: 1/6;
    width: 100%;
    padding: 0;
  }
  .site--mobile-header--mobile-one .mobile-header__indicators {
    grid-row: 2;
    grid-column: 6/8;
    margin-left: auto;
  }
}

/*
// .mobile-indicator
*/
.site--mobile-header--mobile-one .mobile-indicator {
  position: relative;
}
.site--mobile-header--mobile-one .mobile-indicator__button {
  padding: 0;
  display: flex;
  align-items: center;
  position: relative;
  justify-content: center;
  height: 40px;
  width: 40px;
  color: inherit;
  background: black;
  border: none;
  border-radius: 2px;
}
.site--mobile-header--mobile-one .mobile-indicator__button:focus {
  outline: none;
}
.site--mobile-header--mobile-one .mobile-indicator__button:hover {
  color: inherit;
  text-decoration: none;
}
.site--mobile-header--mobile-one .mobile-indicator__button svg {
  width: 20px;
  height: auto;
}
.site--mobile-header--mobile-one .mobile-indicator__button--contrast {
  background: transparent;
}
.site--mobile-header--mobile-one .mobile-indicator__button--contrast svg {
  fill: #F28B00;
}
.site--mobile-header--mobile-one .mobile-indicator__button--rounded {
  background: transparent;
  border-radius: 100%;
  border: 1px solid #F28B00;
}
.site--mobile-header--mobile-one .mobile-indicator__icon {
  display: block;
  position: relative;
}
.site--mobile-header--mobile-one .mobile-indicator__icon svg {
  display: block;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-indicator__icon svg {
  transform: scaleX(1);
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-indicator__icon svg {
  transform: scaleX(-1);
}
.site--mobile-header--mobile-one .mobile-indicator__counter {
  position: absolute;
  top: -2px;
  right: -2px;
  font-size: 10px;
  line-height: 1;
  padding: 6px;
  text-align: center;
  z-index: 0;
}
.site--mobile-header--mobile-one .mobile-indicator__counter:before {
  display: block;
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  z-index: -1;
  border-radius: 50%;
  padding-bottom: 100%;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-indicator__counter:before {
  left: 0;
  right: 0;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-indicator__counter:before {
  right: 0;
  left: 0;
}
.site--mobile-header--mobile-one .mobile-indicator__title,
.site--mobile-header--mobile-one .mobile-indicator__value {
  display: block;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-indicator__title,
[dir=ltr] .site--mobile-header--mobile-one .mobile-indicator__value {
  margin-left: 50px;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-indicator__title,
[dir=rtl] .site--mobile-header--mobile-one .mobile-indicator__value {
  margin-right: 50px;
}
.site--mobile-header--mobile-one .mobile-indicator__title {
  padding-top: 3px;
  margin-bottom: -3px;
  font-size: 13px;
}
.site--mobile-header--mobile-one .mobile-indicator__value {
  font-size: 18px;
  font-weight: 500;
}
.site--mobile-header--mobile-one .mobile-indicator__icon {
  fill: #fff;
  color: #fff;
}
.site--mobile-header--mobile-one .mobile-indicator__title {
  color: #999;
}
.site--mobile-header--mobile-one .mobile-indicator__value {
  font-weight: 500;
}
.site--mobile-header--mobile-one .mobile-indicator__counter {
  color: #fff;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--mobile-header--mobile-one .mobile-indicator__counter {
    font-weight: 400;
  }
}
.site--mobile-header--mobile-one .mobile-indicator__counter:before {
  background-color: #F28B00;
}

/*
// .mobile-header
*/
.site--mobile-header--mobile-one .mobile-logo svg {
  display: block;
}
.site--mobile-header--mobile-one .mobile-logo__part-one {
  fill: rgba(255, 255, 255, 0.9);
}
.site--mobile-header--mobile-one .mobile-logo__part-two {
  fill: rgba(255, 255, 255, 0.6);
}

/*
// .mobile-menu
*/
.site--mobile-header--mobile-one .mobile-menu {
  visibility: hidden;
  transition: visibility 0s 0.25s;
}
.site--mobile-header--mobile-one .mobile-menu__backdrop {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 1000;
  background: rgba(51, 51, 51, 0.8);
  opacity: 0;
  will-change: opacity;
  transition: opacity 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.site--mobile-header--mobile-one .mobile-menu__body {
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 1000;
  background: #242424;
  width: 88%;
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
  will-change: transform;
  overflow: hidden;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__body {
  left: 0;
  transform: translateX(-100%);
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__body {
  right: 0;
  transform: translateX(100%);
}
.site--mobile-header--mobile-one .mobile-menu--open {
  visibility: visible;
  transition-delay: 0s;
}
.site--mobile-header--mobile-one .mobile-menu--open .mobile-menu__backdrop {
  opacity: 1;
}
.site--mobile-header--mobile-one .mobile-menu--open .mobile-menu__body {
  transform: translateX(0);
}
.site--mobile-header--mobile-one .mobile-menu__close {
  position: absolute;
  top: 0;
  width: 48px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  border: none;
  padding: 0;
  fill: currentColor;
  transition: background-color 0.15s, color 0.15s;
  background-color: #fff;
  color: #ccc;
}
.site--mobile-header--mobile-one .mobile-menu__close:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.site--mobile-header--mobile-one .mobile-menu__close:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__close {
  right: 0;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__close {
  left: 0;
}
.site--mobile-header--mobile-one .mobile-menu__close:focus {
  outline: none;
}
.site--mobile-header--mobile-one .mobile-menu__conveyor {
  position: relative;
  width: 100%;
  height: 100%;
  z-index: 1;
  transition: transform 0.25s cubic-bezier(0.25, 0.46, 0.45, 0.94);
}
.site--mobile-header--mobile-one .mobile-menu__panel {
  display: flex;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  flex-direction: column;
  padding: 0 20px;
  background: #242424;
}
.site--mobile-header--mobile-one .mobile-menu__panel-header {
  flex-shrink: 0;
  height: 51px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 2px;
  position: relative;
  backface-visibility: hidden;
}
.site--mobile-header--mobile-one .mobile-menu__panel-header__logo .mobile-logo .ny-logo__img {
  height: auto;
  width: 70px;
}
.site--mobile-header--mobile-one .mobile-menu__panel-header__logo .mobile-logo .ny-logo__hat {
  display: none;
}
.site--mobile-header--mobile-one .mobile-menu__panel-header__logo .mobile-logo svg {
  height: auto;
  width: 70px;
}
.site--mobile-header--mobile-one .mobile-menu__panel-back {
  width: 32px;
  min-height: 32px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  border: 1px solid #F28B00;
  padding-top: 0;
  padding-bottom: 0;
  margin-top: 8px;
  fill: #F28B00;
  background-color: transparent !important;
  transition: background-color 0.15s, color 0.15s;
  background-color: #fff;
  color: #ccc;
}
.site--mobile-header--mobile-one .mobile-menu__panel-back:hover {
  background-color: #f2f2f2;
  color: #4d4d4d;
}
.site--mobile-header--mobile-one .mobile-menu__panel-back:active {
  background-color: #ebebeb;
  color: #4d4d4d;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__panel-back {
  left: 0;
  padding-left: 0;
  padding-right: 2px;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__panel-back {
  right: 0;
  padding-right: 0;
  padding-left: 2px;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__panel-back svg {
  transform: scaleX(1);
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__panel-back svg {
  transform: scaleX(-1);
}
.site--mobile-header--mobile-one .mobile-menu__panel-back:hover {
  background-color: #fff !important;
}
.site--mobile-header--mobile-one .mobile-menu__panel-back:focus {
  outline: none;
}
.site--mobile-header--mobile-one .mobile-menu__panel-arrow {
  position: absolute;
}
.site--mobile-header--mobile-one .mobile-menu__panel-title {
  text-align: center;
  margin: auto;
  font-size: 15px;
  font-weight: 500;
}
.site--mobile-header--mobile-one .mobile-menu__panel-body {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  backface-visibility: hidden;
}
.site--mobile-header--mobile-one .mobile-menu__panel-buttons {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.site--mobile-header--mobile-one .mobile-menu__divider {
  flex-shrink: 0;
  height: 1px;
  background: #ebebeb;
}
.site--mobile-header--mobile-one .mobile-menu__spring {
  flex-grow: 1;
}
.site--mobile-header--mobile-one .mobile-menu__indicators {
  display: flex;
  padding: 8px 11px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
}
.site--mobile-header--mobile-one .mobile-menu__indicator {
  width: calc((100% - 0px * 3) / 3);
  display: flex;
  flex-direction: column;
  align-items: center;
  fill: #404040;
  border-radius: 2px;
  padding: 8px 0 6px;
}
.site--mobile-header--mobile-one .mobile-menu__indicator:hover {
  background: #f2f2f2;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__indicator + .site--mobile-header--mobile-one .mobile-menu__indicator {
  margin-left: 0px;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__indicator + .site--mobile-header--mobile-one .mobile-menu__indicator {
  margin-right: 0px;
}
.site--mobile-header--mobile-one .mobile-menu__indicator-title {
  font-size: 9px;
  font-weight: 500;
  text-transform: uppercase;
  color: #999;
  margin-top: 5px;
}
.site--mobile-header--mobile-one .mobile-menu__indicator-icon {
  position: relative;
}
.site--mobile-header--mobile-one .mobile-menu__indicator-icon svg {
  display: block;
}
.site--mobile-header--mobile-one .mobile-menu__indicator-counter {
  position: absolute;
  bottom: calc(100% - 7px);
  color: #fff;
  font-size: 10px;
  line-height: 1;
  padding: 2px 3px 1px;
  border-radius: 6.5px;
  text-align: center;
  z-index: 0;
  font-weight: 500;
}
@media (max-resolution: 1dppx) {
  .site--mobile-header--mobile-one .mobile-menu__indicator-counter {
    font-weight: 400;
  }
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__indicator-counter {
  left: calc(100% - 4px);
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__indicator-counter {
  right: calc(100% - 4px);
}
.site--mobile-header--mobile-one .mobile-menu__indicator-counter:before {
  display: block;
  position: absolute;
  content: "";
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background-color: #F28B00;
  z-index: -1;
  border-radius: 2.5px;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__indicator-counter:before {
  transform: skewX(-11deg);
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__indicator-counter:before {
  transform: skewX(11deg);
}
.site--mobile-header--mobile-one .mobile-menu__links {
  margin-top: 10px;
}
.site--mobile-header--mobile-one .mobile-menu__links ul {
  list-style: none;
  padding: 10px 0;
  margin: 0;
  font-size: 15px;
  line-height: 22px;
  font-weight: 500;
  width: 100%;
}
.site--mobile-header--mobile-one .mobile-menu__links ul > li > a,
.site--mobile-header--mobile-one .mobile-menu__links ul > li > button {
  position: relative;
  color: inherit;
  width: 100%;
  border: none;
  background: transparent;
  font-family: inherit;
  font-size: inherit;
  font-weight: inherit;
  display: flex;
  align-items: center;
  padding: 7px 0;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__links ul > li > a,
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__links ul > li > button {
  text-align: left;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__links ul > li > a,
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__links ul > li > button {
  text-align: right;
}
.site--mobile-header--mobile-one .mobile-menu__links ul > li > a:hover,
.site--mobile-header--mobile-one .mobile-menu__links ul > li > button:hover {
  color: #F28B00;
}
.site--mobile-header--mobile-one .mobile-menu__links ul > li > a:hover svg,
.site--mobile-header--mobile-one .mobile-menu__links ul > li > button:hover svg {
  fill: #F28B00;
}
.site--mobile-header--mobile-one .mobile-menu__links ul > li > a:focus,
.site--mobile-header--mobile-one .mobile-menu__links ul > li > button:focus {
  outline: none;
}
.site--mobile-header--mobile-one .mobile-menu__links ul > li > a svg,
.site--mobile-header--mobile-one .mobile-menu__links ul > li > button svg {
  position: absolute;
  top: calc(50% - 5px);
  fill: #bfbfbf;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__links ul > li > a svg,
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__links ul > li > button svg {
  right: 16px;
  transform: scaleX(1);
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__links ul > li > a svg,
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__links ul > li > button svg {
  left: 16px;
  transform: scaleX(-1);
}
.site--mobile-header--mobile-one .mobile-menu__links ul > li > a.highlight:before,
.site--mobile-header--mobile-one .mobile-menu__links ul > li > button.highlight:before {
  position: absolute;
  display: block;
  content: "";
  width: 5px;
  height: 5px;
  border-radius: 2.5px;
  background: #F28B00;
  top: 15px;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__links ul > li > a.highlight:before,
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__links ul > li > button.highlight:before {
  left: 8px;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__links ul > li > a.highlight:before,
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__links ul > li > button.highlight:before {
  right: 8px;
}
.site--mobile-header--mobile-one .mobile-menu__wrapper-list {
  padding-top: 20px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
.site--mobile-header--mobile-one .mobile-menu__wrapper-list .icon {
  padding-right: 10px;
  color: rgba(255, 255, 255, 0.7);
}
.site--mobile-header--mobile-one .mobile-menu__links-image {
  display: block;
  width: 22px;
  height: 16px;
  border-radius: 1.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__links-image {
  margin-right: 9px;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__links-image {
  margin-left: 9px;
}
.site--mobile-header--mobile-one .mobile-menu__links-image img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.site--mobile-header--mobile-one .mobile-menu__contacts {
  text-align: center;
  padding: 16px 20px 14px;
  transition: background 0.2s;
}
.site--mobile-header--mobile-one .mobile-menu__contacts:hover {
  background: #f2f2f2;
}
.site--mobile-header--mobile-one .mobile-menu__contacts, .site--mobile-header--mobile-one .mobile-menu__contacts:hover {
  color: inherit;
}
.site--mobile-header--mobile-one .mobile-menu__contacts-subtitle {
  font-size: 13px;
  color: #999;
}
.site--mobile-header--mobile-one .mobile-menu__contacts-title {
  font-size: 18px;
  font-weight: 500;
}
.site--mobile-header--mobile-one .mobile-menu__settings-list {
  display: flex;
}
.site--mobile-header--mobile-one .mobile-menu__setting {
  flex-basis: 0;
  flex-grow: 1;
  width: 50%;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__setting + .site--mobile-header--mobile-one .mobile-menu__setting {
  border-left: 1px solid #ebebeb;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__setting + .site--mobile-header--mobile-one .mobile-menu__setting {
  border-right: 1px solid #ebebeb;
}
.site--mobile-header--mobile-one .mobile-menu__setting-button {
  background: transparent;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: inherit;
  color: inherit;
  position: relative;
  width: 100%;
  padding-top: 9px;
  padding-bottom: 9px;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__setting-button {
  padding-left: 14px;
  padding-right: 26px;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__setting-button {
  padding-right: 14px;
  padding-left: 26px;
}
.site--mobile-header--mobile-one .mobile-menu__setting-button:hover {
  background: #f2f2f2;
}
.site--mobile-header--mobile-one .mobile-menu__setting-button:focus {
  outline: none;
}
.site--mobile-header--mobile-one .mobile-menu__setting-panel {
  display: none;
}
.site--mobile-header--mobile-one .mobile-menu__setting-icon {
  display: block;
  width: 22px;
  height: 16px;
  border-radius: 1.5px;
  position: relative;
  overflow: hidden;
  flex-shrink: 0;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__setting-icon {
  margin-right: 7px;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__setting-icon {
  margin-left: 7px;
}
.site--mobile-header--mobile-one .mobile-menu__setting-icon img {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.site--mobile-header--mobile-one .mobile-menu__setting-icon--currency {
  background: #5c6266;
  color: #fff;
  font-size: 10px;
  line-height: 16px;
}
.site--mobile-header--mobile-one .mobile-menu__setting-title {
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__setting-title {
  text-align: left;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__setting-title {
  text-align: right;
}
.site--mobile-header--mobile-one .mobile-menu__setting-arrow {
  position: absolute;
  top: calc(50% - 5px);
  fill: #bfbfbf;
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-menu__setting-arrow {
  right: 12px;
  transform: scaleX(1);
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-menu__setting-arrow {
  left: 12px;
  transform: scaleX(-1);
}
.site--mobile-header--mobile-one .mobile-menu__setting-arrow svg {
  display: block;
}
.site--mobile-header--mobile-one .mobile-menu__links-container {
  display: flex;
  flex-direction: column;
  padding: 16px 0;
  gap: 16px;
  border-top: 1px solid rgba(255, 255, 255, 0.1);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}
.site--mobile-header--mobile-one .mobile-menu__links-container--item {
  display: flex;
  align-items: flex-start;
}
.site--mobile-header--mobile-one .mobile-menu__links-container--item .mobile-menu__links-container--item-link {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
  padding: 4px 0;
}
.site--mobile-header--mobile-one .mobile-menu__links-container--item .mobile-menu__links-container--item-link:hover {
  color: #F28B00;
}
.site--mobile-header--mobile-one .mobile-menu__links-container--item::before {
  content: "";
  width: 3px;
  height: 3px;
  background-color: #F28B00;
  margin-right: 8px;
  margin-top: 13px;
}
.site--mobile-header--mobile-one .mobile-menu__contact-links {
  display: flex;
  justify-content: space-between;
  padding: 12px 0;
}
.site--mobile-header--mobile-one .mobile-menu__contact-links--divider {
  height: 100%;
  width: 1px;
  background-color: rgba(255, 255, 255, 0.1);
}
.site--mobile-header--mobile-one .mobile-menu__contact-links--part {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.site--mobile-header--mobile-one .mobile-menu__contact-links--part .mobile-menu__contact-links--part-item {
  display: flex;
  align-items: center;
}
.site--mobile-header--mobile-one .mobile-menu__contact-links--part .mobile-menu__contact-links--part-item a {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
}
.site--mobile-header--mobile-one .mobile-menu__contact-links--part .mobile-menu__contact-links--part-item .mobile-menu__contact-links--part-item-link {
  color: rgba(255, 255, 255, 0.7);
  font-size: 13px;
}
.site--mobile-header--mobile-one .mobile-menu__contact-links--part .mobile-menu__contact-links--part-item .mobile-menu__contact-links--part-item-link-icon {
  margin-right: 8px;
}
.site--mobile-header--mobile-one .mobile-menu__contact-links--part .mobile-menu__contact-links--part-item .mobile-menu__contact-links--part-item-link-icon svg {
  fill: rgba(255, 255, 255, 0.7);
  height: auto;
  width: 15px;
}
.site--mobile-header--mobile-one .mobile-menu__schedule {
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
}
.site--mobile-header--mobile-one .mobile-menu__schedule span {
  font-size: 13px;
  color: rgba(255, 255, 255, 0.7);
}
.site--mobile-header--mobile-one .mobile-menu__login-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  font-size: 12px;
  font-weight: 600;
  color: #F28B00;
  border: 1px solid #F28B00;
  padding: 4px 0;
  margin-bottom: 50px;
}
.site--mobile-header--mobile-one .mobile-menu__login-button .mobile-menu__login-button__secondary {
  color: rgba(255, 255, 255, 0.5);
  font-weight: 400;
}
.site--mobile-header--mobile-one .mobile-menu__header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
}
@media (max-width: 767.98px) {
  .site--mobile-header--mobile-one .mobile-menu__panel {
    padding: 0 10px;
  }
  .site--mobile-header--mobile-one .mobile-menu__header {
    padding: 10px;
  }
}

/*
// .mobile-search
*/
.site--mobile-header--mobile-one .mobile-search__body {
  display: flex;
  position: relative;
  z-index: 0;
  width: 100%;
}
.site--mobile-header--mobile-one .mobile-search__input,
.site--mobile-header--mobile-one .mobile-search__button {
  background: transparent;
  border: none;
  font-family: inherit;
  color: inherit;
}
.site--mobile-header--mobile-one .mobile-search__input:focus,
.site--mobile-header--mobile-one .mobile-search__button:focus {
  outline: none;
}
.site--mobile-header--mobile-one .mobile-search__input {
  flex-grow: 1;
  font-size: 16px;
}
.site--mobile-header--mobile-one .mobile-search__field {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  z-index: -1;
  transition: color 0.2s, background 0.2s, border-color 0.2s;
}
.site--mobile-header--mobile-one .mobile-search__button {
  width: unset2px;
  flex-shrink: 0;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: color 0.2s;
  fill: currentColor;
  border-width: 0;
  border-left-width: 1px;
  border-style: solid;
}
.site--mobile-header--mobile-one .mobile-search__vehicle-picker {
  background: #F28B00;
  color: #262626;
  min-width: 44px;
  padding: 0 8px;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 500;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  transition: color 0.15s, background-color 0.15s;
}
@media (min-width: 576px) {
  .site--mobile-header--mobile-one .mobile-search__vehicle-picker {
    min-width: 90px;
  }
}
.site--mobile-header--mobile-one .mobile-search__vehicle-picker:hover {
  background: #fff;
  fill: none;
}
.site--mobile-header--mobile-one .mobile-search__vehicle-picker:active {
  background: #fff;
}
.site--mobile-header--mobile-one .mobile-search__vehicle-picker:active svg {
  fill: none;
}
.site--mobile-header--mobile-one .mobile-search__vehicle-picker:focus {
  outline: none;
}
.site--mobile-header--mobile-one .mobile-search__vehicle-picker:focus svg {
  fill: none;
}
.site--mobile-header--mobile-one .mobile-search__vehicle-picker svg {
  display: block;
  fill: #fff;
  width: 24px;
}
.site--mobile-header--mobile-one .mobile-search__vehicle-picker app-icon {
  flex-shrink: 0;
  fill: rgba(0, 0, 0, 0.75);
}
[dir=ltr] .site--mobile-header--mobile-one .mobile-search__vehicle-picker app-icon + .mobile-search__vehicle-picker-label {
  margin-left: 8px;
}
[dir=rtl] .site--mobile-header--mobile-one .mobile-search__vehicle-picker app-icon + .mobile-search__vehicle-picker-label {
  margin-right: 8px;
}
@media (min-width: 768px) {
  .site--mobile-header--mobile-one .mobile-search__input {
    height: unset;
    padding: 9px 11px;
    border-width: 1px;
    border-style: solid;
    transition: color 0.2s, background 0.2s, border-color 0.2s;
    color: #262626;
    background-color: #f0f0f0;
    border-color: #f0f0f0;
  }
  .site--mobile-header--mobile-one .mobile-search__input::placeholder {
    color: #6c757d;
  }
  [dir=ltr] .site--mobile-header--mobile-one .mobile-search__input {
    padding-left: 11px;
    padding-right: 37px;
  }
  [dir=rtl] .site--mobile-header--mobile-one .mobile-search__input {
    padding-right: 11px;
    padding-left: 37px;
  }
  .site--mobile-header--mobile-one .mobile-search__input:hover {
    border-color: #d9d9d9;
  }
  .site--mobile-header--mobile-one .mobile-search__input:focus {
    background-color: #fff;
    border-color: #d9d9d9;
  }
  .site--mobile-header--mobile-one .mobile-search__button--close {
    display: none;
  }
  .site--mobile-header--mobile-one .mobile-search__button--search {
    position: absolute;
    top: 0;
    height: 100%;
  }
  [dir=ltr] .site--mobile-header--mobile-one .mobile-search__button--search {
    right: 0;
  }
  [dir=rtl] .site--mobile-header--mobile-one .mobile-search__button--search {
    left: 0;
  }
  [dir=ltr] .site--mobile-header--mobile-one .mobile-search__vehicle-picker {
    margin-right: 6px;
  }
  [dir=rtl] .site--mobile-header--mobile-one .mobile-search__vehicle-picker {
    margin-left: 6px;
  }
  .site--mobile-header--mobile-one .mobile-search__button--search {
    color: #b2b2b2;
  }
  .site--mobile-header--mobile-one .mobile-search__button--search:hover {
    color: #F28B00;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .site--mobile-header--mobile-one .mobile-search__vehicle-picker-label {
    display: none;
  }
}
@media (max-width: 767.98px) {
  .site--mobile-header--mobile-one .mobile-search__body {
    height: 100%;
  }
  .site--mobile-header--mobile-one .mobile-search__input {
    flex-basis: 0;
    width: 0;
  }
  [dir=ltr] .site--mobile-header--mobile-one .mobile-search__input {
    padding-left: 12px;
    padding-right: 0;
  }
  [dir=rtl] .site--mobile-header--mobile-one .mobile-search__input {
    padding-right: 12px;
    padding-left: 0;
  }
  .site--mobile-header--mobile-one .mobile-search__button--close {
    border-width: 0;
    border-style: solid;
  }
  [dir=ltr] .site--mobile-header--mobile-one .mobile-search__button--close {
    border-left-width: 1px;
  }
  [dir=rtl] .site--mobile-header--mobile-one .mobile-search__button--close {
    border-right-width: 1px;
  }
  .site--mobile-header--mobile-one .mobile-search__input {
    color: #262626;
  }
  .site--mobile-header--mobile-one .mobile-search__input::placeholder {
    color: #6c757d;
  }
  .site--mobile-header--mobile-one .mobile-search__input ~ .mobile-search__field {
    background: #fff;
  }
  .site--mobile-header--mobile-one .mobile-search__input ~ .mobile-search__button--search {
    border-color: #ebebeb;
  }
  .site--mobile-header--mobile-one .mobile-search__button {
    color: #b2b2b2;
  }
  .site--mobile-header--mobile-one .mobile-search__button:hover {
    color: #F28B00;
  }
}
@media (max-width: 440px) {
  .site--mobile-header--mobile-one .mobile-search__vehicle-picker-label {
    display: none;
  }
}

/*
// .departments
*/
.mobile-contacts {
  display: block;
  position: relative;
}

.mobile-contacts__button {
  display: flex;
  gap: 10px;
  align-items: center;
  padding: 0;
  position: relative;
  background-color: transparent;
  border: none;
  font-family: inherit;
  transition: color 0.2s;
  color: rgba(255, 255, 255, 0.7);
  fill: #fff;
}
.mobile-contacts__button:focus {
  outline: none;
}
.mobile-contacts__button:hover {
  color: #F28B00;
  fill: #F28B00;
}
.mobile-contacts__menu {
  position: absolute;
  z-index: 1;
  visibility: hidden;
  opacity: 0;
  top: 100%;
  right: 0;
  transition: opacity 0.2s, transform 0.2s, visibility 0s 0.2s;
}

.mobile-contacts__body {
  pointer-events: auto;
  display: flex;
  background-color: #fff;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  color: #242424;
  padding: 15px 20px;
}
[dir=ltr] .mobile-contacts__body {
  float: left;
}
[dir=rtl] .mobile-contacts__body {
  float: right;
}

.mobile-contacts__list {
  white-space: nowrap;
  flex-shrink: 0;
  list-style: none;
  margin: 0;
  padding: 0;
}

.mobile-contacts__link {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
  color: #242424;
}
.mobile-contacts__link:hover {
  color: #F28B00;
}

.mobile-contacts__item {
  color: inherit;
  display: block;
  position: relative;
  padding: 5px 0;
}

.mobile-contacts__link-icon {
  margin-right: 15px;
}

.mobile-contacts--open .mobile-contacts__menu {
  transition-delay: 0s;
  visibility: visible;
  opacity: 1;
  transform: translateY(0);
}
.mobile-contacts--open .mobile-contacts__button-arrow {
  transform: rotate(180deg);
}

.mobile-contacts__arrow {
  width: 41px;
}

.mobile-contacts__schedule {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 47px;
  gap: 10px;
  background-color: #E3E3E3;
}
.mobile-contacts__schedule span {
  font-size: clamp(14px, 0.83vw, 16px);
  font-weight: 500;
  color: #646464;
}